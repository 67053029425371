.home-blog-wrapper {
  display: grid;
  grid-template-rows: 1fr;
}

.home-blog-wrapper .home-blog-header{
  margin:0 auto;
  padding-top:25px;
}

p.home-blog-header-text {
  color: #25282A;
  font-size: 26px;
  margin: 0 auto;
}

.home-blog-wrapper .home-blog-entries {
  width:100%;
  height: 600px;
  margin: 0 auto;
}

.home-blog-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px;
}
*/

.card-group.card-group-scroll > .card2 {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px;
}
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.home-slider-card {
  background: #fff;
  height: 430px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}
