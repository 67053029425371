.home-property-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height:448px;
}

.home-property-wrapper .home-property-left {
  position: relative;
  background: linear-gradient(rgba(100,52,96,0.69),rgba(100,52,96,0.69)), url('/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-wrapper .home-property-right {
  position: relative;
  background: linear-gradient(rgba(0,66,106,0.69),rgba(0,66,106,0.69)),url('/wp-content/uploads/2019/03/SeniorLiving-Segment-bg.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-text {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 20%;
}

.home-property-text .home-property-header {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  border-bottom: 1px solid #A28D5B;
  color: #fff;
}
.home-property-text .home-property-tag {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #fff;
  width:95%;
}
