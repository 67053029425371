@import "phone-styles.css";

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .home-blog-section-mobile {
    display: none;
  }

  .footer-mobile {
    display: none;
  }
  #category-wrapper-mobile {
    display: none;
  }
  .product-page-mobile {
    display: none;
  }
  .custom-collections-wrapper-mobile {
    display: none;
  }
  .custom-collection-wrapper-mobile {
    display: none;
  }
  .segment-wrapper-mobile {
    display: none;
  }
  .ds-featuredstyles-section-mobile {
    display: none;
  }
  .mobile-nav {
    display:none;
  }
  .media-mobile-nav {
    display:none;
  }
  .home-hero-section-mobile {
    display:none;
  }
  .about-video-mobile {
    display: none;
  }

  .media-page-mobile-videos {
    display: none
  }
  .sustainability-video-mobile {
    display: none;
  }
  #mobile-cart-button {
    display: none;
  }

}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }

  .carousel-caption {
    display: block!important;
    margin-left: -25px;
    margin-top: -150px;
    z-index: 10;
    position: absolute;
    color: #fff;
    text-align: center;
    height:300px;
  }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
    .home-blog-section-mobile {
      display: none;
    }
    .footer-mobile {
      display: none;
    }
    #category-wrapper-mobile {
      display: none;
    }
    .product-page-mobile {
      display: none;
    }
    .custom-collections-wrapper-mobile {
      display: none;
    }
    .custom-collection-wrapper-mobile {
      display: none;
    }

    .segment-wrapper-mobile {
      display: none;
    }
    .ds-featuredstyles-section-mobile {
      display: none;
    }
    .mobile-nav {
      display: none;
    }
    .media-mobile-nav {
      display:none;
    }
    .home-hero-section-mobile {
      display:none;
    }
    .about-video-mobile {
      display: none;
    }
    .media-page-mobile-videos {
      display: none
    }
    .sustainability-video-mobile {
      display: none;
    }
    .sustainability-video-mobile {
      display: none;
    }
    .biocel-laminate-graphic-mobile, .biocel-cushion-graphic-mobile {
      display: none;
    }
    #mobile-cart-button {
      display: none;
    }

}
html {
    margin-top: 0;
}
body {
    max-width: 1280px;
    margin: 0 auto;
    background: #E8E8E8;
}

body,
div#page-inner {
    /* Will not work on IE if body is ommitted */
    /*display: flex;*/
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
}
button:focus {
  outline:none;
}
a:focus {
  outline: none;
}
a, button {
  cursor: pointer;
}
div#content {
    flex-grow: 1;
}

.posts-title,
.navbar {
    padding-left: 1rem;
}

.navbar {
    border-bottom: 1px solid #eaeaea;
}

.site-content-contain {
    background-color: transparent !important;
    margin-top: 60px;
}

.site-content-contain #content,
.navigation-top {
    background-color: #fff;
    width: 75%;
    margin: 0 auto;
}



@media screen and (min-width: 48em) {
    .site-content {
        
    }
    
}


.aseel-front-page.has-header-image .custom-header-media,
.aseel-front-page.has-header-video .custom-header-media,
.home.blog.has-header-image .custom-header-media,
.home.blog.has-header-video .custom-header-media {
    height: 25vh !important;
}

#primary #main .post {
    clear: left;
    float: left;
    width: 100%;
}

.post-excerpt {
    background: #f4f4f4;
    padding: 10px;
    margin-bottom: 20px;
}

img#loader {
    clear: both;
    margin: 0 auto;
}

#loader {
    text-align: center;
    display: none;
}

#loader.active {
    display: block;
}

.loader-gif {
    text-align: center;
    margin-top: 10em;
}

.post-entry {
    padding-top: 1em;
}

.col-md-4.card-outer {
    float: left;
    padding-top: 1em;
    padding-bottom: 1em;
}

.col-md-4.card-outer .card {
    height: 520px;
}

.img-outer {
    max-height: 250px;
    overflow: hidden;
}

#posts-here {
    position: fixed;
    bottom: 10px;
}

.card-outer {
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.5s ease-out;
}

.card-outer.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.card-body div {
    float: left;
}

img.product-image,
img.featured-image {
    width: 100%;
}
