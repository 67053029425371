@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

footer{
  height: 400px;
  background: #25282A;
  color:#fff;
  max-width: 100%;
  bottom: 0;
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
}

.footer-content-left {
  margin-top: 50px;
  /*padding-left: 150px;*/
}

.footer-content-right {
  display:grid;
  margin-top: 35px;
  grid-template-columns: 1fr;
  width:90%;
}

.right-top {
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) ) ;
}

.right-bottom {
  grid-row: 2;
}

.col-4 {
  grid-row:1;
  grid-column:4/6;
}

.footer-column-header {
  color: #999;
  font-size: 14pt;
  font-family: 'Adelle Sans Sb';
}
.footer-links{
  color: #A28D5B;
  font-size: 14px;
  font-family: 'Adelle Sans Lt';
}

.footer-links:visited {
  color: #A28D5B;
  font-size: 14px;
  font-family: 'Adelle Sans Lt';
}

.footer-links:hover {
  text-decoration: none;
  color: #999;
}

.find-rep-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.footer-social-icons {
  padding-bottom: 5px;
  color: #999;
}

.footer-copyright {
  font-family: 'Adelle Sans Lt';
  font-size:11px;
  border-top: 1px solid #555;
  padding-top:5px;
  width:95%;
  color: #999;
}

.footer-copyright a {
  color: #999;
}
.footer-copyright a:hover {
  text-decoration: none;
}

.footer-social-icons a{
  color: #999;
  padding-left: 10px
}
