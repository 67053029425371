@media only screen and (min-width: 992px) {
	
	.carpet-submenu-large {
	  width: 160px;
	  float: left;
	  padding: 15px;
	  max-width: 160px;
	}
	
	.wsmainwp {
	  margin: 0 auto;
	  max-width: 1250px;
	}
	
}
