
.ds-featuredstyles-wrapper {
  display: grid;
  grid-template-rows: 50px 1fr;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-header{
  margin:0 auto;
  padding-top:75px;
}

p.ds-featuredstyles-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-entries {
  width:100%;
  height: 450px;
  margin: 0 auto;
}

.ds-featuredstyles-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px
}
*/
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  padding-left: 10px;
  padding-top:3px;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.cardbody > p > a {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  text-decoration: none;
}
.cardbody > p > a:hover {
  text-decoration: none;
}
.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.ds-slider-card {
  background: #fff;
  height: 400px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}
