@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@media screen and (min-width: 765px) {
  /*.category-main-content .results {
    padding: 20px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 239px)!important;
    color: #444;
  }*/
}

/** DESKTOP */

#category-wrapper {
  background: #F8F8F8!important;
}
#category-container {
  margin: 0 auto;
  width:90%;
}

.category-header {
  text-align: center;
  padding-top:50px;
}
.category-header h1 {
  font-family: 'Zahrah';
  font-size: 56px;
  color: #25282A;
}

.category-header h1::first-letter {
  text-transform: capitalize;
}

.category-header p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}


.category-filterbar {
  border-bottom: 1px solid #CCC;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}
.category-filterbar-left {
  text-align: left;
  width:49%;
  display:inline-block;
}
.category-filterbar-right {
  text-align:right;
  width:50%;
  display:inline-block;
}
.category-filterbar-right a {
  text-decoration: none;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}

.category-main-content {
  display: grid;
  grid-template-columns: 250px 1fr;
  padding-bottom: 100px;
}

.category-main-content .filter {
  padding-top:25px;
  display: inline-block;
  margin-left:5px;
  margin-right: 5px;
  width: 225px;
}

.category-main-content .filter-item {
  border-bottom: .5px solid #CCC;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #777;
  
}

.category-main-content .filter-item .left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.category-main-content .filter-item .right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.right a {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #777;
}
.right a:hover {
  text-decoration: none;
  cursor: pointer;
}

.category-main-content .results {
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  /*grid-template-columns: repeat(3, 239px);*/
  grid-template-columns: repeat(auto-fill, minmax(239px, 239px));
  color: #444;
}

.results .product {
  padding: 20px;
}

.product-wrapper {
  color: #555;
  font-size: 16px;
  font-family: 'Adelle Sans';
  position: relative;
}

.product-wrapper img {
  width:239px;
  height: 239px;
}

.product-wrapper span {
  font-family: 'Adelle Sans Lt';
}

.product-wrapper a {
  color: #444;
}
.product-wrapper a:hover {
  color: #444;
  text-decoration: none;
}


#style-name-options, 
#construction-type-options, 
#dye-methods-options, 
#patterntypes-options, 
#segments-options, 
#enduses-options, 
#backing-options, 
#faceWeight-options,
#hsSize-options,
#thickness-options,
#wearlayer-options,
#finish-options,
#install-method-options,
#color-range-options {
  display: none;
}

.filter-options {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left:5px;
}


/** MOBILE **/
#category-wrapper-mobile {
  background: #F8F8F8;
}

#category-wrapper-mobile .category-header-mobile {
  text-align: center;
  padding-top:100px;
}
#category-wrapper-mobile .category-header-mobile h1 {
  font-family: 'Zahrah';
  font-size: 38px;
  color: #25282A;
}

#category-wrapper-mobile .category-header-mobile h1::first-letter {
  text-transform: capitalize;
}

#category-wrapper-mobile .category-header-mobile p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}

#category-wrapper-mobile .filter-button-holder-mobile {
  text-align: center;
  padding: 20px;
}

#category-wrapper-mobile .filter-button-holder-mobile .filter-button-mobile {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

#filter-mobile-id, 
#faceWeightMobile-options,
#constructionMobile-type-options, 
#dye-methodsMobile-options, 
#patterntypesMobile-options, 
#segmentsMobile-options, 
#endusesMobile-options, 
#backing-optionsMobile, 
#hsSizeMobile-options,
#thicknessMobile-options,
#wearlayerMobile-options,
#finishMobile-options,
#install-methodMobile-options,
#color-rangeMobile-options {
  display: none;
}

.filter-mobile .filter {
  padding-top:15px;
  display: inline-block;
  margin-left:15px;
  margin-right: 5px;
  width: 90%;
}

.filter-mobile .filter .filter-item {
  border-bottom: .5px solid #CCC;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #777;
  
}

.filter-mobile .filter  .filter-item .left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.filter-mobile .filter .filter-item .right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.right a {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #777;
}
.right a:hover {
  text-decoration: none;
  cursor: pointer;
}
.filter-mobile-buttons {
  padding: 10px;
  text-align: center;
  padding-bottom: 25px;
}
.filter-mobile-buttons  .filter-clear-button-mobile {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.filter-mobile-buttons  .filter-done-button-mobile {
  color: #FFF;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width:150px;
}


/** Product List */
.product-list-wrapper-mobile {
  display: block;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
}

.product-list-wrapper-mobile .product {
  /*padding: 20px;*/
}

.product-wrapper-mobile {
  color: #555;
  font-size: 16px;
  font-family: 'Adelle Sans';
}



.product-wrapper-mobile img {
  width:128px;
  height: 128px;
}

.product-wrapper-mobile p {
  margin-left: 10px;
}

.product-wrapper-mobile span {
  font-family: 'Adelle Sans Lt';
}

.product-wrapper-mobile a {
  color: #444;
}
.product-wrapper-mobile a:hover {
  color: #444;
  text-decoration: none;
}

.notify-badge-mobile{
  position: absolute;
  left:190px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}

.notify-badge{
  position: absolute;
  right: -20px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}

.sg-notify-badge{
  position: absolute;
  right: -30px;
  top: -30px;
  color:white;
  padding:5px;
  background: transparent;
  height: 75px;
}

.sg-notify-badge-mobile{
  position: absolute;
  left:190px;
  top: -20px;
  color:white;
  padding:5px;
  background: transparent;
  height: 75px;
}

.product-block {
  position: relative;
}

/** CSS for Rug Style Customize URL **/
.style-customize-container {
	position: relative;
	width: 50%;
	max-width: 239px;
	height: 50%;
	max-height: 239px;	
}

.style-customize-image {
	display: block;
}

.style-customize-button {
  position: relative;
  top: -80px;
  left: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  color: white;
  border-radius: 50px;
  text-align: center;
	padding-left: 25px;
	padding-right: 25px;
  width: 120px;
	height: 37px;
  font-family: "Adelle Sans Sb";
  font-size: 14px;
}

/** Desktop */	
@media screen and (min-width: 765px) {
  .style-customize-button-text {
    padding-top: 10px;
    margin: auto;
    color: white;
    text-align: center;
    font-family: "Adelle Sans Sb";
    font-size: 14px;	
  }
  .style-customize-button-text a:link, a:visited, a:hover, a:active{
    color: white;
    text-decoration: none;
  }
  .style-title-text a:link, a:visited, a:hover, a:active{
  color: #444;
 	text-decoration: none;
  }
}

.style-customize-button-text-mobile a:link, a:hover, a:active{
  color: #444;
 	text-decoration: none;
}

.style-customize-button-text-mobile a:visited{
  color: white;
 	text-decoration: none;
}