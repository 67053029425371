@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('./Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('./Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('./Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('./Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('./Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('./Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
