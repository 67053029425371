@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

.sustainability-wrapper {
	background-color: #fff;
}

.sustainability-wrapper-2 {
	margin-left: 50px;
	margin-right: 50px;
	padding-top: 10px;
	padding-bottom: 30px;
	background-color: #fff;
}

.sustainability-wrapper-2-text-1 {
	font-family: 'Adelle Sans';
	font-size: 26px;
	color: #fff;
	margin-top: 15px;
	margin-bottom: 15px;
}

.sustainability-wrapper-2-text-2 {
	color: #fff;
	padding-top: 5px;
}

.sustainability-wrapper-2-text-3 {
	color: #8C8B89;
}

.sustainability-hero {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.sustainability-hero-content {
  height: 500px;
  position: relative;
}

.sustainability-marketing-block {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sustainability-marketing-block .smb-graphic {
  padding-left: 20px;
  flex: 25%;
}

.sustainability-marketing-block .smb-text {
  padding-top: 45px;
  flex: 60%;
  width: 80%;
  padding-right: 10px;
}

.sustainability-marketing-block .smb-text p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;  
}

.sustainability-marketing-block .smb-text p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.sustainability-marketing-block .smb-youtube {
  padding-left: 20px;
  flex: 30%;
  width: 30%;
  margin-right: -60px;
}

.sustainability-marketing-block .smb-graphic2 {
  padding-left: 20px;
  flex: 25%;
  width: 25%;
}

.sustainability-marketing-block .smb-text2 {
  padding-top: 0px;
  flex: 35%;
  width: 35%;
  padding-right: 10px;
  padding-left: 10px;
}

.sustainability-marketing-block .smb-text2 p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;
  
}

.sustainability-marketing-block .smb-text2 p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.sustainability-CRI-image {
  padding-top: 70px;
}

.sustainability-CRI-list {
  padding-left: 15px;
  font-size: 13px;
}

.sustainability-text-url {
  color: #a28d5b;
}

.sustainability-paragraph-block {
  padding-right: 30px;
}

.sustainability-programs-section {
	{/* height: 400px; */}
}

@media screen and (max-width: 735px) {
  .sustainability-video-desktop { display: none; }
  .sustainability-header {
    width: 100%!important;
    height: 486px!important;
    {/* background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SustainabilityHeader_320_486.jpg')!important; */}
	background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2024/02/SustainabilityHero_1280x500.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .sustainability-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:90%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .sustainability-video {
    width: 100%!important;
  }

  .biocel-laminate-graphic-desktop, .biocel-cushion-graphic-desktop {
    display: none!important;
  }

  .biocel-laminate-grid {
    grid-template-columns: 1fr!important;
    grid-gap: 0!important;
  }
  .biocel-check-left{
    padding: 10px!important;
  }
  .biocel-check-left > p {
    padding-left: 0px!important;
  }
  .biocel-check-right {
    padding-left: 50px!important;
  }
}

@media only screen and (max-width: 600px) {
	
	.sustainability-wrapper {
		margin-top: 0px;
	}
	
	.sustainability-container-2 {
		padding-top: 20px;
	}
	
	.smb-text {
		padding-left: 15px;
		padding-right: 15px;
	}
	
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .biocel-laminate-graphic-desktop, .biocel-cushion-graphic-desktop {
    display: none!important;
  }
  .biocel-laminate-grid {
    grid-template-columns: 1fr!important;
    margin-left: 20px!important;
  }
  .biocel-check-left{
    padding: 0px!important;
  }
  .biocel-check-left > p {
    padding-left: 50px!important;
  }
  .biocel-check-right {
    padding-left: 95px!important;
  }
}

@media screen and (max-width: 1024px) {
  .biocel-check-left {
    padding-left: 50px!important;
  }
}


.sustainability-header {
  width: 100%;
  height: 450px;
  /* background: linear-gradient(to bottom, transparent 0%, black 200%), url('/wp-content/uploads/2019/03/SustainabilityHeader.jpg'); */
  background: linear-gradient(to bottom, transparent 0%, black 200%), url('/wp-content/uploads/2024/02/SustainabilityHero_1280x500.jpg'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.sustainability-header-content {
  height: 450px;
  position: relative;
}

.sustainability-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.sustainability-above-fold {
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 25px;
  padding-bottom: 25px;
}

.sustainability-above-title {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
}

.sustainability-above-content {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  width: 85%;
  margin: 0 auto;
  text-align: center;
}

.sustainability-video-block {
  background: #F8F8F8;
  margin: 0 auto;
  text-align: center;
  padding-top: 25px;
}

.sustainability-video-title {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
  padding-bottom: 50px;
}

.sustainability-video {
  margin:0 auto;
  text-align: center;
  width:754px;
}

.sustainability-video-content p {
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 80%;
  text-align: center;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  margin: 0 auto;
}


.biocel-content {
  background: #fff;
  margin: 0 auto;
  height: 100%;
  padding-bottom: 100px;
}
.biocel-content-wrapper {
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
}
.biocel-header h2 {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
  padding-top: 50px;
  padding-bottom: 30px;
}


.biocel-nav > a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
}

.biocel-nav > a:hover {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}

.biocel-nav > a.active-biocel {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}
/*
.biocel-laminate-content {
  padding-top: 25px;
}
.biocel-laminate-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  line-height: 26px;
  margin: 0 auto;
  text-align: center;
}*/

.biocel-laminate-content {
  height: 150px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
}

.biocel-laminate-content p {
  text-align: center;
  margin: 0 auto;
  /*width:75%;*/
  height: 150px;
}

.biocel-laminate-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(50%,50%));
  margin:0 auto;
  /*max-width: 100%;*/
  padding-top: 0px;
  padding-bottom: 100px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}
.biocel-check-left {
  padding-left: 50px;
}
.biocel-check-left > p {
  padding: 5px;
  text-align: left;
  padding-left:150px;
  width: 100%;
}

.biocel-check-right > p {
  padding: 5px;
  text-align: left;
}

.sustainability-leed {
  width: 100%;
  height: 348px;
  background: linear-gradient(rgba(37,40,42,0.60),rgba(37,40,42,0.60)), url('/wp-content/uploads/2019/03/SustainabilityLead.jpg');
  background-attachment: 'fixed';
  background-position: center;
  background-repeat: 'no-repeat';
  background-size: 'cover';  
  margin: 0 auto;
  color: #fff;
  text-align: center;
}



.sustainability-need-help {
  width: 100%;
  height: 348px;
  background: linear-gradient(rgba(37,40,42,0.60),rgba(37,40,42,0.60)), url('/wp-content/uploads/2019/03/Sustainability-Need-Help.jpg');
  margin: 0 auto;
  color: #fff;
}



.sustainability-need-help-text {
  color: #fff;
  text-align: center;
  padding-top:75px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.sustainability-need-help-text h2 {
  padding-bottom: 30px;
}

.sustainability-need-help-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  width: 604px;
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
  width: 80%;
}

.sustainability-leed h2 {
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 22px;
  color: #FFF;
  text-align: center;
  margin: 0 auto;
  width: 80%; 
  padding-top: 100px;
}

.sustainability-leed-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 12px;
  background:transparent;
  display: inline-block;
  padding:10px;
  width: 225px;
  text-align: center;
}

.sustainability-leed-button:hover {
  text-decoration: none;
  color: #A28D5B;
  cursor: pointer;
}

.sustainability-leed-button:visited {
  text-decoration: none;
  color: #A28D5B;
}