@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .custom-design-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/CustomDesignHeader.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .custom-design-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:85%!important;
    width: 100%!important;
    margin-left:10px!important;
  }
  .custom-design-content-column-left, .custom-design-content-column-right {
    padding-left: 0px!important;
  }
  .custom-design-content-column-middle {
    display: none!important;
  }
  .custom-design-main-content-grid {
    grid-template-columns: 1fr!important;
  }
  .custom-design-content-column-left {
    grid-template-columns: 1fr!important;
  }
  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .cs-col3-row1, .cs-col3-row2 {
    padding-top: 50px!important;
    padding-left: 20px!important;
  }
}

@media screen and (max-width: 780px) {
  .custom-design-content-column-middle {
    display: none!important;
  }

  .custom-design-content-column-left, .custom-design-content-column-right {
    padding-left: 225px;
  }

  .custom-design-main-content-grid {
    grid-template-columns: 1fr!important;
  }
  .custom-design-content-column-left {
    grid-template-columns: 1fr!important;
  }

  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .cs-col3-row1, .cs-col3-row2 {
    padding-top: 50px!important;
    padding-left: 20px!important;
  }
}

.custom-design-main-content .cs-col1-row1-content > h2 {
  text-align: right;
}
.custom-design-main-content .cs-col1-row1-content > h2 {
  text-align: right;
}

.custom-design-main-content .cs-col3-row1-content > h2 {
  text-align: left;
}
.custom-design-main-content .cs-col3-row1-content > h2 {
  text-align: left;
}

.custom-design-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/03/CustomDesignHeader.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.custom-design-header-content {
  height: 450px;
  position: relative;
}

.custom-design-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.custom-design-main-content {
  background: #FFF;
  text-align: center;
  margin: 0 auto;
}

.custom-design-main-content-grid {
  width: 700px;
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
  margin: 0 auto;
  text-align: center;
  min-height: 700px;
  background: #FFF;
  padding-bottom: 100px;
  padding-right: 850px;
}

.custom-design-content-column-left {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.cs-col1-row1-content {
  margin: 0 auto;
  width: 350px;
}
.cs-col1-row1-content img {
  padding-bottom: 10px;
}

.cs-col1-row1-content h2, p {
  text-align: right;
}

.cs-col1-row1-content h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A
}

.cs-col1-row1-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.cs-col3-row1-content {
  margin: 0 auto;
  width: 350px;
}
.cs-col3-row1-content img {
  padding-bottom: 10px;
}

.cs-col3-row1-content h2, p {
  text-align: left;
}

.cs-col3-row1-content h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A
}

.cs-col3-row1-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.cs-col3-row1 {
  padding-top: 375px;
}

.cs-col3-row2 {
  padding-top: 150px;
}
