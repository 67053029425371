@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

nav.installations {
  background: #fff;
}

nav.installations li {
  display: inline-block;
}

nav.installations li a {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #777;
  padding-left: 15px;
}

nav.installations li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B;
}

nav.installations li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B!important;
}

.installation .card-title {
  font-family: 'Adelle Sans Sb';
  font-size: 18px;
  text-decoration: none;
  padding-left: 5px;
  color:#25282A;
}

.card-info {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color:#777777;
  margin-top:-10px;
  text-decoration: none;
}
.card-body > a:hover {
  text-decoration: none;
}
.install-modal-header {
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color:#25282A;
}

.install-modal-header-inner {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}

.slick-dots {
  bottom: -10px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.installs-header-content {
  height: 450px;
  position: relative;
}

.installs-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}


.installs-above-fold {
  height: 100px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 25px;
  padding-bottom: 25px;
}

.installs-featured-styles-top {
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;
}

.installs-featured-styles-top .installs-featured-header{
  margin:0 auto;
  padding-top:50px;
}

p.installs-featured-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.card-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  background: #fff;
}
.card {
  /* width: 17rem; */
}

.card-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0;
  padding-left: 0;
}

