@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

#home-container {
  /*margin-top: 75px;*/
}

.home-hero-section {
  width: 100%;
}

.home-marketing-section {
  width:75%;
  margin: 0 auto;
  padding: 100px;
  text-align: center;
  background: #FFF;
  width: 100%;
}

.home-marketing-section h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 26px;
  padding-bottom:20px;
}

.home-marketing-section p {
  font-family: 'Adelle Sans Lt';
  font-size: 18px;
  text-align: center;
}

.home-product-section {
  width: 100%;
  margin:0 auto;
  padding-bottom:100px;
  background: #FFF;
}

.home-product-wrapper {
  /*display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  height: 494px;*/
  margin: 0 auto;
  /*
  display: grid;
  grid-gap: 10px;
  */
  width:80%;
  height:494px;
  /*
  grid-template: "large large item1 item2" "large2 large2 item3 item4";
  */
}

.home-sustainability-wrapper {
  margin: 0 auto;
  width:80%;
  height:680px;
}

.photo {
  background: black;
}
.large {
  grid-area:large;
}
.large2 {
  grid-area:large2;
}

/*
.photo:nth-of-type(3){ grid-area:item1;}
.photo:nth-of-type(4){ grid-area:item2;}
.photo:nth-of-type(5){ grid-area:item3;}
.photo:nth-of-type(6){ grid-area:item4;}
*/

.box-1 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/Sustainability_507x242-2.jpg');
  background-size: cover;
  background-position: bottom;
}

.box-15 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/Entryway-Systems_248x242-1.jpg');
  background-size: cover;
}

.box-16 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/CustomBroadloom_BoldaciousHC143_248x242.jpg');
  background-size: cover;
}

.box-2 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/CarpetTile_248x242-1.jpg');
  background-size: cover;
}

.box-3 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/HardSurface_UrbanEscapes_248x242.jpg');
  background-size: cover;
}

.box-4 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/Rugs_LocalStyle_Vibe_Treasure_248x242.jpg');
  background-size: cover;
}

.box-5 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/HomePage_BroadloomSquare_248x242.jpg');
  background-size: cover;
}

/*
.home-product-wrapper .left {
  position: relative;
  width: 494px;
  height: 494px;
  background: url('/wp-content/uploads/2018/12/HomePage-OverheadCorridor@2x.jpg');
  background-size: 100%;
  bottom:0;
  top:0;
}
*/


.home-product-text {
  position: absolute;
  top: 75%;
  right: 0;
  left: 0;
  padding:15px;
  line-height:1;
  height:100%;
}

.home-product-text .home-product-header {
  font-family: 'Adelle Sans';
  font-size: 16px;
  line-height: 0;
  color: #fff;
}

.home-product-text .home-product-subheader {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  line-height: 1;
  color: #A28D5B;
}

/*
.home-product-wrapper .right {
  width: 494px;
  height: 494px;
  display:grid;
  grid-template-columns: 1fr, 1fr;
}
*/


.photo .home-product-box-text {
  position: absolute;
  top: 75%;
  right: 0;
  left: 0;
  padding:15px;
}

.photo .home-product-box-text .home-product-header {
  font-family: 'Adelle Sans';
  font-size: 16px;
  line-height: 0;
  color: #fff;
  margin-bottom: 20px;
}

.photo .home-product-box-text .home-product-subheader {
  font-family: 'Adelle Sans Lt';
  font-size: 12px;
  line-height: 1;
  color: #A28D5B;
}

.home-property-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height:448px;
}

.home-property-wrapper .home-property-left {
  position: relative;
  background: linear-gradient(rgba(100,52,96,0.69),rgba(100,52,96,0.69)), url('/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-wrapper .home-property-right {
  position: relative;
  background: linear-gradient(rgba(0,66,106,0.69),rgba(0,66,106,0.69)),url('/wp-content/uploads/2018/12/homepage-sigliving.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-text {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 20%;
}

.home-property-text .home-property-header {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  border-bottom: 1px solid #A28D5B;
  color: #fff;
}
.home-property-text .home-property-tag {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #fff;
  width:95%;
}

.home-blog-section {
  background: #DDD;
  height:600px;
  
}
.home-blog-section-mobile {
  background: #DDD;
  height:700px;
}

.home-find-rep-wrapper {
  background: linear-gradient(rgba(37,40,42,0.75),rgba(37,40,42,0.75)),url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  min-height: 400px;
}

.home-find-rep-text {
  color: #fff;
  text-align: center;
  padding-top:150px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.home-find-rep-button {
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
}

.home-product-section-sustainability {	
  width: 1024px;
}

.home-product-text-header {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #fff;
  margin-bottom: 25px;
}

.home-product-text-header-sustainability {
  font-family: 'Adelle Sans';
  font-size: 30px;
  color: #fff;
  margin-bottom: 25px;
}

.home-product-text-subheader {
  font-family: 'Adelle Sans Lt';
  font-size: 12px;
  color: #A28D5B;
  margin-bottom: 10px;
}

.home-section-sustainability {
  padding-top: 80px;	
  width: 1034px;
}


@media only screen and (max-width: 600px) {
	
	.home-product-section-sustainability {	
	  width: 400px;
	}
	
	.home-product-section {	
	  margin-top: 80px;
	}
	
	.home-section-sustainability {	
	  width: 400px;
	}
	
	.home-sustainability-wrapper {
	  margin-top: 400px;
	}

}
