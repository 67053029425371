:focus {
  outline : none;
}
.cd-wrapper {
  background: linear-gradient(rgba(37,40,42,0.75),rgba(37,40,42,0.75)),url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  min-height: 400px;
  text-align: center;
}


.cd-text {
  color: #fff;
  text-align: center;
  padding-top:100px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.cd-text h2 {
  padding-bottom: 30px;
}

.cd-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  width: 604px;
  margin: 0 auto;
  padding-bottom: 30px;
  width: 80%;
}

.cd-learn-more-button {
  border-color: transparent;
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 166px;
  cursor: pointer;
}
.cd-learn-more-button { 
  outline: none;
}

.cd-find-rep-button {
  border-color: transparent;
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background: transparent;
  display: inline-block;
  padding:10px;
  width: 166px;
  cursor: pointer;
  outline: none;
}

.cd-find-rep-button:focus {
  outline: none;
}
