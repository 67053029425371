.project-installs-wrapper {
  background: #fff;
  padding-bottom: 50px;
  padding-top: 75px;
}
.project-installs-header {
  text-align: center;
}
.project-installs-header h4 {
  color: #25282A;
  font-size: 26px;
  -webkit-text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.project-installs-explore-button {
  text-align: center;
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 250px;
}

.project-installs-explore-button:hover {
  text-decoration: none;
  text-align: center;
  color: #fff;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: #a28d5b;
  display: inline-block;
  padding: 10px;
  width: 250px;
}

.featured-project-installs a {
  color: #000;
  text-decoration: none;
}

.featured-project-installs a:hover {
  text-decoration: none;
}