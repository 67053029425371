@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .tradeshows-header {
    width: 100%!important;
    height: 486px!important;
    background: url('/wp-content/uploads/2024/12/Trade-Shows-Hero.png')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .tradeshows-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:85%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .tradeshows-hospitality-tradeshows .tradeshows-list {
    grid-template-columns: 1fr!important;
  }

  .tradeshows-wrapper > h3 {
    padding-top:25px;
  }
}

.tradeshows-header {
  width: 100%;
  height: 450px;
  background: url('/wp-content/uploads/2024/12/Trade-Shows-Hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.tradeshows-header-content {
  height: 450px;
  position: relative;
}

.tradeshows-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}
.tradeshows-hospitality-tradeshows {
  min-height: 500px;
  background: #643460;
  padding-bottom: 100px;
}

.tradeshows-senior-living-tradeshows {
  min-height: 500px;
  background: #00426A;
}

.tradeshows-general-tradeshows {
  min-height: 400px;
  background: #F8F8F8;
  padding-bottom: 100px;
}

.tradeshow-header {
  margin:0 auto;
  padding-top: 100px;
  text-align: center;
  padding-bottom: 50px;
}

.tradeshow-header-text {
  font-family: 'Adelle Sans';
  color: #FFF;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.tradeshow-header-text-general {
  font-family: 'Adelle Sans';
  color: #25282A;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.tradeshows-hospitality-tradeshows .tradeshows-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

/*.tradeshows-general-tradeshows .tradeshows-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}*/

.tradeshows-list .tradeshow-item {
  width: 75%;
  margin: 0 auto;
}

.tradeshow-item h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #fff;
}

.tradeshow-item a {
  text-decoration: none;
}
.tradeshow-item a:hover {
  text-decoration: none;
}

.tradeshow-item h5 {
  font-family: 'Adelle Sans Lt';
  font-size: 20px;
  color: #fff;
  opacity: .6;
}

.tradeshow-item p {
  padding-top: 5px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #FFF;
  opacity: .6;
}

.tradeshows-list .tradeshow-item-general {
  width: 75%;
  margin: 0 auto;
}

.tradeshow-item-general h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #25282A;
}

.tradeshow-item-general a {
  text-decoration: none;
}
.tradeshow-item-general a:hover {
  text-decoration: none;
}

.tradeshow-item-general h5 {
  font-family: 'Adelle Sans Lt';
  font-size: 20px;
  color: #777;
  opacity: .6;
}

.tradeshow-item-general p {
  padding-top: 5px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.tradeshow-gallery-section {
  padding-top: 20px;
  padding-bottom: 0px;  
}

.tradeshow-gallery-wrapper {

}


@media only screen and (max-width: 600px) {
	
	.tradeshow-gallery-section {	
	  width: 420px;
	}
	
	.tradeshow-gallery-wrapper {
	  /* margin-top: 400px; */
	}

}
