@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


.signaturenow-header-content {
  height: 450px;
  position: relative;
}

.signaturenow-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:80%;
  margin-left:100px;
}


.signaturenow-above-fold {
  height: 200px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 0px;
  padding-bottom: 25px;
  width: 1280px;
  max-width: 1280px;
}

.signaturenow-above-fold p {
  text-align: center;
  margin: 0 auto;
  width:75%;
}

.signaturenow-link-types .link-rows {
  margin: 0 auto;
  padding-top: 25px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.link-row {
  text-align: center;
}

.link-row .link-header {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #A28D5B;
  padding-bottom: 10px;
}

.signaturenow-link-types .link-rows .link-row .link-content {
  font-family: 'Adelle Sans Lt';
  font-size: 14;
  color: #fff;
  opacity: .7;
  padding-top: 10px;  
}

.link-button {
  padding-top: 20px;
}

.link-hr {
  border-top: 2px solid #A28D5B;
  width:50px;
  text-align: center;
  margin: 0 auto;  
}

.signaturenow-link-types .link-rows .link-row .link-button .link-row-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 10px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:225px;
}
.signaturenow-link-types .link-rows .link-row .link-button .link-row-button-large {
  color: #FFF;
  border:1px solid #FFF;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:200px;
}




.signaturenow-link-types {
  /* height: 325px; */
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  background-image: url("/wp-content/uploads/2020/07/home-page-header-bg.jpg");
  background-blend-mode: overlay;
  background-size: cover;
  bottom:0;
  top:0;
}


.link-button {
  margin: 0 auto;
  text-align: baseline;
  justify-self: baseline;
}

.signaturenow-featured-styles-wrapper {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;

}
.signaturenow-hr {
  border-top: 2px solid #fff;
  width:50px;
  text-align: center;
  margin: 0 auto;  
}

.signaturenow-featured-styles-header {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.signaturenow-featured-styles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}

.signaturenow-featured-style img {
  height:500px;
  width: 500px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.signaturenow-featured-style p {
  text-align: left!important;
  padding-left: 20px;
  color: rgb(119,119,119);
}

.signaturenow-products-wrapper, .signaturenow-rug-wrapper {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;
}
.signaturenow-products-inner, .signaturenow-rug-inner {
  width: 90%;
  margin: 0 auto;
}
.signaturenow-products-header, .signaturenow-rug-header {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.signaturenow-products-content p{
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.signaturenow-products-category {
  text-align: left;
  font-size: 1rem;
  padding-left: 25px;
  padding-top: 50px;
  padding-bottom: .5rem;
  font-weight: 500;
}

.signaturenow-products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}

.signaturenow-products a {
  text-decoration: none;
  color: #25282a;
}
.signaturenow-products a:hover {
  text-decoration: none;
  color: #25282a;
}

.signaturenow-product-block {
  text-align: left;
  position: relative;
  padding-left: 25px;
}

.signaturenow-notify-badge{
  position: absolute;
  right:10px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}


.signaturenow-rug-inner {
  margin: 0 auto;
  width: 100%;
}

.signaturenow-rug-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 25px;
}

.signaturenow-rug-images>p {
  text-align: left!important;
  margin-left: 25px!important;
}

.signaturenow-rug-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 25px;
}

.signaturenow-rug-content h4 {
  font-size: 1.2rem;
  padding-bottom: 25px;
}

.signaturenow-rug-content ul {
  text-align: left;
  margin-left: 50px;
}

.signaturenow-button-large {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 200px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1024px) {
  .signaturenow-featured-styles {
    width: 100%;
  }
  .signaturenow-rug-images-col1 img{
    width: 300px;
    height: 400px;
  }

  .signaturenow-rug-images-col2 img{
    width: 300px;
    height: 400px;
  }

  .signaturenow-rug-images-col3-row1 img{
    width: 300px;
    height: 180px;
  }
  .signaturenow-rug-images-col3-row2 img{
    width: 300px;
    height: 180px;
  }
}

@media screen and (max-width: 768px) {
  .signaturenow-link-types {
    height: 700px;
    }

    .signaturenow-header-content h2 {
      top:80%;
      margin-left:50px;
    }
  .signaturenow-featured-styles {
    width: 100%;
  }

  .link-rows {
    width:75%;
    margin: 0 auto;
    padding-top: 100px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }
}


@media screen and (max-width: 480px) {
  .signaturenow-header-content h2 {
    margin-left: 10px;
  }

  .signaturenow-featured-style img {
    width: 100%;
  }

  .signaturenow-product-block {
    padding-left: 50px
  }

  .signaturenow-rug-content ul {
    margin-left: 0;
  }
}