@media only screen and (min-width: 992px) {
	
	.carpet-submenu-large {
	  width: 160px;
	  float: left;
	  padding: 15px;
	  max-width: 160px;
	}
	
	.wsmainwp {
	  margin: 0 auto;
	  max-width: 1250px;
	}
	
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
}

button:focus {outline:0;}

.close {
  color: #A28D5B;
  font-family: 'Adelle Sans Lt';
  font-size: 2rem;
  font-weight: normal;
  padding-right: 50px;
  padding-top:10px;
}

.popup-content {
  width: 60%!important;
  padding:0!important;
  display:inline-block;
}
.cart-wrapper {
  width: 100%;
  max-height: 90%;
  overflow-x: auto;
  overflow-y: auto;
  z-index: 1;
}

.cart-header {
  width: 100%;
  background: #F8F8F8;
  display: inline-block;
}

.header-text {
  display: inline-block;
  width:100%;
  padding-top: 40px;
}

.header-text h1 {
  font-size: 56px;
  font-family: 'Zahrah';
  color: #25282A;
  text-align: center;
}

.header-text p.sample-count {
  text-align: center;
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
  margin-top:-5px;
}

.cart-top-nav {
  padding-left:20px;
}

.cart-top-nav a {
  font-family: 'Adelle Sans';
  font-size: 12px;
  color: #777;
}
.cart-top-nav a:hover, a.active-cart-nav {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration: underline;

}

.cart-items {
  min-height: 200px;
  width: 100%;
  padding-left:30px;
}

.item-name {
  text-align: left;
}
.item-name .item-headers {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
}
.item-name .color-name {
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
  margin-top:-5px;
}
table {
  width: 80%;
  margin: 0 auto;
}
td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.cart-item-row {
  border-bottom: .5px solid #CCC;
}

.cart-buttons, .checkout-buttons {
  text-align: center;
  padding: 50px;
}

.cart-buttons .continue-shopping {
  color: #A28D5B;
  border:1px solid #A28D5B;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.cart-buttons .checkout-button-front {
  color: #fff;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 100px;
}

.form-label {
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
}

.qty-select select {
  border: 0;
  border-bottom:1px solid #A28D5B;
  background: #fff;
}

.checkout-form-div {
  width:60%;
  margin: 0 auto;
  min-height: 300px;
  max-height: 600px;
  padding-top:20px;
  z-index: auto;
  text-align: left;
}

.checkout-form-div p {
  font-family: 'Adelle Sans';
  font-size: 11px;
  font-style: italic;
  color: #AAA;
}
.row, .fullRow {
  padding: 10px;
}
.col {
  float: left;
  width: 50%
}

.col label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.col input {
  border:0;
  border-bottom: .5px solid #CCC;
  width:100%;
}

.fullRow label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.fullRow input {
  border:0;
  border-bottom: .5px solid #CCC;
  width:100%;
}

.checkout-buttons .continue-shopping{
  color: #A28D5B;
  border:1px solid #A28D5B;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.checkout-buttons .checkout-button-front{
  color: #fff;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 150px;
}


.frmStateSelect select {
  border: 0;
  border-bottom: .5px solid #CCC;
  background: #fff;
}

.error{
  margin-left: 5px;
  font-size: 12px;
  font-family: 'Adelle Sans';
  color: red;
}

.thank-you {
  margin: 0 auto;
  padding-top:50px;
  padding-left: 30px;
  padding-right: 30px;
}

.thank-you p {
  text-align: center;
  font-family: 'Adelle Sans';
  font-size: 1rem;
  color: #777;
  padding-bottom: 50px;
}
@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

footer{
  height: 400px;
  background: #25282A;
  color:#fff;
  max-width: 100%;
  bottom: 0;
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
}

.footer-content-left {
  margin-top: 50px;
  /*padding-left: 150px;*/
}

.footer-content-right {
  display:grid;
  margin-top: 35px;
  grid-template-columns: 1fr;
  width:90%;
}

.right-top {
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) ) ;
}

.right-bottom {
  grid-row: 2;
}

.col-4 {
  grid-row:1;
  grid-column:4/6;
}

.footer-column-header {
  color: #999;
  font-size: 14pt;
  font-family: 'Adelle Sans Sb';
}
.footer-links{
  color: #A28D5B;
  font-size: 14px;
  font-family: 'Adelle Sans Lt';
}

.footer-links:visited {
  color: #A28D5B;
  font-size: 14px;
  font-family: 'Adelle Sans Lt';
}

.footer-links:hover {
  text-decoration: none;
  color: #999;
}

.find-rep-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.footer-social-icons {
  padding-bottom: 5px;
  color: #999;
}

.footer-copyright {
  font-family: 'Adelle Sans Lt';
  font-size:11px;
  border-top: 1px solid #555;
  padding-top:5px;
  width:95%;
  color: #999;
}

.footer-copyright a {
  color: #999;
}
.footer-copyright a:hover {
  text-decoration: none;
}

.footer-social-icons a{
  color: #999;
  padding-left: 10px
}

footer {
  height: 100%;
}
.footermobile-content {
  text-align: center;
  width: 100%;
}

.footermobile-content .footermobile-logo {
  padding-top: 50px;
}

.footermobile-content .footermobile-find-rep {
  padding-top: 50px;
  padding-bottom: 25px;
}

.footermobile-content .footermobile-find-rep > button.find-rep-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.footermobile-content .footermobile-link-cell {
  padding-top:25px;
  display: inline-block;
  margin-left:5px;
  margin-right: 5px;
  width: 325px;
}

.footermobile-content .footermobile-link-cell .footermobile-item {
  border-bottom: .5px solid #555;
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-right a {
  font-size: 26px;
  color: #A28D5B
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-right a:hover {
  text-decoration: none;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-left {
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color: #FFF;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-right a {
  font-size: 26px;
  color: #A28D5B
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-right a:hover {
  text-decoration: none;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-left {
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color: #FFF;
}

.footermobile-content .footermobile-social-icons a {
  padding: 10px;
  color: #999;
}

.footermobile-content .footermobile-legal {
  padding-top: 10px;
  font-family: 'Adelle Sans LT';
  font-size: 11px;
  color: #555;
}

.footermobile-content .footermobile-legal a {
  font-family: 'Adelle Sans LT';
  font-size: 11px;
  color: #555;
  text-decoration: none;
}

.footermobile-content .footermobile-legal a:hover {
  text-decoration: none;
}

#footerResource-options,
#footerCompany-options,
#footerMedia-options {
  display: none;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

#home-container {
  /*margin-top: 75px;*/
}

.home-hero-section {
  width: 100%;
}

.home-marketing-section {
  width:75%;
  margin: 0 auto;
  padding: 100px;
  text-align: center;
  background: #FFF;
  width: 100%;
}

.home-marketing-section h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 26px;
  padding-bottom:20px;
}

.home-marketing-section p {
  font-family: 'Adelle Sans Lt';
  font-size: 18px;
  text-align: center;
}

.home-product-section {
  width: 100%;
  margin:0 auto;
  padding-bottom:100px;
  background: #FFF;
}

.home-product-wrapper {
  /*display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  height: 494px;*/
  margin: 0 auto;
  /*
  display: grid;
  grid-gap: 10px;
  */
  width:80%;
  height:494px;
  /*
  grid-template: "large large item1 item2" "large2 large2 item3 item4";
  */
}

.home-sustainability-wrapper {
  margin: 0 auto;
  width:80%;
  height:680px;
}

.photo {
  background: black;
}
.large {
  grid-area:large;
}
.large2 {
  grid-area:large2;
}

/*
.photo:nth-of-type(3){ grid-area:item1;}
.photo:nth-of-type(4){ grid-area:item2;}
.photo:nth-of-type(5){ grid-area:item3;}
.photo:nth-of-type(6){ grid-area:item4;}
*/

.box-1 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/Sustainability_507x242-2.jpg');
  background-size: cover;
  background-position: bottom;
}

.box-15 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/Entryway-Systems_248x242-1.jpg');
  background-size: cover;
}

.box-16 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/CustomBroadloom_BoldaciousHC143_248x242.jpg');
  background-size: cover;
}

.box-2 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/CarpetTile_248x242-1.jpg');
  background-size: cover;
}

.box-3 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/HardSurface_UrbanEscapes_248x242.jpg');
  background-size: cover;
}

.box-4 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/Rugs_LocalStyle_Vibe_Treasure_248x242.jpg');
  background-size: cover;
}

.box-5 {
  position: relative;
  background: url('/wp-content/uploads/2024/02/HomePage_BroadloomSquare_248x242.jpg');
  background-size: cover;
}

/*
.home-product-wrapper .left {
  position: relative;
  width: 494px;
  height: 494px;
  background: url('/wp-content/uploads/2018/12/HomePage-OverheadCorridor@2x.jpg');
  background-size: 100%;
  bottom:0;
  top:0;
}
*/


.home-product-text {
  position: absolute;
  top: 75%;
  right: 0;
  left: 0;
  padding:15px;
  line-height:1;
  height:100%;
}

.home-product-text .home-product-header {
  font-family: 'Adelle Sans';
  font-size: 16px;
  line-height: 0;
  color: #fff;
}

.home-product-text .home-product-subheader {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  line-height: 1;
  color: #A28D5B;
}

/*
.home-product-wrapper .right {
  width: 494px;
  height: 494px;
  display:grid;
  grid-template-columns: 1fr, 1fr;
}
*/


.photo .home-product-box-text {
  position: absolute;
  top: 75%;
  right: 0;
  left: 0;
  padding:15px;
}

.photo .home-product-box-text .home-product-header {
  font-family: 'Adelle Sans';
  font-size: 16px;
  line-height: 0;
  color: #fff;
  margin-bottom: 20px;
}

.photo .home-product-box-text .home-product-subheader {
  font-family: 'Adelle Sans Lt';
  font-size: 12px;
  line-height: 1;
  color: #A28D5B;
}

.home-property-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height:448px;
}

.home-property-wrapper .home-property-left {
  position: relative;
  background: linear-gradient(rgba(100,52,96,0.69),rgba(100,52,96,0.69)), url('/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-wrapper .home-property-right {
  position: relative;
  background: linear-gradient(rgba(0,66,106,0.69),rgba(0,66,106,0.69)),url('/wp-content/uploads/2018/12/homepage-sigliving.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-text {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 20%;
}

.home-property-text .home-property-header {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  border-bottom: 1px solid #A28D5B;
  color: #fff;
}
.home-property-text .home-property-tag {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #fff;
  width:95%;
}

.home-blog-section {
  background: #DDD;
  height:600px;
  
}
.home-blog-section-mobile {
  background: #DDD;
  height:700px;
}

.home-find-rep-wrapper {
  background: linear-gradient(rgba(37,40,42,0.75),rgba(37,40,42,0.75)),url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  min-height: 400px;
}

.home-find-rep-text {
  color: #fff;
  text-align: center;
  padding-top:150px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.home-find-rep-button {
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
}

.home-product-section-sustainability {	
  width: 1024px;
}

.home-product-text-header {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #fff;
  margin-bottom: 25px;
}

.home-product-text-header-sustainability {
  font-family: 'Adelle Sans';
  font-size: 30px;
  color: #fff;
  margin-bottom: 25px;
}

.home-product-text-subheader {
  font-family: 'Adelle Sans Lt';
  font-size: 12px;
  color: #A28D5B;
  margin-bottom: 10px;
}

.home-section-sustainability {
  padding-top: 80px;	
  width: 1034px;
}


@media only screen and (max-width: 600px) {
	
	.home-product-section-sustainability {	
	  width: 400px;
	}
	
	.home-product-section {	
	  margin-top: 80px;
	}
	
	.home-section-sustainability {	
	  width: 400px;
	}
	
	.home-sustainability-wrapper {
	  margin-top: 400px;
	}

}

/* W3.CSS 4.15 December 2020 by Jan Egil and Borge Refsnes */
html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section{display:block}summary{display:list-item}
audio,canvas,progress,video{display:inline-block}progress{vertical-align:baseline}
audio:not([controls]){display:none;height:0}[hidden],template{display:none}
a{background-color:transparent}a:active,a:hover{outline-width:0}
abbr[title]{border-bottom:none;text-decoration:underline;-webkit-text-decoration:underline dotted;text-decoration:underline dotted}
b,strong{font-weight:bolder}dfn{font-style:italic}mark{background:#ff0;color:#000}
small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}sup{top:-0.5em}figure{margin:1em 40px}img{border-style:none}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}hr{box-sizing:content-box;height:0;overflow:visible}
button,input,select,textarea,optgroup{font:inherit;margin:0}optgroup{font-weight:bold}
button,input{overflow:visible}button,select{text-transform:none}
button,[type=button],[type=reset],[type=submit]{-webkit-appearance:button}
button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring{outline:1px dotted ButtonText}
fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em}
legend{color:inherit;display:table;max-width:100%;padding:0;white-space:normal}textarea{overflow:auto}
[type=checkbox],[type=radio]{padding:0}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px}
[type=search]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
/* End extract */
html,body{font-family:Verdana,sans-serif;font-size:15px;line-height:1.5}html{overflow-x:hidden}
h1{font-size:36px}h2{font-size:30px}h3{font-size:24px}h4{font-size:20px}h5{font-size:18px}h6{font-size:16px}
.w3-serif{font-family:serif}.w3-sans-serif{font-family:sans-serif}.w3-cursive{font-family:cursive}.w3-monospace{font-family:monospace}
h1,h2,h3,h4,h5,h6{font-family:"Segoe UI",Arial,sans-serif;font-weight:400;margin:10px 0}.w3-wide{letter-spacing:4px}
hr{border:0;border-top:1px solid #eee;margin:20px 0}
.w3-image{max-width:100%;height:auto}img{vertical-align:middle}a{color:inherit}
.w3-table,.w3-table-all{border-collapse:collapse;border-spacing:0;width:100%;display:table}.w3-table-all{border:1px solid #ccc}
.w3-bordered tr,.w3-table-all tr{border-bottom:1px solid #ddd}.w3-striped tbody tr:nth-child(even){background-color:#f1f1f1}
.w3-table-all tr:nth-child(odd){background-color:#fff}.w3-table-all tr:nth-child(even){background-color:#f1f1f1}
.w3-hoverable tbody tr:hover,.w3-ul.w3-hoverable li:hover{background-color:#ccc}.w3-centered tr th,.w3-centered tr td{text-align:center}
.w3-table td,.w3-table th,.w3-table-all td,.w3-table-all th{padding:8px 8px;display:table-cell;text-align:left;vertical-align:top}
.w3-table th:first-child,.w3-table td:first-child,.w3-table-all th:first-child,.w3-table-all td:first-child{padding-left:16px}
.w3-btn,.w3-button{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;cursor:pointer;white-space:nowrap}
.w3-btn:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}
.w3-btn,.w3-button{-webkit-touch-callout:none;-webkit-user-select:none;user-select:none}   
.w3-disabled,.w3-btn:disabled,.w3-button:disabled{cursor:not-allowed;opacity:0.3}.w3-disabled *,:disabled *{pointer-events:none}
.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}
.w3-badge,.w3-tag{background-color:#000;color:#fff;display:inline-block;padding-left:8px;padding-right:8px;text-align:center}.w3-badge{border-radius:50%}
.w3-ul{list-style-type:none;padding:0;margin:0}.w3-ul li{padding:8px 16px;border-bottom:1px solid #ddd}.w3-ul li:last-child{border-bottom:none}
.w3-tooltip,.w3-display-container{position:relative}.w3-tooltip .w3-text{display:none}.w3-tooltip:hover .w3-text{display:inline-block}
.w3-ripple:active{opacity:0.5}.w3-ripple{transition:opacity 0s}
.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #ccc;width:100%}
.w3-select{padding:9px 0;width:100%;border:none;border-bottom:1px solid #ccc}
.w3-dropdown-click,.w3-dropdown-hover{position:relative;display:inline-block;cursor:pointer}
.w3-dropdown-hover:hover .w3-dropdown-content{display:block}
.w3-dropdown-hover:first-child,.w3-dropdown-click:hover{background-color:#ccc;color:#000}
.w3-dropdown-hover:hover > .w3-button:first-child,.w3-dropdown-click:hover > .w3-button:first-child{background-color:#ccc;color:#000}
.w3-dropdown-content{cursor:auto;color:#000;background-color:#fff;display:none;position:absolute;min-width:160px;margin:0;padding:0;z-index:1}
.w3-check,.w3-radio{width:24px;height:24px;position:relative;top:6px}
.w3-sidebar{height:100%;width:200px;background-color:#fff;position:fixed!important;z-index:1;overflow:auto}
.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-main,#main{transition:margin-left .4s}
.w3-modal{z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.w3-modal-content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;width:600px}
.w3-bar{width:100%;overflow:hidden}.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar .w3-bar-item{padding:8px 16px;float:left;width:auto;border:none;display:block;outline:0}
.w3-bar .w3-dropdown-hover,.w3-bar .w3-dropdown-click{position:static;float:left}
.w3-bar .w3-button{white-space:normal}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
.w3-responsive{display:block;overflow-x:auto}
.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.s1{width:8.33333%}.w3-col.s2{width:16.66666%}.w3-col.s3{width:24.99999%}.w3-col.s4{width:33.33333%}
.w3-col.s5{width:41.66666%}.w3-col.s6{width:49.99999%}.w3-col.s7{width:58.33333%}.w3-col.s8{width:66.66666%}
.w3-col.s9{width:74.99999%}.w3-col.s10{width:83.33333%}.w3-col.s11{width:91.66666%}.w3-col.s12{width:99.99999%}
@media (min-width:601px){.w3-col.m1{width:8.33333%}.w3-col.m2{width:16.66666%}.w3-col.m3,.w3-quarter{width:24.99999%}.w3-col.m4,.w3-third{width:33.33333%}
.w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:66.66666%}
.w3-col.m9,.w3-threequarter{width:74.99999%}.w3-col.m10{width:83.33333%}.w3-col.m11{width:91.66666%}.w3-col.m12{width:99.99999%}}
@media (min-width:993px){.w3-col.l1{width:8.33333%}.w3-col.l2{width:16.66666%}.w3-col.l3{width:24.99999%}.w3-col.l4{width:33.33333%}
.w3-col.l5{width:41.66666%}.w3-col.l6{width:49.99999%}.w3-col.l7{width:58.33333%}.w3-col.l8{width:66.66666%}
.w3-col.l9{width:74.99999%}.w3-col.l10{width:83.33333%}.w3-col.l11{width:91.66666%}.w3-col.l12{width:99.99999%}}
.w3-rest{overflow:hidden}.w3-stretch{margin-left:-16px;margin-right:-16px}
.w3-content,.w3-auto{margin-left:auto;margin-right:auto}.w3-content{max-width:980px}.w3-auto{max-width:1140px}
.w3-cell-row{display:table;width:100%}.w3-cell{display:table-cell}
.w3-cell-top{vertical-align:top}.w3-cell-middle{vertical-align:middle}.w3-cell-bottom{vertical-align:bottom}
.w3-hide{display:none!important}.w3-show-block,.w3-show{display:block!important}.w3-show-inline-block{display:inline-block!important}
@media (max-width:1205px){.w3-auto{max-width:95%}}
@media (max-width:600px){.w3-modal-content{margin:0 10px;width:auto!important}.w3-modal{padding-top:30px}
.w3-dropdown-hover.w3-mobile .w3-dropdown-content,.w3-dropdown-click.w3-mobile .w3-dropdown-content{position:relative}	
.w3-hide-small{display:none!important}.w3-mobile{display:block;width:100%!important}.w3-bar-item.w3-mobile,.w3-dropdown-hover.w3-mobile,.w3-dropdown-click.w3-mobile{text-align:center}
.w3-dropdown-hover.w3-mobile,.w3-dropdown-hover.w3-mobile .w3-btn,.w3-dropdown-hover.w3-mobile .w3-button,.w3-dropdown-click.w3-mobile,.w3-dropdown-click.w3-mobile .w3-btn,.w3-dropdown-click.w3-mobile .w3-button{width:100%}}
@media (max-width:768px){.w3-modal-content{width:500px}.w3-modal{padding-top:50px}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-hide-large{display:none!important}.w3-sidebar.w3-collapse{display:block!important}}
@media (max-width:992px) and (min-width:601px){.w3-hide-medium{display:none!important}}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}.w3-auto{max-width:100%}}
.w3-top,.w3-bottom{position:fixed;width:100%;z-index:1}.w3-top{top:0}.w3-bottom{bottom:0}
.w3-overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:2}
.w3-display-topleft{position:absolute;left:0;top:0}.w3-display-topright{position:absolute;right:0;top:0}
.w3-display-bottomleft{position:absolute;left:0;bottom:0}.w3-display-bottomright{position:absolute;right:0;bottom:0}
.w3-display-middle{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%)}
.w3-display-left{position:absolute;top:50%;left:0%;transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}
.w3-display-topmiddle{position:absolute;left:50%;top:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-bottommiddle{position:absolute;left:50%;bottom:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-container:hover .w3-display-hover{display:block}.w3-display-container:hover span.w3-display-hover{display:inline-block}.w3-display-hover{display:none}
.w3-display-position{position:absolute}
.w3-circle{border-radius:50%}
.w3-round-small{border-radius:2px}.w3-round,.w3-round-medium{border-radius:4px}.w3-round-large{border-radius:8px}.w3-round-xlarge{border-radius:16px}.w3-round-xxlarge{border-radius:32px}
.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 8px}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-code,.w3-codespan{font-family:Consolas,"courier new";font-size:16px}
.w3-code{width:auto;background-color:#fff;padding:8px 12px;border-left:4px solid #4CAF50;word-wrap:break-word}
.w3-codespan{color:crimson;background-color:#f1f1f1;padding-left:4px;padding-right:4px;font-size:110%}
.w3-card,.w3-card-2{box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)}
.w3-card-4,.w3-hover-shadow:hover{box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)}
.w3-spin{animation:w3-spin 2s infinite linear}@keyframes w3-spin{0%{transform:rotate(0deg)}100%{transform:rotate(359deg)}}
.w3-animate-fading{animation:fading 10s infinite}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;animation:animatetop 0.4s}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;animation:animateleft 0.4s}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;animation:animateright 0.4s}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;animation:animatebottom 0.4s}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.w3-opacity,.w3-hover-opacity:hover{opacity:0.60}.w3-opacity-off,.w3-hover-opacity-off:hover{opacity:1}
.w3-opacity-max{opacity:0.25}.w3-opacity-min{opacity:0.75}
.w3-greyscale-max,.w3-grayscale-max,.w3-hover-greyscale:hover,.w3-hover-grayscale:hover{filter:grayscale(100%)}
.w3-greyscale,.w3-grayscale{filter:grayscale(75%)}.w3-greyscale-min,.w3-grayscale-min{filter:grayscale(50%)}
.w3-sepia{filter:sepia(75%)}.w3-sepia-max,.w3-hover-sepia:hover{filter:sepia(100%)}.w3-sepia-min{filter:sepia(50%)}
.w3-tiny{font-size:10px!important}.w3-small{font-size:12px!important}.w3-medium{font-size:15px!important}.w3-large{font-size:18px!important}
.w3-xlarge{font-size:24px!important}.w3-xxlarge{font-size:36px!important}.w3-xxxlarge{font-size:48px!important}.w3-jumbo{font-size:64px!important}
.w3-left-align{text-align:left!important}.w3-right-align{text-align:right!important}.w3-justify{text-align:justify!important}.w3-center{text-align:center!important}
.w3-border-0{border:0!important}.w3-border{border:1px solid #ccc!important}
.w3-border-top{border-top:1px solid #ccc!important}.w3-border-bottom{border-bottom:1px solid #ccc!important}
.w3-border-left{border-left:1px solid #ccc!important}.w3-border-right{border-right:1px solid #ccc!important}
.w3-topbar{border-top:6px solid #ccc!important}.w3-bottombar{border-bottom:6px solid #ccc!important}
.w3-leftbar{border-left:6px solid #ccc!important}.w3-rightbar{border-right:6px solid #ccc!important}
.w3-section,.w3-code{margin-top:16px!important;margin-bottom:16px!important}
.w3-margin{margin:16px!important}.w3-margin-top{margin-top:16px!important}.w3-margin-bottom{margin-bottom:16px!important}
.w3-margin-left{margin-left:16px!important}.w3-margin-right{margin-right:16px!important}
.w3-padding-small{padding:4px 8px!important}.w3-padding{padding:8px 16px!important}.w3-padding-large{padding:12px 24px!important}
.w3-padding-16{padding-top:16px!important;padding-bottom:16px!important}.w3-padding-24{padding-top:24px!important;padding-bottom:24px!important}
.w3-padding-32{padding-top:32px!important;padding-bottom:32px!important}.w3-padding-48{padding-top:48px!important;padding-bottom:48px!important}
.w3-padding-64{padding-top:64px!important;padding-bottom:64px!important}
.w3-padding-top-64{padding-top:64px!important}.w3-padding-top-48{padding-top:48px!important}
.w3-padding-top-32{padding-top:32px!important}.w3-padding-top-24{padding-top:24px!important}
.w3-left{float:left!important}.w3-right{float:right!important}
.w3-button:hover{color:#000!important;background-color:#ccc!important}
.w3-transparent,.w3-hover-none:hover{background-color:transparent!important}
.w3-hover-none:hover{box-shadow:none!important}
/* Colors */
.w3-amber,.w3-hover-amber:hover{color:#000!important;background-color:#ffc107!important}
.w3-aqua,.w3-hover-aqua:hover{color:#000!important;background-color:#00ffff!important}
.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#2196F3!important}
.w3-light-blue,.w3-hover-light-blue:hover{color:#000!important;background-color:#87CEEB!important}
.w3-brown,.w3-hover-brown:hover{color:#fff!important;background-color:#795548!important}
.w3-cyan,.w3-hover-cyan:hover{color:#000!important;background-color:#00bcd4!important}
.w3-blue-grey,.w3-hover-blue-grey:hover,.w3-blue-gray,.w3-hover-blue-gray:hover{color:#fff!important;background-color:#607d8b!important}
.w3-green,.w3-hover-green:hover{color:#fff!important;background-color:#4CAF50!important}
.w3-light-green,.w3-hover-light-green:hover{color:#000!important;background-color:#8bc34a!important}
.w3-indigo,.w3-hover-indigo:hover{color:#fff!important;background-color:#3f51b5!important}
.w3-khaki,.w3-hover-khaki:hover{color:#000!important;background-color:#f0e68c!important}
.w3-lime,.w3-hover-lime:hover{color:#000!important;background-color:#cddc39!important}
.w3-orange,.w3-hover-orange:hover{color:#000!important;background-color:#ff9800!important}
.w3-deep-orange,.w3-hover-deep-orange:hover{color:#fff!important;background-color:#ff5722!important}
.w3-pink,.w3-hover-pink:hover{color:#fff!important;background-color:#e91e63!important}
.w3-purple,.w3-hover-purple:hover{color:#fff!important;background-color:#9c27b0!important}
.w3-deep-purple,.w3-hover-deep-purple:hover{color:#fff!important;background-color:#673ab7!important}
.w3-red,.w3-hover-red:hover{color:#fff!important;background-color:#f44336!important}
.w3-sand,.w3-hover-sand:hover{color:#000!important;background-color:#fdf5e6!important}
.w3-teal,.w3-hover-teal:hover{color:#fff!important;background-color:#009688!important}
.w3-yellow,.w3-hover-yellow:hover{color:#000!important;background-color:#ffeb3b!important}
.w3-white,.w3-hover-white:hover{color:#000!important;background-color:#fff!important}
.w3-black,.w3-hover-black:hover{color:#fff!important;background-color:#000!important}
.w3-grey,.w3-hover-grey:hover,.w3-gray,.w3-hover-gray:hover{color:#000!important;background-color:#9e9e9e!important}
.w3-light-grey,.w3-hover-light-grey:hover,.w3-light-gray,.w3-hover-light-gray:hover{color:#000!important;background-color:#f1f1f1!important}
.w3-dark-grey,.w3-hover-dark-grey:hover,.w3-dark-gray,.w3-hover-dark-gray:hover{color:#fff!important;background-color:#616161!important}
.w3-pale-red,.w3-hover-pale-red:hover{color:#000!important;background-color:#ffdddd!important}
.w3-pale-green,.w3-hover-pale-green:hover{color:#000!important;background-color:#ddffdd!important}
.w3-pale-yellow,.w3-hover-pale-yellow:hover{color:#000!important;background-color:#ffffcc!important}
.w3-pale-blue,.w3-hover-pale-blue:hover{color:#000!important;background-color:#ddffff!important}
.w3-text-amber,.w3-hover-text-amber:hover{color:#ffc107!important}
.w3-text-aqua,.w3-hover-text-aqua:hover{color:#00ffff!important}
.w3-text-blue,.w3-hover-text-blue:hover{color:#2196F3!important}
.w3-text-light-blue,.w3-hover-text-light-blue:hover{color:#87CEEB!important}
.w3-text-brown,.w3-hover-text-brown:hover{color:#795548!important}
.w3-text-cyan,.w3-hover-text-cyan:hover{color:#00bcd4!important}
.w3-text-blue-grey,.w3-hover-text-blue-grey:hover,.w3-text-blue-gray,.w3-hover-text-blue-gray:hover{color:#607d8b!important}
.w3-text-green,.w3-hover-text-green:hover{color:#4CAF50!important}
.w3-text-light-green,.w3-hover-text-light-green:hover{color:#8bc34a!important}
.w3-text-indigo,.w3-hover-text-indigo:hover{color:#3f51b5!important}
.w3-text-khaki,.w3-hover-text-khaki:hover{color:#b4aa50!important}
.w3-text-lime,.w3-hover-text-lime:hover{color:#cddc39!important}
.w3-text-orange,.w3-hover-text-orange:hover{color:#ff9800!important}
.w3-text-deep-orange,.w3-hover-text-deep-orange:hover{color:#ff5722!important}
.w3-text-pink,.w3-hover-text-pink:hover{color:#e91e63!important}
.w3-text-purple,.w3-hover-text-purple:hover{color:#9c27b0!important}
.w3-text-deep-purple,.w3-hover-text-deep-purple:hover{color:#673ab7!important}
.w3-text-red,.w3-hover-text-red:hover{color:#f44336!important}
.w3-text-sand,.w3-hover-text-sand:hover{color:#fdf5e6!important}
.w3-text-teal,.w3-hover-text-teal:hover{color:#009688!important}
.w3-text-yellow,.w3-hover-text-yellow:hover{color:#d2be0e!important}
.w3-text-white,.w3-hover-text-white:hover{color:#fff!important}
.w3-text-black,.w3-hover-text-black:hover{color:#000!important}
.w3-text-grey,.w3-hover-text-grey:hover,.w3-text-gray,.w3-hover-text-gray:hover{color:#757575!important}
.w3-text-light-grey,.w3-hover-text-light-grey:hover,.w3-text-light-gray,.w3-hover-text-light-gray:hover{color:#f1f1f1!important}
.w3-text-dark-grey,.w3-hover-text-dark-grey:hover,.w3-text-dark-gray,.w3-hover-text-dark-gray:hover{color:#3a3a3a!important}
.w3-border-amber,.w3-hover-border-amber:hover{border-color:#ffc107!important}
.w3-border-aqua,.w3-hover-border-aqua:hover{border-color:#00ffff!important}
.w3-border-blue,.w3-hover-border-blue:hover{border-color:#2196F3!important}
.w3-border-light-blue,.w3-hover-border-light-blue:hover{border-color:#87CEEB!important}
.w3-border-brown,.w3-hover-border-brown:hover{border-color:#795548!important}
.w3-border-cyan,.w3-hover-border-cyan:hover{border-color:#00bcd4!important}
.w3-border-blue-grey,.w3-hover-border-blue-grey:hover,.w3-border-blue-gray,.w3-hover-border-blue-gray:hover{border-color:#607d8b!important}
.w3-border-green,.w3-hover-border-green:hover{border-color:#4CAF50!important}
.w3-border-light-green,.w3-hover-border-light-green:hover{border-color:#8bc34a!important}
.w3-border-indigo,.w3-hover-border-indigo:hover{border-color:#3f51b5!important}
.w3-border-khaki,.w3-hover-border-khaki:hover{border-color:#f0e68c!important}
.w3-border-lime,.w3-hover-border-lime:hover{border-color:#cddc39!important}
.w3-border-orange,.w3-hover-border-orange:hover{border-color:#ff9800!important}
.w3-border-deep-orange,.w3-hover-border-deep-orange:hover{border-color:#ff5722!important}
.w3-border-pink,.w3-hover-border-pink:hover{border-color:#e91e63!important}
.w3-border-purple,.w3-hover-border-purple:hover{border-color:#9c27b0!important}
.w3-border-deep-purple,.w3-hover-border-deep-purple:hover{border-color:#673ab7!important}
.w3-border-red,.w3-hover-border-red:hover{border-color:#f44336!important}
.w3-border-sand,.w3-hover-border-sand:hover{border-color:#fdf5e6!important}
.w3-border-teal,.w3-hover-border-teal:hover{border-color:#009688!important}
.w3-border-yellow,.w3-hover-border-yellow:hover{border-color:#ffeb3b!important}
.w3-border-white,.w3-hover-border-white:hover{border-color:#fff!important}
.w3-border-black,.w3-hover-border-black:hover{border-color:#000!important}
.w3-border-grey,.w3-hover-border-grey:hover,.w3-border-gray,.w3-hover-border-gray:hover{border-color:#9e9e9e!important}
.w3-border-light-grey,.w3-hover-border-light-grey:hover,.w3-border-light-gray,.w3-hover-border-light-gray:hover{border-color:#f1f1f1!important}
.w3-border-dark-grey,.w3-hover-border-dark-grey:hover,.w3-border-dark-gray,.w3-hover-border-dark-gray:hover{border-color:#616161!important}
.w3-border-pale-red,.w3-hover-border-pale-red:hover{border-color:#ffe7e7!important}.w3-border-pale-green,.w3-hover-border-pale-green:hover{border-color:#e7ffe7!important}
.w3-border-pale-yellow,.w3-hover-border-pale-yellow:hover{border-color:#ffffcc!important}.w3-border-pale-blue,.w3-hover-border-pale-blue:hover{border-color:#e7ffff!important}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Medium.376be6b5.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Medium.010795ca.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity .150s ease-in-out;
}

.carousel-item > img {
  width: 100%;
  max-height: 600px;
  background-size: cover;
}

.carousel-caption-background {
  background: #000;
  z-index: 10;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  left: 63%;
  bottom: 20%;
  z-index: 10;
  padding-top: 250px;
  color: #fff;
  text-align: center;
  top: 0;
  height:100px;
}

.carousel-button {
  margin-top:15px;
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  padding:10px;
  width:150px;
  z-index: 10;
}

.carousel-caption .card {
  width:250px;
  min-height:250px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.carousel-caption .card-title {
  font-family: 'Zahrah';
  font-size: 40px;
  line-height: 45px;
  text-align: center;
}

.carousel-caption .card-text {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  text-align:center;
}

.home-blog-wrapper {
  display: grid;
  grid-template-rows: 150px 1fr;
}

.home-blog-wrapper .home-blog-header{
  margin:0 auto;
  padding-top:25px!important;
}

p.home-blog-header-text {
  color: #25282A;
  font-size: 26px;
  margin: 0 auto;
}

.home-blog-wrapper .home-blog-entries {
  width:100%;
  height: 600px;
  margin: 0 auto;
}

.home-blog-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px;
}
*/

.card-group.card-group-scroll > .card2 {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px;
}
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.home-slider-card {
  background: #fff;
  height: 430px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}

.home-blog-wrapper {
  display: grid;
  grid-template-rows: 1fr;
}

.home-blog-wrapper .home-blog-header{
  margin:0 auto;
  padding-top:25px;
}

p.home-blog-header-text {
  color: #25282A;
  font-size: 26px;
  margin: 0 auto;
}

.home-blog-wrapper .home-blog-entries {
  width:100%;
  height: 600px;
  margin: 0 auto;
}

.home-blog-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px;
}
*/

.card-group.card-group-scroll > .card2 {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px;
}
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.home-slider-card {
  background: #fff;
  height: 430px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}

.home-property-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height:448px;
}

.home-property-wrapper .home-property-left {
  position: relative;
  background: linear-gradient(rgba(100,52,96,0.69),rgba(100,52,96,0.69)), url('/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-wrapper .home-property-right {
  position: relative;
  background: linear-gradient(rgba(0,66,106,0.69),rgba(0,66,106,0.69)),url('/wp-content/uploads/2019/03/SeniorLiving-Segment-bg.jpg');
  background-size: cover;
  bottom:0;
  top:0;
}

.home-property-text {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 20%;
}

.home-property-text .home-property-header {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  border-bottom: 1px solid #A28D5B;
  color: #fff;
}
.home-property-text .home-property-tag {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #fff;
  width:95%;
}

.signow-wrapper {
  background: #fff;
  padding-top: 100px;
  padding-bottom: 50px;
}

.signow-wrapper a:hover {
  text-decoration: none;
}

.signow-logo img {
  padding-left: 25px;
  padding-bottom: 5px;
}

.signow-content {
  background: #A28D5B;
  height: 400px;
  color: #fff;
  position: relative;
}

.signow-content-inner {
  display: flex;
  flex-direction: row;
}

.signow-content-inner-left {
  margin-left: 20px;
  margin-right: 60px;
  margin-top: 15px;
  font-size: 14px;
  width: 640px;
}

.signow-content-inner-right img {
  width: 800px;
}

.signow-content-box {
  position: absolute;
  left: 310px;
  top: 265px;
}

@media screen and (max-width: 425px) {
  .signow-wrapper {
    margin-top: 450px;
    height: 100%;
  }
  .signow-content-inner {
    flex-direction: column;
  }

  .signow-content-inner-left {
    width: 100%;
    padding-right: 25px;
  }

  .signow-content-inner-right img {
    width: 100%;
  }

  .signow-content {
    height: 100%;
  }

  .signow-content-box {
    display:none;
  }
  
  .home-blog-section-mobile {
    padding-top: 0!important;
  }

}

@media screen and (min-width: 736px) and (max-width: 1024px) {
  .signow-content-inner-right {
    width: 600px;
  }

  .signow-content-inner-right img {
    width: 600px;
  }
  
  .signow-content-box {
    position: absolute;
    left: 260px;
    top: 265px;
  }
}

.sustainability-wrapper {
  background: #D9D9D6;
  padding-top: 50px;
  padding-bottom: 50px;
}

.sustainability-text {
	font-family: 'Zahrah';
	font-size: 50px;
	color: #fff;
	padding-bottom: 100px;
	padding-left: 70px;
}

.sustainability-wrapper a:hover {
  text-decoration: none;
}

.sustainability-logo img {
  padding-left: 25px;
  padding-bottom: 5px;
}

.sustainability-content {
  background: #A28D5B;
  height: 400px;
  color: #fff;
  position: relative;
}

.sustainability-content-inner {
  display: flex;
  flex-direction: row;
}

.sustainability-content-inner-left {
  margin-left: 20px;
  margin-right: 60px;
  margin-top: 15px;
  font-size: 14px;
  width: 640px;
}

.sustainability-content-inner-right img {
  width: 800px;
}

.sustainability-content-box {
  position: absolute;
  left: 310px;
  top: 265px;
}

@media screen and (max-width: 450px) {
  .sustainability-wrapper {
    margin-top: 450px;
    height: 100%;
  }  

  .sustainability-content-inner {
    flex-direction: column;
  }

  .sustainability-content-inner-left {
    width: 100%;
    padding-right: 25px;
  }

  .sustainability-content-inner-right img {
    width: 100%;
  }

  .sustainability-content {
    height: 100%;
  }

  .sustainability-content-box {
    display:none;
  }
  
  .home-blog-section-mobile {
    padding-top: 0!important;
  }

}

@media screen and (min-width: 736px) and (max-width: 1024px) {
  .sustainability-content-inner-right {
    width: 600px;
  }

  .sustainability-content-inner-right img {
    width: 600px;
  }
  
  .sustainability-content-box {
    position: absolute;
    left: 260px;
    top: 265px;
  }
}

.project-installs-wrapper {
  background: #fff;
  padding-bottom: 50px;
  padding-top: 75px;
}
.project-installs-header {
  text-align: center;
}
.project-installs-header h4 {
  color: #25282A;
  font-size: 26px;
  -webkit-text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.project-installs-explore-button {
  text-align: center;
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 250px;
}

.project-installs-explore-button:hover {
  text-decoration: none;
  text-align: center;
  color: #fff;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: #a28d5b;
  display: inline-block;
  padding: 10px;
  width: 250px;
}

.featured-project-installs a {
  color: #000;
  text-decoration: none;
}

.featured-project-installs a:hover {
  text-decoration: none;
}
@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@media screen and (min-width: 765px) {
  /*.category-main-content .results {
    padding: 20px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 239px)!important;
    color: #444;
  }*/
}

/** DESKTOP */

#category-wrapper {
  background: #F8F8F8!important;
}
#category-container {
  margin: 0 auto;
  width:90%;
}

.category-header {
  text-align: center;
  padding-top:50px;
}
.category-header h1 {
  font-family: 'Zahrah';
  font-size: 56px;
  color: #25282A;
}

.category-header h1::first-letter {
  text-transform: capitalize;
}

.category-header p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}


.category-filterbar {
  border-bottom: 1px solid #CCC;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}
.category-filterbar-left {
  text-align: left;
  width:49%;
  display:inline-block;
}
.category-filterbar-right {
  text-align:right;
  width:50%;
  display:inline-block;
}
.category-filterbar-right a {
  text-decoration: none;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}

.category-main-content {
  display: grid;
  grid-template-columns: 250px 1fr;
  padding-bottom: 100px;
}

.category-main-content .filter {
  padding-top:25px;
  display: inline-block;
  margin-left:5px;
  margin-right: 5px;
  width: 225px;
}

.category-main-content .filter-item {
  border-bottom: .5px solid #CCC;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #777;
  
}

.category-main-content .filter-item .left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.category-main-content .filter-item .right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.right a {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #777;
}
.right a:hover {
  text-decoration: none;
  cursor: pointer;
}

.category-main-content .results {
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  /*grid-template-columns: repeat(3, 239px);*/
  grid-template-columns: repeat(auto-fill, minmax(239px, 239px));
  color: #444;
}

.results .product {
  padding: 20px;
}

.product-wrapper {
  color: #555;
  font-size: 16px;
  font-family: 'Adelle Sans';
  position: relative;
}

.product-wrapper img {
  width:239px;
  height: 239px;
}

.product-wrapper span {
  font-family: 'Adelle Sans Lt';
}

.product-wrapper a {
  color: #444;
}
.product-wrapper a:hover {
  color: #444;
  text-decoration: none;
}


#style-name-options, 
#construction-type-options, 
#dye-methods-options, 
#patterntypes-options, 
#segments-options, 
#enduses-options, 
#backing-options, 
#faceWeight-options,
#hsSize-options,
#thickness-options,
#wearlayer-options,
#finish-options,
#install-method-options,
#color-range-options {
  display: none;
}

.filter-options {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left:5px;
}


/** MOBILE **/
#category-wrapper-mobile {
  background: #F8F8F8;
}

#category-wrapper-mobile .category-header-mobile {
  text-align: center;
  padding-top:100px;
}
#category-wrapper-mobile .category-header-mobile h1 {
  font-family: 'Zahrah';
  font-size: 38px;
  color: #25282A;
}

#category-wrapper-mobile .category-header-mobile h1::first-letter {
  text-transform: capitalize;
}

#category-wrapper-mobile .category-header-mobile p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}

#category-wrapper-mobile .filter-button-holder-mobile {
  text-align: center;
  padding: 20px;
}

#category-wrapper-mobile .filter-button-holder-mobile .filter-button-mobile {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

#filter-mobile-id, 
#faceWeightMobile-options,
#constructionMobile-type-options, 
#dye-methodsMobile-options, 
#patterntypesMobile-options, 
#segmentsMobile-options, 
#endusesMobile-options, 
#backing-optionsMobile, 
#hsSizeMobile-options,
#thicknessMobile-options,
#wearlayerMobile-options,
#finishMobile-options,
#install-methodMobile-options,
#color-rangeMobile-options {
  display: none;
}

.filter-mobile .filter {
  padding-top:15px;
  display: inline-block;
  margin-left:15px;
  margin-right: 5px;
  width: 90%;
}

.filter-mobile .filter .filter-item {
  border-bottom: .5px solid #CCC;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #777;
  
}

.filter-mobile .filter  .filter-item .left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.filter-mobile .filter .filter-item .right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.right a {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #777;
}
.right a:hover {
  text-decoration: none;
  cursor: pointer;
}
.filter-mobile-buttons {
  padding: 10px;
  text-align: center;
  padding-bottom: 25px;
}
.filter-mobile-buttons  .filter-clear-button-mobile {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.filter-mobile-buttons  .filter-done-button-mobile {
  color: #FFF;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width:150px;
}


/** Product List */
.product-list-wrapper-mobile {
  display: block;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
}

.product-list-wrapper-mobile .product {
  /*padding: 20px;*/
}

.product-wrapper-mobile {
  color: #555;
  font-size: 16px;
  font-family: 'Adelle Sans';
}



.product-wrapper-mobile img {
  width:128px;
  height: 128px;
}

.product-wrapper-mobile p {
  margin-left: 10px;
}

.product-wrapper-mobile span {
  font-family: 'Adelle Sans Lt';
}

.product-wrapper-mobile a {
  color: #444;
}
.product-wrapper-mobile a:hover {
  color: #444;
  text-decoration: none;
}

.notify-badge-mobile{
  position: absolute;
  left:190px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}

.notify-badge{
  position: absolute;
  right: -20px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}

.sg-notify-badge{
  position: absolute;
  right: -30px;
  top: -30px;
  color:white;
  padding:5px;
  background: transparent;
  height: 75px;
}

.sg-notify-badge-mobile{
  position: absolute;
  left:190px;
  top: -20px;
  color:white;
  padding:5px;
  background: transparent;
  height: 75px;
}

.product-block {
  position: relative;
}

/** CSS for Rug Style Customize URL **/
.style-customize-container {
	position: relative;
	width: 50%;
	max-width: 239px;
	height: 50%;
	max-height: 239px;	
}

.style-customize-image {
	display: block;
}

.style-customize-button {
  position: relative;
  top: -80px;
  left: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  color: white;
  border-radius: 50px;
  text-align: center;
	padding-left: 25px;
	padding-right: 25px;
  width: 120px;
	height: 37px;
  font-family: "Adelle Sans Sb";
  font-size: 14px;
}

/** Desktop */	
@media screen and (min-width: 765px) {
  .style-customize-button-text {
    padding-top: 10px;
    margin: auto;
    color: white;
    text-align: center;
    font-family: "Adelle Sans Sb";
    font-size: 14px;	
  }
  .style-customize-button-text a:link, a:visited, a:hover, a:active{
    color: white;
    text-decoration: none;
  }
  .style-title-text a:link, a:visited, a:hover, a:active{
  color: #444;
 	text-decoration: none;
  }
}

.style-customize-button-text-mobile a:link, a:hover, a:active{
  color: #444;
 	text-decoration: none;
}

.style-customize-button-text-mobile a:visited{
  color: white;
 	text-decoration: none;
}
@font-face {
  font-family: "Zahrah";
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans Lt";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

.product-notify-badge {
  position: absolute;
  right: 0px;
  top: 15px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 125px;
}

.sg-product-notify-badge {
  position: absolute;
  right: 0px;
  top: 15px;
  color:white;
  padding:5px;
  background: transparent;
  height: 125px;
}

.product-page {
  margin: 0;
  padding-bottom: 100px;
  background: #f8f8f8;
}

.product-page-wrapper {
  margin: 0 auto;
}

.product-top {
  padding-top: 50px;
  padding-bottom: 100px;
  /*width: 60%;*/
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.product-page-top-inner {
  display: grid;
  grid-template-columns: 30% 1fr;
  width: 80%;
}

.product-breadcrumb {
  text-align: left;
}

.product-breadcrumb > a {
  color: #a28d5b;
  font-family: "Adelle Sans";
  font-size: 12px;
}
.product-breadcrumb > a:hover {
  text-decoration: none;
}

.product-swatches {
  padding-top: 15px;
  display: grid;
  /*grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));*/
  grid-template-columns: repeat(auto-fill, minmax(50%, 50%));
  width: 90%;
  min-height: 500px;
}

.product-swatches .left {
  display: inline;
}
.product-title h1 {
  font-family: "Zahrah";
  font-size: 40px;
  text-align: left;
}

.product-swatch-changer {
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #777;
  text-align: left;
}

.product-swatch-changer a {
  color: #444;
}

.product-swatch-changer a:hover {
  color: #a28d5b;
  text-decoration: underline;
}

.product-swatch-changer a.active-link {
  text-decoration: underline;
  color: #a28d5b;
}
.product-swatch-changer a.nonactive-link {
  color: #444;
}

.product-swatches-small {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 10px;
}
.swatch-box {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #25282a;
}

.swatch-box img.active-swatch {
  border: 5px solid black;
}

.swatch-box span {
  font-family: "Adelle Sans Lt";
  color: #777;
}

.product-swatches .right {
  padding-left: 20px;
  margin-left: 20px;
  max-height: 494px;
  min-width: 494px;
  position: relative;
}

.product-swatches-small a {
  font-family: "Adelle Sans Sb";
  font-size: 12px;
  color: #25282a;
}
.product-swatches-small a:hover {
  text-decoration: none;
}
.sample-button {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 150px;
}

a.sample-button:hover {
  color: #a28d5b;
}

.rug-button {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 150px;
}

.customize-button {
  position: relative;
  top: 85%;
  left: 60%;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  color: #fff;
  border-radius: 50px;
  padding: 10px;
  width: 150px;
  font-family: "Adelle Sans Sb";
  font-size: 14px;
}

.product-description-text {
  color: #fff;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  padding-top: 200px;
}

.product-description-heading {
  font-family: "Adelle Sans";
  font-size: 26px;
}

.product-description-tag {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  color: #d9d9d6;
  padding-top: 10px;
  padding-bottom: 10px;
}

a.brochure-button,
a.product-specs-download-button {
  text-align: center;
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 250px;
}
a.brochure-button,
a.product-specs-download-button:hover {
  text-decoration: none;
}

.product-specs {
  padding-top: 50px;
  width: 60%;
  margin: 0 auto;
  border-bottom: 0.5px solid #ccc;
  padding-bottom: 50px;
}

.product-specs-header,
.product-resources-header h2 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
}

.product-specs-details {
  width: 100%;
}

.product-specs-details .table td {
  border: none;
}
.product-resources-details .table td {
  border: none;
}
.product-specs-details .spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
}
.product-resources-details .spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
  display: block;
}

.product-resources-details .spec-title span {
  display: block;
  float: right;
  width: 50%;
  margin-left: 10px;
}

.product-specs-details {
  font-family: "Adelle Sans";
  font-size: 16px;
  color: #777;
}

.product-resources {
  padding-top: 50px;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.product-resources-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}

.product-resources-details .spec-data a {
  font-family: "Adelle Sans";
  font-size: 16px;
  color: #a28d5b;
  text-decoration: none;
}

.rug-specs-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.rug-specs-wrapper .rug-spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
  display: block;
}

.rug-specs-wrapper .rug-spec-title span {
  display: block;
  float: right;
  width: 50%;
  text-align: left;
  font-family: "Adelle Sans";
  font-size: 15px;
  color: #777;
}

.hs-specs-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.hs-specs-wrapper .hs-spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
  display: block;
}

.hs-specs-wrapper .hs-spec-title span {
  display: block;
  float: right;
  width: 50%;
  text-align: left;
  font-family: "Adelle Sans";
  font-size: 13px;
  color: #777;
}

/** MOBILE */

.product-page-wrapper-mobile {
  margin: 0 auto;
}

.product-top-mobile {
  padding-top: 50px;
  width: 100%;
  padding-left: 10px;
}

.product-breadcrumb-mobile > a {
  color: #a28d5b;
  font-family: "Adelle Sans";
  font-size: 12px;
}

.product-breadcrumb-mobile > a:hover {
  text-decoration: none;
}

.product-page {
  margin: 0;
  padding-bottom: 100px;
  background: #f8f8f8;
}

.product-page-top-inner {
  display: grid;
  grid-template-columns: 30% 1fr;
  width: 80%;
}

.product-breadcrumb > a {
  color: #a28d5b;
  font-family: "Adelle Sans";
  font-size: 12px;
}

.product-title-mobile h1 {
  text-align: center;
  font-family: "Zahrah";
  font-size: 40px;
}

.product-swatch-changer-mobile {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #777;
}

.product-swatch-changer-mobile a {
  color: #444;
}

.product-swatch-changer-mobile a:hover {
  color: #a28d5b;
  text-decoration: underline;
}

.product-swatch-changer-mobile a.active-link {
  text-decoration: underline;
  color: #a28d5b;
}
.product-swatch-changer-mobile a.nonactive-link {
  color: #444;
}

.colourway-roomscenes-mobile {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.product-swatches-small-mobile {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 110px));
  grid-gap: 20px;
}

.popup-content {
  width: 80% !important;
  height: 75% !important;
}

.order-sample-mobile-button button {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 150px;
}

.product-swatches-small-mobile a {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #25282a;
}
.product-swatches-small-mobile a:hover {
  text-decoration: none;
}

.product-description-text-mobile {
  color: #fff;
  text-align: center;
  width: 85%;
  margin: 0 auto;
  padding-top: 100px;
}

.product-specs-mobile,
.product-resources-mobile {
  padding-top: 50px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.spec-group-mobile {
  padding-bottom: 3px;
  text-align: left;
  padding-left: -20px;
}

.spec-title-mobile {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
}
.spec-data-mobile {
  font-family: "Adelle Sans";
  font-size: 16px;
  color: #777;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
}

button:focus {outline:0;}

.close {
  color: #A28D5B;
  font-family: 'Adelle Sans Lt';
  font-size: 2rem;
  font-weight: normal;
  padding-right: 50px;
  padding-top:10px;
}

.popup-content {
  width: 100%!important;
  height: 95%!important;
  padding:0!important;
  display:inline-block;
}
.cart-wrapper-mobile {
  width: 100%;
  max-height: 90%;
  overflow-x: auto;
  overflow-y: auto;
  z-index: 1;
}

.cart-header-mobile {
  width: 100%;
  background: #FFF;
  display: inline-block;
}

.cart-header-text-mobile {
  display: inline-block;
  width:100%;
  padding-top: 50px;
  padding-left: 10px;
}

.cart-header-text-mobile h1 {
  font-size: 20px;
  font-family: 'Adelle Sans';
  color: #25282A;
  text-align: left;
}

.cart-header-mobile p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #888888;
}

.cart-header-text-mobile p.sample-count {
  text-align: center;
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
  margin-top:-5px;
}

.cart-top-nav {
  padding-left:20px;
}

.cart-top-nav a {
  font-family: 'Adelle Sans';
  font-size: 12px;
  color: #777;
}
.cart-top-nav a:hover, a.active-cart-nav {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration: underline;

}

.cart-items {
  min-height: 200px;
  width: 100%;
  padding-left:30px;
}

.item-name {
  text-align: left;
}
.item-name .item-headers {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
}
.item-name .color-name {
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
  margin-top:-5px;
}
table {
  width: 80%;
  margin: 0 auto;
}
td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.cart-item-row {
  border-bottom: .5px solid #CCC;
}

.cart-buttons, .checkout-buttons {
  text-align: center;
  padding: 50px;
}

.cart-buttons .continue-shopping {
  color: #A28D5B;
  border:1px solid #A28D5B;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.cart-buttons .checkout-button-front {
  color: #fff;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 100px;
}

.form-label {
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
}

.qty-select select {
  border: 0;
  border-bottom:1px solid #A28D5B;
  background: #fff;
}

.checkout-form-div {
  width:60%;
  margin: 0 auto;
  min-height: 300px;
  max-height: 600px;
  padding-top:20px;
  z-index: auto;
  text-align: left;
}

.checkout-form-div p {
  font-family: 'Adelle Sans';
  font-size: 11px;
  font-style: italic;
  color: #AAA;
}
.row, .fullRow {
  padding: 10px;
}
.col {
  float: left;
  width: 50%
}

.row label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.row input {
  border:0;
  border-bottom: .5px solid #CCC;
  width:100%;
}

.fullRow label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.fullRow input {
  border:0;
  border-bottom: .5px solid #CCC;
  width:100%;
}

.checkout-buttons .continue-shopping{
  color: #A28D5B;
  border:1px solid #A28D5B;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.checkout-buttons .checkout-button-front{
  color: #fff;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 150px;
}


.frmStateSelect select {
  border: 0;
  border-bottom: .5px solid #CCC;
  background: #fff;
}

.error{
  margin-left: 5px;
  font-size: 12px;
  font-family: 'Adelle Sans';
  color: red;
}

.thank-you {
  margin: 0 auto;
  padding-top:50px;
  padding-left: 30px;
  padding-right: 30px;
}

.thank-you p {
  text-align: center;
  font-family: 'Adelle Sans';
  font-size: 1rem;
  color: #777;
  padding-bottom: 50px;
}
/*http://www.signatureflooring.local/wp-content/uploads/2019/02/rs-bona-fide.png*/
@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .sales-rep-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SIG_ElementsDissolved_7411_Nickel_Herringbone_320_486.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:85%!important;
    width: 100%!important;
    margin-left:10px!important;
  }
  .header-content nav {
    margin-left: -40px!important;
    position: absolute!important;
    top: 95%!important;
  }

  .sales-rep-filter-form {
    background: #F8F8F8;
    width: 100%;
    height: 125px!important;
  }
  .sales-rep-filter-form ul {
    margin-left:-40px!important;
  }
  .sales-rep-filter-form li {
    display: inline-block;
    padding-top: 25px;
    padding-left:25px;
  }
  
  .sales-rep-filter-form li > label {
    font-family: 'Adelle Sans';
    font-size: 11px;
    color: #AAA;
  }
  
  .sales-rep-filter-form li > select {
    border: 0;
    border-bottom: .5px solid #ccc;
  }

  .sales-rep-cell {
    text-align: left!important;
  }

  .sales-reps-data {
    grid-template-columns: 1fr!important;
    padding-left: 20%!important;
  }

  .sales-rep-image {
    float: none!important;
  }
  .sales-rep-details {
    padding-top: 10px;
    float: none!important;
    text-align: left;
    padding-left:10px;
  }

  .sales-rep-hear-from-you {
    width: 100%;
    height: 100%!important;
    background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2019/03/sales-rep-hear-mobile-bg.png')!important;
    margin: 0 auto;
  }
  
  .sales-rep-hear-from-you h2 {
    font-family: 'Adelle Sans Lt';
    font-size: 26px!important;
    padding-left: 50px;
    padding-right: 50px;
    color: #FFF;
    text-align: center;
    padding-top: 50px!important;
    padding-bottom: 50px;
  }
  
  .sales-rep-contact-ways-wrapper {
    color: #fff;
    display: grid;
    grid-template-columns: 1fr!important;
    grid-gap: 0!important;
    justify-items:normal!important;
    padding-left: 25px!important;
  }

  .contact-ways1, .contact-ways2 {
    padding-bottom: 30px!important
  }
}

@media screen and (max-width: 780px) {
  .sales-reps-data {
    grid-template-columns: 1fr!important;
    padding-left: 20%!important;
  }
}

.sales-rep-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/02/sales-rep-header-active.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header-content {
  height: 450px;
  position: relative;
}
.header-content h2 {
  font-family: 'Adelle Sans';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.header-content nav {
  margin-left: 50px;
  position: absolute;
  top: 90%;
}

.header-content nav li {
  display: inline-block;
}

.header-content nav li a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left: 15px;
}

.header-content nav li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
}

.header-content nav li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B!important;
          text-decoration-color: #A28D5B!important;
}

.sales-rep-filter-form {
  background: #F8F8F8;
  width: 100%;
  height: 125px;
}
.sales-rep-filter-form ul {
  margin-left:50px;
}
.sales-rep-filter-form li {
  display: inline-block;
  padding-top: 25px;
  padding-left:25px;
}

.sales-rep-filter-form li > label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.sales-rep-filter-form li > select {
  border: 0;
  border-bottom: .5px solid #ccc;
}

.sales-reps-wrapper {
  background: #fff;
  height: 100%;
  margin: 0 auto;
}

.sales-reps-data {
  width:100%;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, minmax(50%, 50%));
  justify-items: left;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  height: 100%;
}

.sales-rep-image {
  float: left;
}
.sales-rep-details {
  padding-top: 10px;
  float: left;
  text-align: left;
  padding-left:10px;
}

.sales-rep-details h3 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  font-color: #25282A;
}

.sales-rep-details h5 {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.sales-rep-details p {
  padding-top: 3px;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
}

.sales-rep-details p > i {
  color: #A28D5B;
}

.sales-rep-hear-from-you {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2019/02/sales-rep-hear-from-you-bg.png');
  margin: 0 auto;
}

.sales-rep-hear-from-you h2 {
  font-family: 'Adelle Sans Lt';
  font-size: 30px;
  color: #FFF;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.sales-rep-contact-ways-wrapper {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  justify-items: center;
}

.rep-segments {
  font-family: 'Adelle Sans';
  font-size: 12px;
  color: #FFF;
  opacity: .8;
}

.rep-segments span.segment-hospitality {
  background: #643460;
  padding: 2px;
}

.rep-segments span {
  padding-left:5px;
}

.rep-segments span.segment-senior-living {
  background: #00426A;
  padding:2px;
}

.contact-ways-header {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #AAA;
}

.contact-ways {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #D9D9D6;
}

.contact-email {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@media screen and (max-width: 735px) {
  .resources-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SIG_NatInstincts_AspenGlow_CK2_320_486.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .resources-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:90%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .resources-main-content {
    padding-top: 25px!important;
  }

  .resources-wrapper {
    padding-top: 50px!important;
    grid-template-columns: 1fr!important;
    justify-items: left!important;
  }
  .resources-col-1 {
    padding-left: 0!important;
  }

  .spec-col-1 {
    grid-template-columns: 1fr!important;
  }

}
.resources-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/02/resources-header@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.resources-header-content {
  height: 450px;
  position: relative;
}
.resources-header-content h2 {
  font-family: 'Adelle Sans';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.resources-header-content nav {
  margin-left: 50px;
  position: absolute;
  top: 90%;
}

.resources-header-content nav li {
  display: inline-block;
}

.resources-header-content nav li a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left: 15px;
}

.resources-header-content nav li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
}

.resources-header-content nav li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B!important;
          text-decoration-color: #A28D5B!important;
}


/** Resources Content */
.resources-main-content {
  background: #F8F8F8;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0 auto;
}

.resources-main-content h2 {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
  text-align: center;
}

.resources-main-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 70%;
}

.resources-main-content .resources-wrapper {
  padding-top: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  justify-items: center;
}

.resources-wrapper {
  width: 80%;
  margin: 0 auto;
}

.resources-col-header {
  font-family: 'Adelle Sans';
  font-size: 12px;
  color: #AAA;
}
.resources-col-links {
  min-width: 150px;
}
.resources-col-links a {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
  text-decoration: none;
  padding-bottom: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/** Need Help */
.resources-need-help {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.40),rgba(37,40,42,0.40)), url('/wp-content/uploads/2019/02/resources-need-help@2x.jpg');
  margin: 0 auto;
  color: #fff;
}

.resources-need-help-text {
  color: #fff;
  text-align: center;
  padding-top:100px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.resources-need-help-text h2 {
  padding-bottom: 30px;
}

.resources-need-help-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.resources-find-rep-button {
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 225px;
}

.resources-find-rep-button:hover {
  text-decoration: none;
  color: #25282A;
}

.resources-col-1 .spec-col-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

.resources-col-1 {
  padding-left: 200px;
}

.resources-col-2 .spec-col-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

span.product-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 70%;
}

:focus {
  outline : none;
}
.cd-wrapper {
  background: linear-gradient(rgba(37,40,42,0.75),rgba(37,40,42,0.75)),url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  min-height: 400px;
  text-align: center;
}


.cd-text {
  color: #fff;
  text-align: center;
  padding-top:100px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.cd-text h2 {
  padding-bottom: 30px;
}

.cd-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  width: 604px;
  margin: 0 auto;
  padding-bottom: 30px;
  width: 80%;
}

.cd-learn-more-button {
  border-color: transparent;
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 166px;
  cursor: pointer;
}
.cd-learn-more-button { 
  outline: none;
}

.cd-find-rep-button {
  border-color: transparent;
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background: transparent;
  display: inline-block;
  padding:10px;
  width: 166px;
  cursor: pointer;
  outline: none;
}

.cd-find-rep-button:focus {
  outline: none;
}


.ds-featuredstyles-wrapper {
  display: grid;
  grid-template-rows: 50px 1fr;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-header{
  margin:0 auto;
  padding-top:75px;
}

p.ds-featuredstyles-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-entries {
  width:100%;
  height: 450px;
  margin: 0 auto;
}

.ds-featuredstyles-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px
}
*/
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  padding-left: 10px;
  padding-top:3px;
}

h5.hospitality-card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  padding-left: 10px;
  padding-top:3px;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.cardbody > p > a {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  text-decoration: none;
}
.cardbody > p > a:hover {
  text-decoration: none;
}
.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.ds-slider-card {
  background: #fff;
  height: 400px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}

@font-face {
  font-family: "Adelle Sans Lt";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Adelle Sans Sb";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  
}

@media screen and (max-width: 735px) {
  .hospitality-header {
    width: 100% !important;
    height: 450px !important;
    background:
      url("/wp-content/uploads/2024/10/Rendezvous_OasisSG007_SPC9120_1280x450.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .hospitality-generic-video-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/ZD477-Plains_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .hospitality-rendezvous-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/12/Rendezvous_StacieGarcia_1280x500_v3.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .hospitality-lamina-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/10/Lamina_JR154_1280x500.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .hospitality-boldacious-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/10/Boldacious_HC141_1280x500.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .hospitality-living-sketches-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/10/LivingSketches_HC131_1280x500.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .hospitality-emerge-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/10/Emerge_HC122_1280x500.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .hospitality-outbound-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/10/Outbound_HC051_1280x500.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .hospitality-header3 {
    width: 100% !important;
    height: 486px !important;
    background:
      url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .hospitality-marketing-block .smb-text {
    padding-top: 45px;
    padding-left: 10px;
    padding-right: 30px;
  }
  
  .hospitality-above-fold {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  .hdiw-col p {
    text-align: center;
  }

  .ds-featuredstyles-section {
    display: none;
  }
  
}

@media screen and (min-width: 736px) and (max-width: 1280px) {
  .ds-featuredstyles-section-mobile {
    display: none;
  }
}

.hospitality-header {
  width: 100%;
  height: 450px;
  background:
    url("/wp-content/uploads/2024/10/Rendezvous_OasisSG007_SPC9120_1280x450.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-header-content {
  height: 500px;
  position: relative;
}

.hospitality-header-content h2 {
  font-family: "Zahrah";
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: 70%;
  margin-left: 30px;
}
.hospitality-header-content h5 {
  font-family: "Zahrah";
  font-size: 16px !important;
  color: #fff;
  position: absolute;
  top: 83% !important;
  width: 100% !important;
  margin-left: 30px !important;
}

.hospitality-generic-video-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/ZD477-Plains_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-generic-video-hero-content {
  height: 500px;
  position: relative;
}

.hospitality-rendezvous-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/12/Rendezvous_StacieGarcia_1280x500_v3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.hospitality-rendezvous-collection-hero-content {
  height: 500px;
  position: relative;
}


.hospitality-rendezvous-oasis-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/12/Rendezvous-Oasis-RS_SG007_1280x500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.hospitality-rendezvous-oasis-collection-hero-content {
  height: 500px;
  position: relative;
}


.hospitality-lamina-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/10/Lamina_JR154_1280x500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-lamina-collection-hero-content {
  height: 500px;
  position: relative;
}

.hospitality-boldacious-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/10/Boldacious_HC141_1280x500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-boldacious-collection-hero-content {
  height: 500px;
  position: relative;
}

.hospitality-living-sketches-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/10/LivingSketches_HC131_1280x500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-living-sketches-collection-hero-content {
  height: 500px;
  position: relative;
}

.hospitality-emerge-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/10/Emerge_HC122_1280x500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-emerge-collection-hero-content {
  height: 500px;
  position: relative;
}

.hospitality-outbound-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/10/Outbound_HC051_1280x500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-outbound-collection-hero-content {
  height: 500px;
  position: relative;
}

.hospitality-header3 {
  width: 100%;
  height: 450px;
  background:
    url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hospitality-header3-content {
  height: 450px;
  position: relative;
}

.ds-slider-card h5.card-title {
  padding-left: 0px !important;
}

.hospitality-above-fold {
  height: 300px;
  width: 1280px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #fff;
}

.hospitality-above-fold h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
}

.hospitality-above-fold p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 75%;
}

.hospitality-video- {
  background: #f8f8f8;
  padding-top: 30px;
  /*padding-bottom: 100px;*/

  height: 100%;
  margin-bottom: -6px;
  text-align: center;
}

.hospitality-video- h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.hospitality-how-does-it-work {
  background: #f8f8f8;
  padding-top: 75px;
  padding-bottom: 100px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.hospitality-how-does-it-work h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.how-does-it-work-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 80%;
  margin: 0 auto;
}

.hdiw-col img {
  height: 260px;
  width: 260px;
}

.hdiw-col p {
  font-family: "Adelle Sans Lt";
  font-size: 14px;
  color: #777;
  text-align: center;
}

.hdiw-col h4 {
  padding-top: 20px;
  font-family: "Adelle Sans Sb";
  font-size: 20px;
  color: #25282a;
}

.design-button .design-row-button-large {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 200px;
}
.ds-featuredstyles-section {
  background: #ddd;
  height: 500px;
}


.hospitality-marketing-block {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hospitality-marketing-block .smb-graphic {
  padding-left: 20px;
  flex: 25% 1;
}

.hospitality-marketing-block .smb-text {
  padding-top: 45px;
  flex: 60% 1;
  width: 80%;
  padding-right: 10px;
}

.hospitality-marketing-block .smb-text p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;  
}

.hospitality-marketing-block .smb-text p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.hospitality-marketing-block .smb-youtube {
  padding-left: 20px;
  flex: 30% 1;
  width: 30%;
  margin-right: -60px;
}

.hospitality-marketing-block .smb-graphic2 {
  padding-left: 20px;
  flex: 25% 1;
  width: 25%;
}

.hospitality-marketing-block .smb-text2 {
  padding-top: 0px;
  flex: 35% 1;
  width: 35%;
  padding-right: 10px;
  padding-left: 10px;
}

.hospitality-marketing-block .smb-text2 p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;
  
}

.hospitality-marketing-block .smb-text2 p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.hospitality-grid {
  display: grid;
  background-color: #FFFFFF;
  grid-template-columns: repeat(4, 1fr);;
}

.grid-column {
  background-color: #ffffff; /* Adjust the background color as needed */
  padding: 5px; /* Adjust the padding as needed */
  width: 25%;
  text-align: center; /* Center the text */
}

.ds-featuredstyles-wrapper {
  display: grid;
  grid-template-rows: 50px 1fr;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-header{
  margin:0 auto;
  padding-top:75px;
}

p.ds-featuredstyles-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-entries {
  width:100%;
  height: 450px;
  margin: 0 auto;
}

.ds-featuredstyles-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px
}
*/
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  padding-left: 10px;
  padding-top:3px;
}

h5.senior-living-card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  padding-left: 10px;
  padding-top:3px;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.cardbody > p > a {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  text-decoration: none;
}
.cardbody > p > a:hover {
  text-decoration: none;
}
.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.ds-slider-card {
  background: #fff;
  height: 400px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}

@font-face {
  font-family: "Adelle Sans Lt";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Adelle Sans Sb";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  
}

@media screen and (max-width: 735px) {
  .senior-living-header {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG259-I70112-PD072_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .senior-living-regional-tapestry-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/ZD477-Plains_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-aquarella-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG259-Z70204_PD076_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-common-threads-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG258-B7032-7201_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-terra-cruda-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG-TerraCruda-TileF7414-Form7424_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-reflective-echoes-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/05/SIG-ReflectiveEchoes_Hero.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-polished-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG_Polished_ZD455_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-urban-escapes-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/10/Urban-Escapes-1280x500-HeroFinal_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-local-connections-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/10/Local-Connections-1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .senior-living-header3 {
    width: 100% !important;
    height: 486px !important;
    background:
      url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-marketing-block .smb-text {
    padding-top: 45px;
    padding-left: 10px;
    padding-right: 30px;
  }
  
  .senior-living-above-fold {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  .hdiw-col p {
    text-align: center;
  }

  .ds-featuredstyles-section {
    display: none;
  }
  
}

@media screen and (min-width: 736px) and (max-width: 1280px) {
  .ds-featuredstyles-section-mobile {
    display: none;
  }
}

.senior-living-header {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG259-I70112-PD072_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-header-content {
  height: 500px;
  position: relative;
}

.senior-living-header-content h2 {
  font-family: "Zahrah";
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: 70%;
  margin-left: 30px;
}
.senior-living-header-content h5 {
  font-family: "Zahrah";
  font-size: 16px !important;
  color: #fff;
  position: absolute;
  top: 83% !important;
  width: 100% !important;
  margin-left: 30px !important;
}

.senior-living-regional-tapestry-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/ZD477-Plains_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-regional-tapestry-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-aquarella-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG259-Z70204_PD076_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-aquarella-collection-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-common-threads-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG258-B7032-7201_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-common-threads-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-terra-cruda-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG-TerraCruda-TileF7414-Form7424_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-terra-cruda-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-reflective-echoes-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/05/SIG-ReflectiveEchoes_Hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-reflective-echoes-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-polished-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG_Polished_ZD455_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-polished-collection-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-urban-escapes-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/10/Urban-Escapes-1280x500-HeroFinal_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-urban-escapes-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-local-connections-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/10/Local-Connections-1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-local-connections-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-header3 {
  width: 100%;
  height: 450px;
  background:
    url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-header3-content {
  height: 450px;
  position: relative;
}

.ds-slider-card h5.card-title {
  padding-left: 0px !important;
}

.senior-living-above-fold {
  height: 300px;
  width: 1280px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #fff;
}

.senior-living-above-fold h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
}

.senior-living-above-fold p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 75%;
}

.senior-living-video- {
  background: #f8f8f8;
  padding-top: 30px;
  /*padding-bottom: 100px;*/

  height: 100%;
  margin-bottom: -6px;
  text-align: center;
}

.senior-living-video- h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.senior-living-how-does-it-work {
  background: #f8f8f8;
  padding-top: 75px;
  padding-bottom: 100px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.senior-living-how-does-it-work h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.how-does-it-work-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 80%;
  margin: 0 auto;
}

.hdiw-col img {
  height: 260px;
  width: 260px;
}

.hdiw-col p {
  font-family: "Adelle Sans Lt";
  font-size: 14px;
  color: #777;
  text-align: center;
}

.hdiw-col h4 {
  padding-top: 20px;
  font-family: "Adelle Sans Sb";
  font-size: 20px;
  color: #25282a;
}

.design-button .design-row-button-large {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 200px;
}
.ds-featuredstyles-section {
  background: #ddd;
  height: 500px;
}


.senior-living-marketing-block {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.senior-living-marketing-block .smb-graphic {
  padding-left: 20px;
  flex: 25% 1;
}

.senior-living-marketing-block .smb-text {
  padding-top: 45px;
  flex: 60% 1;
  width: 80%;
  padding-right: 10px;
}

.senior-living-marketing-block .smb-text p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;  
}

.senior-living-marketing-block .smb-text p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.senior-living-marketing-block .smb-youtube {
  padding-left: 20px;
  flex: 30% 1;
  width: 30%;
  margin-right: -60px;
}

.senior-living-marketing-block .smb-graphic2 {
  padding-left: 20px;
  flex: 25% 1;
  width: 25%;
}

.senior-living-marketing-block .smb-text2 {
  padding-top: 0px;
  flex: 35% 1;
  width: 35%;
  padding-right: 10px;
  padding-left: 10px;
}

.senior-living-marketing-block .smb-text2 p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;
  
}

.senior-living-marketing-block .smb-text2 p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.ds-featuredstyles-wrapper {
  display: grid;
  grid-template-rows: 150px 1fr;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-header{
  margin:0 auto;
  padding-top:75px;
}

p.ds-featuredstyles-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-entries {
  width:100%;
  height: 550px;
  margin: 0 auto;
}

.ds-featuredstyles-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px
}
*/
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  padding-left: 10px;
  padding-top:3px;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.cardbody > p > a {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  text-decoration: none;
}
.cardbody > p > a:hover {
  text-decoration: none;
}
.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.ds-slider-card {
  background: #fff;
  height: 400px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}

@font-face {
  font-family: "Adelle Sans Lt";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Adelle Sans Sb";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  
}

@media screen and (max-width: 735px) {
  .design-studio-header {
    width: 100% !important;
    height: 486px !important;
    background: linear-gradient(to bottom, transparent 0%, black 175%),
      url("/wp-content/uploads/2020/04/spark-page-header-scaled.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .spark-marketing-block .smb-text {
    padding-top: 45px;
    padding-left: 10px;
    padding-right: 30px;
  }
  
  .design-studio-above-fold {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  .hdiw-col p {
    text-align: center;
  }

  .ds-featuredstyles-section {
    display: none;
  }
  
}

@media screen and (min-width: 736px) and (max-width: 1280px) {
  .ds-featuredstyles-section-mobile {
    display: none;
  }
}

.design-studio-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 175%),
    url("/wp-content/uploads/2020/04/spark-page-header-scaled.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.design-studio-header-content {
  height: 450px;
  position: relative;
}

.design-studio-header-content h2 {
  font-family: "Zahrah";
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: 70%;
  margin-left: 30px;
}
.design-studio-header-content h5 {
  font-family: "Zahrah";
  font-size: 16px !important;
  color: #fff;
  position: absolute;
  top: 83% !important;
  width: 100% !important;
  margin-left: 30px !important;
}

.ds-slider-card h5.card-title {
  padding-left: 0px !important;
}

.design-studio-above-fold {
  height: 300px;
  width: 1280px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #fff;
}

.design-studio-above-fold h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
}

.design-studio-above-fold p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 75%;
}

.spark-video {
  background: #f8f8f8;
  padding-top: 30px;
  /*padding-bottom: 100px;*/

  height: 100%;
  margin-bottom: -6px;
  text-align: center;
}

.spark-video h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.design-studio-how-does-it-work {
  background: #f8f8f8;
  padding-top: 75px;
  padding-bottom: 100px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.design-studio-how-does-it-work h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.how-does-it-work-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 80%;
  margin: 0 auto;
}

.hdiw-col img {
  height: 260px;
  width: 260px;
}

.hdiw-col p {
  font-family: "Adelle Sans Lt";
  font-size: 14px;
  color: #777;
  text-align: center;
}

.hdiw-col h4 {
  padding-top: 20px;
  font-family: "Adelle Sans Sb";
  font-size: 20px;
  color: #25282a;
}

.design-button .design-row-button-large {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 200px;
}
.ds-featuredstyles-section {
  background: #ddd;
  height: 700px;
}


.spark-marketing-block {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.spark-marketing-block .smb-graphic {
  padding-left: 20px;
  flex: 40% 1;
}

.spark-marketing-block .smb-text {
  padding-top: 45px;
  flex: 60% 1;
  width: 80%;
  padding-right: 10px;
}

.spark-marketing-block .smb-text p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;
  
}

.spark-marketing-block .smb-text p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.ds-featuredstyles-wrapper {
  display: grid;
  grid-template-rows: 50px 1fr;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-header{
  margin:0 auto;
  padding-top:75px;
}

p.ds-featuredstyles-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.ds-featuredstyles-wrapper .ds-featuredstyles-entries {
  width:100%;
  height: 450px;
  margin: 0 auto;
}

.ds-featuredstyles-entries .card-group {
  background: #DDD;
}
/*
.card-group.card-group-scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.card-group.card-group-scroll > .card {
  flex-basis: 324px;
  margin-left: 20px;
  width: 324px;
  height: 370px
}
*/
h5.card-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  padding-left: 10px;
  padding-top:3px;
}

h5.card-title a {
  text-decoration: none;
  color: #25282A;
}

.cardbody > p > a {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
  text-decoration: none;
}
.cardbody > p > a:hover {
  text-decoration: none;
}
.card-img-top {
  border-bottom: 1px solid #DDD;
}


.home-slider-container {
  max-width:100%;
  margin: 0 auto;
}
.ds-slider-card {
  background: #fff;
  height: 400px;
}
.cardPadding {
  padding-right:5px;
  padding-left: 5px;
}

@font-face {
  font-family: "Adelle Sans Lt";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Adelle Sans Sb";
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format("woff2"),
    url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  
}

@media screen and (max-width: 735px) {
  .bdny-2021-header {
    width: 100% !important;
    height: 486px !important;
    background:
      url("/wp-content/uploads/2021/11/SIG230-BDNY_header_mobile_cropped.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .bdny-2021-header2 {
    width: 100% !important;
    height: 486px !important;
    background:
      url("/wp-content/uploads/2021/11/SIG230_JourneyB7012-Safari_1077x450.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .bdny-2021-header3 {
    width: 100% !important;
    height: 486px !important;
    background:
      url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .bdny-marketing-block .smb-text {
    padding-top: 45px;
    padding-left: 10px;
    padding-right: 30px;
  }
  
  .bdny-2021-above-fold {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  .hdiw-col p {
    text-align: center;
  }

  .ds-featuredstyles-section {
    display: none;
  }
  
}

@media screen and (min-width: 736px) and (max-width: 1280px) {
  .ds-featuredstyles-section-mobile {
    display: none;
  }
}

.bdny-2021-header {
  width: 100%;
  height: 450px;
  background:
    url("/wp-content/uploads/2021/11/SIG230-BDNY_header_1200x580_cropped.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.bdny-2021-header-content {
  height: 450px;
  position: relative;
}

.bdny-2021-header-content h2 {
  font-family: "Zahrah";
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: 70%;
  margin-left: 30px;
}
.bdny-2021-header-content h5 {
  font-family: "Zahrah";
  font-size: 16px !important;
  color: #fff;
  position: absolute;
  top: 83% !important;
  width: 100% !important;
  margin-left: 30px !important;
}

.bdny-2021-header2 {
  width: 100%;
  height: 450px;
  background:
    url("/wp-content/uploads/2021/11/SIG230_JourneyB7012-Safari_1077x450.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.bdny-2021-header2-content {
  height: 450px;
  position: relative;
}

.bdny-2021-header3 {
  width: 100%;
  height: 450px;
  background:
    url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.bdny-2021-header3-content {
  height: 450px;
  position: relative;
}

.ds-slider-card h5.card-title {
  padding-left: 0px !important;
}

.bdny-2021-above-fold {
  height: 300px;
  width: 1280px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #fff;
}

.bdny-2021-above-fold h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
}

.bdny-2021-above-fold p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 75%;
}

.bdny-video- {
  background: #f8f8f8;
  padding-top: 30px;
  /*padding-bottom: 100px;*/

  height: 100%;
  margin-bottom: -6px;
  text-align: center;
}

.bdny-video- h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.bdny-2021-how-does-it-work {
  background: #f8f8f8;
  padding-top: 75px;
  padding-bottom: 100px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.bdny-2021-how-does-it-work h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.how-does-it-work-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 80%;
  margin: 0 auto;
}

.hdiw-col img {
  height: 260px;
  width: 260px;
}

.hdiw-col p {
  font-family: "Adelle Sans Lt";
  font-size: 14px;
  color: #777;
  text-align: center;
}

.hdiw-col h4 {
  padding-top: 20px;
  font-family: "Adelle Sans Sb";
  font-size: 20px;
  color: #25282a;
}

.design-button .design-row-button-large {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 200px;
}
.ds-featuredstyles-section {
  background: #ddd;
  height: 500px;
}


.bdny-marketing-block {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bdny-marketing-block .smb-graphic {
  padding-left: 20px;
  flex: 40% 1;
}

.bdny-marketing-block .smb-text {
  padding-top: 45px;
  flex: 60% 1;
  width: 80%;
  padding-right: 10px;
}

.bdny-marketing-block .smb-text p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;
  
}

.bdny-marketing-block .smb-text p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}
.custom-collections-wrapper-desktop {
  background: #F8F8F8;
}

.custom-collections-container {
  margin: 0 auto;
  width: 75%;
}

.custom-collections-header {
  text-align: center;
  padding-top:50px;
  border-bottom: 1px solid #CCC;
  border-width: 80%;
}
.custom-collections-header h1 {
  font-family: 'Zahrah';
  font-size: 56px;
  color: #25282A;
}

.custom-collections-body {
  min-height: 1000px;
  padding-bottom: 100px;
  padding-top: 25px;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /*grid-template-rows: auto auto auto auto;*/
}

.custom-collections-wrapper span {
  font-family: 'Adelle Sans Lt';
}

.custom-collections-wrapper a {
  color: #444;
}
.custom-collections-wrapper a:hover {
  color: #444;
  text-decoration: none;
}


.custom-collections-header-mobile {
  font-family: 'Zahrah';
  text-align: center;
  padding-top:75px;
  border-bottom: 1px solid #CCC;
  border-width: 80%;
}

.collection-wrapper {
  position: relative;
}

.collections-notify-badge{
  position: absolute;
  right: 198px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}

.collections-notify-badge-mobile{
  position: absolute;
  left: 32px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}
.custom-collection-wrapper {
  background: #F8F8F8;
}

.custom-collection-container {
  margin: 0 auto;
  width: 75%;
}

.custom-collection-header {
  text-align: center;
  padding-top:50px;
  border-width: 80%;
}
.custom-collection-header h1 {
  font-family: 'Zahrah';
  font-size: 56px;
  color: #25282A;
}
.custom-collection-header h1::first-letter {
  text-transform: uppercase;
}

.custom-collection-body {
  min-height: 1000px;
  padding-bottom: 100px;
}

.custom-collection-wrapper span {
  font-family: 'Adelle Sans Lt';
}

.custom-collection-wrapper a {
  color: #444;
}
.custom-collection-wrapper a:hover {
  color: #444;
  text-decoration: none;
}


.collection-grid {
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(239px, 239px)); /* see notes below */
  grid-gap: 1em;
}

.custom-collection-body {
  width: 100%;
}

.image-element-class {
  position: relative;
  list-style: none;
  padding: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

/*.image-element-class:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, .3);
}
*/

.button {
  position: absolute;
  left:64px;
  top: 45%;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}

.button button {
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  color: #fff;
  border-radius: 50px;
  padding: 10px;
  width: 150px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  z-index: 1;
  cursor: pointer;
}

.image-element-class:hover .button {
  opacity: 1;
}


.custom-collection-actionbar {
  border-bottom: 1px solid #CCC;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}
.custom-collection-actionbar-left {
  text-align: left;
  width:49%;
  display:inline-block;
}

.custom-collection-actionbar-left a {
  color: #A28D5B;
  font-family: 'Adelle Sans';
  font-size: 12px;
}
.custom-collection-actionbar-left a:hover {
  text-decoration: none;
  cursor: pointer;
}

.custom-collection-actionbar-right {
  text-align:right;
  width:50%;
  display:inline-block;
}
.custom-collection-actionbar-right a {
  text-decoration: none;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
}

.custom-collection-brochure-button {
  margin-top:15px;
  color: #A28D5B !important;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  padding:10px;
  width:150px;
  z-index: 10;
}

.custom-collection-container-mobile {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.custom-collection-header-mobile {
  text-align: center;
  padding-top:25px;
  border-width: 80%;
}

.image-element-mobile-class {
  right: 0!important;
  left: 0!important;
  text-align: center;
  list-style: none;
  padding: 20px;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .aboutus-studio-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/Tree_320_486.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
    margin-top: 50px!important;
  }
  .aboutus-studio-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:90%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .aboutus-history-content > h3{
    padding-top: 25px!important;
    padding-bottom: 25px!important;
    font-size: 26px!important;
  }

  
  .about-history-video-wrapper {
    width: 100%!important;
  }

  .about-us-video-block {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
  .about-video-desktop {
    display: none;
  }

  .aboutus-history-content {
    height: 1300px!important;
    padding-top: 450px!important;
  }
  .checkmarkWrapper {
    grid-template-columns: 1fr!important;
  }
  .about-check-left{
    padding-left: 56px!important;
  }
  .about-check-left > p {
    padding-left: 0px!important;
  }
  .about-check-right {
    padding-left: 0px!important;
  }

  .aboutus-hear-from-you {
    height: 800px!important;
  }
  .aboutus-contact-ways-wrapper {
    grid-template-columns: 1fr!important;
    grid-gap:0px!important;
    justify-items: left!important;
    padding-left: 25px!important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .checkmarkWrapper {
    grid-template-columns: 1fr!important;
    margin-left: 20px!important;
  }
  .about-check-left{
    padding: 0px!important;
  }
  .about-check-left > p {
    padding-left: 50px!important;
  }
  .about-check-right {
    padding-left: 0px!important;
  }
}

@media screen and (max-width: 1024px) {
  .about-check-left {
    padding-left: 50px!important;
  }
}

.aboutus-history-content {
  height: 500px;
  text-align: center;
  margin: 0 auto;
  /*display: table-cell;*/
  vertical-align: middle;
  background: #FFF;
}

.aboutus-history-content p {
  text-align: center;
  margin: 0 auto;
  width:75%;
}

.aboutus-studio-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/03/Tree.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.aboutus-studio-header-content {
  height: 450px;
  position: relative;
}

.aboutus-studio-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.aboutus-above-fold {
  height: 300px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
}

.aboutus-history {
  height: 300px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
}

.aboutus-above-fold p {
  width: 36em;
  margin: 0 auto;
  max-width: 100%;
  line-height: 1.5;
}

.about-us-video-block {
  background: #F8F8F8;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.about-history-video-wrapper {
  margin:0 auto;
  text-align: center;
  width:650px;
}

.about-history-video-wrapper.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.aboutus-history-content {
  padding-top: 50px;
}

.aboutus-history p {
  width: 36em;
  margin: 0 auto;
  max-width: 100%;
  line-height: 1.5;
}

.checkmarkWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(50%,50%));
  margin:0 auto;
  /*max-width: 100%;*/
  padding-top: 20px;
  padding-bottom: 100px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}
.about-check-left {
  padding-left: 150px;
}
.about-check-left > p {
  padding: 5px;
  text-align: left;
  padding-left:150px;
  width: 100%;
}

.about-check-right {
 
}

.about-check-right > p {
  padding: 5px;
  text-align: left;
}

.aboutus-hear-from-you {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  margin: 0 auto;
}

.aboutus-hear-from-you h2 {
  font-family: 'Adelle Sans Lt';
  font-size: 30px;
  color: #FFF;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.aboutus-contact-ways-wrapper {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  justify-items: center;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .tradeshows-header {
    width: 100%!important;
    height: 486px!important;
    background: url('/wp-content/uploads/2024/12/Trade-Shows-Hero.png')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .tradeshows-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:85%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .tradeshows-hospitality-tradeshows .tradeshows-list {
    grid-template-columns: 1fr!important;
  }

  .tradeshows-wrapper > h3 {
    padding-top:25px;
  }
}

.tradeshows-header {
  width: 100%;
  height: 450px;
  background: url('/wp-content/uploads/2024/12/Trade-Shows-Hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.tradeshows-header-content {
  height: 450px;
  position: relative;
}

.tradeshows-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}
.tradeshows-hospitality-tradeshows {
  min-height: 500px;
  background: #643460;
  padding-bottom: 100px;
}

.tradeshows-senior-living-tradeshows {
  min-height: 500px;
  background: #00426A;
}

.tradeshows-general-tradeshows {
  min-height: 400px;
  background: #F8F8F8;
  padding-bottom: 100px;
}

.tradeshow-header {
  margin:0 auto;
  padding-top: 100px;
  text-align: center;
  padding-bottom: 50px;
}

.tradeshow-header-text {
  font-family: 'Adelle Sans';
  color: #FFF;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.tradeshow-header-text-general {
  font-family: 'Adelle Sans';
  color: #25282A;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.tradeshows-hospitality-tradeshows .tradeshows-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

/*.tradeshows-general-tradeshows .tradeshows-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}*/

.tradeshows-list .tradeshow-item {
  width: 75%;
  margin: 0 auto;
}

.tradeshow-item h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #fff;
}

.tradeshow-item a {
  text-decoration: none;
}
.tradeshow-item a:hover {
  text-decoration: none;
}

.tradeshow-item h5 {
  font-family: 'Adelle Sans Lt';
  font-size: 20px;
  color: #fff;
  opacity: .6;
}

.tradeshow-item p {
  padding-top: 5px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #FFF;
  opacity: .6;
}

.tradeshows-list .tradeshow-item-general {
  width: 75%;
  margin: 0 auto;
}

.tradeshow-item-general h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #25282A;
}

.tradeshow-item-general a {
  text-decoration: none;
}
.tradeshow-item-general a:hover {
  text-decoration: none;
}

.tradeshow-item-general h5 {
  font-family: 'Adelle Sans Lt';
  font-size: 20px;
  color: #777;
  opacity: .6;
}

.tradeshow-item-general p {
  padding-top: 5px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.tradeshow-gallery-section {
  padding-top: 20px;
  padding-bottom: 0px;  
}

.tradeshow-gallery-wrapper {

}


@media only screen and (max-width: 600px) {
	
	.tradeshow-gallery-section {	
	  width: 420px;
	}
	
	.tradeshow-gallery-wrapper {
	  /* margin-top: 400px; */
	}

}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .custom-design-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/CustomDesignHeader.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .custom-design-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:85%!important;
    width: 100%!important;
    margin-left:10px!important;
  }
  .custom-design-content-column-left, .custom-design-content-column-right {
    padding-left: 0px!important;
  }
  .custom-design-content-column-middle {
    display: none!important;
  }
  .custom-design-main-content-grid {
    grid-template-columns: 1fr!important;
  }
  .custom-design-content-column-left {
    grid-template-columns: 1fr!important;
  }
  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .cs-col3-row1, .cs-col3-row2 {
    padding-top: 50px!important;
    padding-left: 20px!important;
  }
}

@media screen and (max-width: 780px) {
  .custom-design-content-column-middle {
    display: none!important;
  }

  .custom-design-content-column-left, .custom-design-content-column-right {
    padding-left: 225px;
  }

  .custom-design-main-content-grid {
    grid-template-columns: 1fr!important;
  }
  .custom-design-content-column-left {
    grid-template-columns: 1fr!important;
  }

  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col1-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > h2 {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .custom-design-main-content .cs-col3-row1-content > p {
    text-align: left!important;
    padding-left: 20px!important;
  }

  .cs-col3-row1, .cs-col3-row2 {
    padding-top: 50px!important;
    padding-left: 20px!important;
  }
}

.custom-design-main-content .cs-col1-row1-content > h2 {
  text-align: right;
}
.custom-design-main-content .cs-col1-row1-content > h2 {
  text-align: right;
}

.custom-design-main-content .cs-col3-row1-content > h2 {
  text-align: left;
}
.custom-design-main-content .cs-col3-row1-content > h2 {
  text-align: left;
}

.custom-design-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/03/CustomDesignHeader.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.custom-design-header-content {
  height: 450px;
  position: relative;
}

.custom-design-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.custom-design-main-content {
  background: #FFF;
  text-align: center;
  margin: 0 auto;
}

.custom-design-main-content-grid {
  width: 700px;
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
  margin: 0 auto;
  text-align: center;
  min-height: 700px;
  background: #FFF;
  padding-bottom: 100px;
  padding-right: 850px;
}

.custom-design-content-column-left {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.cs-col1-row1-content {
  margin: 0 auto;
  width: 350px;
}
.cs-col1-row1-content img {
  padding-bottom: 10px;
}

.cs-col1-row1-content h2, p {
  text-align: right;
}

.cs-col1-row1-content h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A
}

.cs-col1-row1-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.cs-col3-row1-content {
  margin: 0 auto;
  width: 350px;
}
.cs-col3-row1-content img {
  padding-bottom: 10px;
}

.cs-col3-row1-content h2, p {
  text-align: left;
}

.cs-col3-row1-content h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A
}

.cs-col3-row1-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.cs-col3-row1 {
  padding-top: 375px;
}

.cs-col3-row2 {
  padding-top: 150px;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .media-page-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SIG_Reflect_Refract_Water_320_486-2.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  
  .media-page-header-content {
    height: 450px;
    position: relative;
  }
  .media-page-header-content h2 {
    font-family: 'Adelle Sans';
    font-size: 50px;
    color: #fff;
    position: absolute;
    top:80%!important;
    margin-left:10px!important;
  }

  .media-page-main-content {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }

  .pr-grid {
    grid-template-columns: 1fr!important;
  }

  .pr-item {
    padding-left: 5px!important;
    margin-right: 5px!important;
  }

  .media-page-pr {
    width: 100%!important;
  }
  .media-page-desktop-videos {
    display:none;
  }

  .media-page-ads {
    padding-left: 20%!important;
  }
  
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-grid {
    grid-template-columns: 1fr!important;
  }
  .media-page-main-content {
    padding-left: 10%!important;
    padding-right: 10%!important;
  }
  .media-page-pr {
    width: 100%!important;
  }
  .media-page-mobile-video {
    display: none;
  }

  .media-page-ads {
    padding-left: 17%!important;
  }
}


@media screen and (max-width: 1024px) {
  .media-page-main-content {
    padding-left: 10%!important;
    padding-right: 10%!important;
  }
}


.media-page-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/03/MediaHeader.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.media-page-header-content {
  height: 450px;
  position: relative;
}

.media-page-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.media-page-header-content nav {
  margin-left: 50px;
  position: absolute;
  top: 90%;
}

.media-page-header-content nav li {
  display: inline-block;
}

.media-page-header-content nav li a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left: 15px;
}

.media-page-header-content nav li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
}

.media-page-header-content nav li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B!important;
          text-decoration-color: #A28D5B!important;
}

.media-page-above-fold {
  height: 200px;
  width: 100%;
  text-align: center;
  background: #FFF;
  padding-top: 75px;
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #25282A;
}

.media-page-main-content {
  min-height: 400px;
  background: #FFF;
  padding-left: 200px;
  padding-right: 200px;
}

.media-page-pr-year-links {
  text-align: center;
  margin: 0 auto;
}

.media-page-pr-year-links > a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
}

.media-page-pr-year-links > a:hover {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}

.media-page-pr-year-links > a.active-year {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}

.media-page-pr {
  margin: 0 auto;
  width: 900px;
}

.pr-grid {
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 25px;
}

.pr-item {
  padding-left: 50px;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B
}
.pr-item .pr-date {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #AAA;
}

.pr-item a {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
}
.pr-item a:hover {
  text-decoration: none;
}

.media-page .pr-grid .pr-item .pr-link {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #AAA;
}


.media-page-videos {
  text-align: center;
  padding-left: 25px;
  margin: 0 auto;
  font-family: 'Adelle Sans';
  font-size: 25px;
  color: #25282A;
}
.media-page-videos span {
  text-align: left;
}


.ad-grid {
  padding-top: 50px;
  padding-bottom: 100px;
  margin: 0 auto;
  padding-left: 0!important;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, 239px);
  grid-row-gap: 25px;
}

.ad-title {
  text-align: left;
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color: #25282A;
}

.ad-download-link a {
  text-align: left;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
}

.ad-download-link a:hover {
  text-decoration: none;
}

.media-page-need-help {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2019/03/media-page-need-help-bg.jpg');
  margin: 0 auto;
}

.media-page-need-help h2 {
  font-family: 'Adelle Sans Lt';
  font-size: 30px;
  color: #FFF;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.media-page-need-help-wrapper {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  justify-items: center;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

.sustainability-wrapper {
	background-color: #fff;
}

.sustainability-wrapper-2 {
	margin-left: 50px;
	margin-right: 50px;
	padding-top: 10px;
	padding-bottom: 30px;
	background-color: #fff;
}

.sustainability-wrapper-2-text-1 {
	font-family: 'Adelle Sans';
	font-size: 26px;
	color: #fff;
	margin-top: 15px;
	margin-bottom: 15px;
}

.sustainability-wrapper-2-text-2 {
	color: #fff;
	padding-top: 5px;
}

.sustainability-wrapper-2-text-3 {
	color: #8C8B89;
}

.sustainability-hero {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.sustainability-hero-content {
  height: 500px;
  position: relative;
}

.sustainability-marketing-block {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sustainability-marketing-block .smb-graphic {
  padding-left: 20px;
  flex: 25% 1;
}

.sustainability-marketing-block .smb-text {
  padding-top: 45px;
  flex: 60% 1;
  width: 80%;
  padding-right: 10px;
}

.sustainability-marketing-block .smb-text p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;  
}

.sustainability-marketing-block .smb-text p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.sustainability-marketing-block .smb-youtube {
  padding-left: 20px;
  flex: 30% 1;
  width: 30%;
  margin-right: -60px;
}

.sustainability-marketing-block .smb-graphic2 {
  padding-left: 20px;
  flex: 25% 1;
  width: 25%;
}

.sustainability-marketing-block .smb-text2 {
  padding-top: 0px;
  flex: 35% 1;
  width: 35%;
  padding-right: 10px;
  padding-left: 10px;
}

.sustainability-marketing-block .smb-text2 p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;
  
}

.sustainability-marketing-block .smb-text2 p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.sustainability-CRI-image {
  padding-top: 70px;
}

.sustainability-CRI-list {
  padding-left: 15px;
  font-size: 13px;
}

.sustainability-text-url {
  color: #a28d5b;
}

.sustainability-paragraph-block {
  padding-right: 30px;
}

.sustainability-programs-section {
	{/* height: 400px; */}
}

@media screen and (max-width: 735px) {
  .sustainability-video-desktop { display: none; }
  .sustainability-header {
    width: 100%!important;
    height: 486px!important;
    {/* background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SustainabilityHeader_320_486.jpg')!important; */}
	background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2024/02/SustainabilityHero_1280x500.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .sustainability-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:90%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .sustainability-video {
    width: 100%!important;
  }

  .biocel-laminate-graphic-desktop, .biocel-cushion-graphic-desktop {
    display: none!important;
  }

  .biocel-laminate-grid {
    grid-template-columns: 1fr!important;
    grid-gap: 0!important;
  }
  .biocel-check-left{
    padding: 10px!important;
  }
  .biocel-check-left > p {
    padding-left: 0px!important;
  }
  .biocel-check-right {
    padding-left: 50px!important;
  }
}

@media only screen and (max-width: 600px) {
	
	.sustainability-wrapper {
		margin-top: 0px;
	}
	
	.sustainability-container-2 {
		padding-top: 20px;
	}
	
	.smb-text {
		padding-left: 15px;
		padding-right: 15px;
	}
	
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .biocel-laminate-graphic-desktop, .biocel-cushion-graphic-desktop {
    display: none!important;
  }
  .biocel-laminate-grid {
    grid-template-columns: 1fr!important;
    margin-left: 20px!important;
  }
  .biocel-check-left{
    padding: 0px!important;
  }
  .biocel-check-left > p {
    padding-left: 50px!important;
  }
  .biocel-check-right {
    padding-left: 95px!important;
  }
}

@media screen and (max-width: 1024px) {
  .biocel-check-left {
    padding-left: 50px!important;
  }
}


.sustainability-header {
  width: 100%;
  height: 450px;
  /* background: linear-gradient(to bottom, transparent 0%, black 200%), url('/wp-content/uploads/2019/03/SustainabilityHeader.jpg'); */
  background: linear-gradient(to bottom, transparent 0%, black 200%), url('/wp-content/uploads/2024/02/SustainabilityHero_1280x500.jpg'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.sustainability-header-content {
  height: 450px;
  position: relative;
}

.sustainability-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.sustainability-above-fold {
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 25px;
  padding-bottom: 25px;
}

.sustainability-above-title {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
}

.sustainability-above-content {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  width: 85%;
  margin: 0 auto;
  text-align: center;
}

.sustainability-video-block {
  background: #F8F8F8;
  margin: 0 auto;
  text-align: center;
  padding-top: 25px;
}

.sustainability-video-title {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
  padding-bottom: 50px;
}

.sustainability-video {
  margin:0 auto;
  text-align: center;
  width:754px;
}

.sustainability-video-content p {
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 80%;
  text-align: center;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  margin: 0 auto;
}


.biocel-content {
  background: #fff;
  margin: 0 auto;
  height: 100%;
  padding-bottom: 100px;
}
.biocel-content-wrapper {
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
}
.biocel-header h2 {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
  padding-top: 50px;
  padding-bottom: 30px;
}


.biocel-nav > a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
}

.biocel-nav > a:hover {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}

.biocel-nav > a.active-biocel {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}
/*
.biocel-laminate-content {
  padding-top: 25px;
}
.biocel-laminate-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  line-height: 26px;
  margin: 0 auto;
  text-align: center;
}*/

.biocel-laminate-content {
  height: 150px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
}

.biocel-laminate-content p {
  text-align: center;
  margin: 0 auto;
  /*width:75%;*/
  height: 150px;
}

.biocel-laminate-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(50%,50%));
  margin:0 auto;
  /*max-width: 100%;*/
  padding-top: 0px;
  padding-bottom: 100px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}
.biocel-check-left {
  padding-left: 50px;
}
.biocel-check-left > p {
  padding: 5px;
  text-align: left;
  padding-left:150px;
  width: 100%;
}

.biocel-check-right > p {
  padding: 5px;
  text-align: left;
}

.sustainability-leed {
  width: 100%;
  height: 348px;
  background: linear-gradient(rgba(37,40,42,0.60),rgba(37,40,42,0.60)), url('/wp-content/uploads/2019/03/SustainabilityLead.jpg');
  background-attachment: 'fixed';
  background-position: center;
  background-repeat: 'no-repeat';
  background-size: 'cover';  
  margin: 0 auto;
  color: #fff;
  text-align: center;
}



.sustainability-need-help {
  width: 100%;
  height: 348px;
  background: linear-gradient(rgba(37,40,42,0.60),rgba(37,40,42,0.60)), url('/wp-content/uploads/2019/03/Sustainability-Need-Help.jpg');
  margin: 0 auto;
  color: #fff;
}



.sustainability-need-help-text {
  color: #fff;
  text-align: center;
  padding-top:75px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.sustainability-need-help-text h2 {
  padding-bottom: 30px;
}

.sustainability-need-help-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  width: 604px;
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
  width: 80%;
}

.sustainability-leed h2 {
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 22px;
  color: #FFF;
  text-align: center;
  margin: 0 auto;
  width: 80%; 
  padding-top: 100px;
}

.sustainability-leed-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 12px;
  background:transparent;
  display: inline-block;
  padding:10px;
  width: 225px;
  text-align: center;
}

.sustainability-leed-button:hover {
  text-decoration: none;
  color: #A28D5B;
  cursor: pointer;
}

.sustainability-leed-button:visited {
  text-decoration: none;
  color: #A28D5B;
}
@font-face {
    font-family: 'Zahrah';
    src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
        url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Adelle Sans Lt';
    src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
        url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Adelle Sans';
    src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
        url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Adelle Sans Sb';
    src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
        url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
    font-weight: normal;
  }
  
  button:focus {outline:0;}
  
  .close {
    color: #A28D5B;
    font-family: 'Adelle Sans Lt';
    font-size: 2rem;
    font-weight: normal;
    padding-right: 50px;
    padding-top:10px;
  }
  
  .popup-content {
    width: 100%!important;
    height: 95%!important;
    padding:0!important;
    display:inline-block;
  }
  .cart-wrapper-mobile {
    width: 100%;
    max-height: 90%;
    overflow-x: auto;
    overflow-y: auto;
    z-index: 1;
  }
  
  .cart-header-mobile {
    width: 100%;
    background: #FFF;
    display: inline-block;
  }
  
  .cart-header-text-mobile {
    display: inline-block;
    width:100%;
    padding-top: 50px;
    padding-left: 10px;
  }
  
  .cart-header-text-mobile h1 {
    font-size: 20px;
    font-family: 'Adelle Sans';
    color: #25282A;
    text-align: left;
  }
  
  .cart-header-mobile p {
    font-family: 'Adelle Sans Lt';
    font-size: 16px;
    color: #888888;
  }
  
  .cart-header-text-mobile p.sample-count {
    text-align: center;
    font-family: 'Adelle Sans LT';
    font-size: 1rem;
    color: #777;
    margin-top:-5px;
  }
  
  .cart-top-nav {
    padding-left:20px;
  }
  
  .cart-top-nav a {
    font-family: 'Adelle Sans';
    font-size: 12px;
    color: #777;
  }
  .cart-top-nav a:hover, a.active-cart-nav {
    font-family: 'Adelle Sans Sb';
    color: #A28D5B;
    text-decoration: underline;
  
  }
  
  .cart-items {
    min-height: 200px;
    width: 100%;
    padding-left:30px;
  }
  
  .item-name {
    text-align: left;
  }
  .item-name .item-headers {
    font-family: 'Adelle Sans Sb';
    font-size: 20px;
    color: #25282A;
  }
  .item-name .color-name {
    font-family: 'Adelle Sans LT';
    font-size: 1rem;
    color: #777;
    margin-top:-5px;
  }
  table {
    width: 80%;
    margin: 0 auto;
  }
  td {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .cart-item-row {
    border-bottom: .5px solid #CCC;
  }
  
  .cart-buttons, .checkout-buttons {
    text-align: center;
    padding: 50px;
  }
  
  .cart-buttons .continue-shopping {
    color: #A28D5B;
    border:1px solid #A28D5B;
    font-size: 14px;
    border-radius: 50px;
    font-family: 'Adelle Sans Sb';
    background:transparent;
    display: inline-block;
    padding:10px;
    width:150px;
  }
  
  .cart-buttons .checkout-button-front {
    color: #fff;
    border:1px solid #A28D5B;
    border-radius: 50px;
    font-family: 'Adelle Sans Sb';
    font-size: 14px;
    background:#A28D5B;
    display: inline-block;
    padding:10px;
    width: 100px;
  }
  
  .form-label {
    font-family: 'Adelle Sans LT';
    font-size: 1rem;
    color: #777;
  }
  
  .qty-select select {
    border: 0;
    border-bottom:1px solid #A28D5B;
    background: #fff;
  }
  
  .checkout-form-div {
    width:60%;
    margin: 0 auto;
    min-height: 300px;
    max-height: 600px;
    padding-top:20px;
    z-index: auto;
    text-align: left;
  }
  
  .checkout-form-div p {
    font-family: 'Adelle Sans';
    font-size: 11px;
    font-style: italic;
    color: #AAA;
  }
  .row, .fullRow {
    padding: 10px;
  }
  .col {
    float: left;
    width: 50%
  }
  
  .row label {
    font-family: 'Adelle Sans';
    font-size: 11px;
    color: #AAA;
  }
  
  .row input {
    border:0;
    border-bottom: .5px solid #CCC;
    width:100%;
  }
  
  .fullRow label {
    font-family: 'Adelle Sans';
    font-size: 11px;
    color: #AAA;
  }
  
  .fullRow input {
    border:0;
    border-bottom: .5px solid #CCC;
    width:100%;
  }
  
  .checkout-buttons .continue-shopping{
    color: #A28D5B;
    border:1px solid #A28D5B;
    font-size: 14px;
    border-radius: 50px;
    font-family: 'Adelle Sans Sb';
    background:transparent;
    display: inline-block;
    padding:10px;
    width:150px;
  }
  
  .checkout-buttons .checkout-button-front{
    color: #fff;
    border:1px solid #A28D5B;
    border-radius: 50px;
    font-family: 'Adelle Sans Sb';
    font-size: 14px;
    background:#A28D5B;
    display: inline-block;
    padding:10px;
    width: 150px;
  }
  
  
  .frmStateSelect select {
    border: 0;
    border-bottom: .5px solid #CCC;
    background: #fff;
  }
  
  .error{
    margin-left: 5px;
    font-size: 12px;
    font-family: 'Adelle Sans';
    color: red;
  }
  
  .thank-you {
    margin: 0 auto;
    padding-top:50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .thank-you p {
    text-align: center;
    font-family: 'Adelle Sans';
    font-size: 1rem;
    color: #777;
    padding-bottom: 50px;
  }
.search-page-header {
  width: 100%;
  height: 200px;
  background: #25282A;
}

.search-page-header-form {
  max-width: 1004px;
  margin: 0 auto;
  text-align: center;
  padding-top: 75px;
}

.search-page-header-form > input[type="text"] {
  width: 80%;
  border: none;
  border-bottom: .5px solid #A28D5B;
  background: transparent;
  color: #FFF;
  font-family: 'Adelle Sans Lt';
  font-size: 26px;
}

.search-page-results {
  min-height: 53vh;
  background: #F8F8F8;
  display: block;
  margin: 0 auto;
  padding-left:5%;
  padding-right: 5%;
  text-align: center;
}

.search-page-results-header {
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
}

.search-page-results-header h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #777;
}

.search-page-products-results-grid {
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  /*grid-template-columns: repeat(3, 239px);*/
  grid-template-columns: repeat(auto-fill, minmax(239px, 239px));
  color: #444;
}

.search-nav-links {
  color: #FFF;
  padding-top: 25px;
}

.search-nav-links li {
  display: inline-block;
  padding: 15px;
}

.search-nav-links li > a {
  color: #777;
  font-size: 12px;
  font-family: 'Adelle Sans'
}
.search-nav-links li > a:hover {
  text-decoration: none;
}

.search-nav-links li > a.active-link {
  color: #A28D5B;
  border-bottom: 1px solid #A28D5B;
  padding-bottom: 2px;
  
}
@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}


.segment-header-content {
  height: 450px;
  position: relative;
}

.segment-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}


.segemnt-above-fold {
  height: 300px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 25px;
  padding-bottom: 25px;
}

.segemnt-above-fold p {
  text-align: center;
  margin: 0 auto;
  width:75%;
}

.design-rows {
  width:90%;
  margin: 0 auto;
  padding-top: 50px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.design-row {
  text-align: center;
  height: 100%;
}

.design-row .design-header {
  font-family: 'Adelle Sans Sb';
  font-size: 17px;
  color: #fff;
  padding-bottom: 10px;
}

.design-rows .design-row .design-content {
  font-family: 'Adelle Sans Lt';
  font-size: 14;
  color: #fff;
  opacity: .7;
  padding-top: 10px;  
}

.design-content-hero {
  height: 273px;
  margin-top: -15px;
}

.design-button {
  bottom: 0;
}

.design-hr {
  border-top: 2px solid #A28D5B;
  width:50px;
  text-align: center;
  margin: 0 auto;  
}

.design-rows .design-row .design-button .design-row-button {
  color: #FFF;
  border:1px solid #FFF;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}
.design-rows .design-row .design-button .design-row-button-large {
  color: #FFF;
  border:1px solid #FFF;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:200px;
}

.segment-featured-styles-top {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;
}

.segment-featured-styles-top .segment-featured-header{
  margin:0 auto;
  padding-top:100px;
}

p.segment-featured-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.featured-row {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  padding-bottom: 25px;
  background: #FFF;
}

.featured-row .product-block {
  display: grid;
  grid-template-columns: 1fr;
}


.featured-row h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
  padding-top: 20px;
  padding-left: 20px;
}

.featured-row p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
  width: 50%;
  padding-left: 20px;
}


.single-featured-row {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  padding-bottom: 25px;
  background: #FFF;
}

.single-featured-row .product-block {
  display: grid;
  grid-template-columns: 1fr;
}

.single-featured-row .product-block a:hover {
  text-decoration: none;
}

.single-featured-row h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
  padding-top: 20px;
  padding-left: 20px;
}

.single-featured-row p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
  width: 50%;
  padding-left: 20px;
}


.trade-shows-wrapper .trade-shows-header {
  margin:0 auto;
  padding-top: 100px;
  text-align: center;
}

.trade-shows-wrapper .trade-shows-header-text {
  font-family: 'Adelle Sans';
  color: #FFF;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.trade-shows {
  width:100%;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 100px 1fr;
  padding-top: 50px;
  padding-bottom: 50px;
}

.trade-shows h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #fff;
}

.show-date {
  font-family: 'Adelle Sans Lt';
  font-size: 20px;
  color: #fff;
  opacity: .6;
}

.trade-shows p {
  padding-top: 5px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #FFF;
  opacity: .6;
}

.trade-show-box {
  justify-self: end;
  width: 400px;
  text-align: left;
}

.trade-show-box > h2 > a {
  color:#FFF;
  text-decoration: none;
}

.trade-show-box > h2 >a:hover {
  text-decoration: none;
}

.trade-show-box-border {
  align-self: center;
  height: 100%;
}

.trade-show-box-border hr {
  border: none;
  border-left: 1px solid #FFF;
  opacity: .25;
  height: 200px;
  width: 1px;
}

.trade-show-box1 {
  padding-left: 100px;
  text-align: left;
}

.trade-show-box2 {
  justify-self: start;
  width: 400px;
  text-align: left;
  color: #777;
}

.hosp-segment-design-types {
  height: 100%;
  padding-bottom: 40px;
  position: relative;
  background: linear-gradient(rgba(100,52,96,.9),rgba(100,52,96,.9)), url("/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg");
  background-size: cover;
  bottom:0;
  top:0;
}
.sl-segment-design-types {
height: 620px;
position: relative;
background: linear-gradient(rgba(0,60,106,.9),rgba(0,60,106,.9)), url("/wp-content/uploads/2019/02/Artistry-Roomscene-1.jpg");
background-size: cover;
bottom: 0;
top:0;
}
.sl-hospitality-segment-design-types {
height: 620px;
position: relative;
background-color: #6c3460;
background-size: cover;
bottom: 0;
top:0;
}
.custom-designs-wrapper {
  background: linear-gradient(rgba(37,40,42,0.75),rgba(37,40,42,0.75)),url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  min-height: 400px;
  text-align: center;
}


.custom-designs-text {
  color: #fff;
  text-align: center;
  padding-top:100px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.custom-designs-text h2 {
  padding-bottom: 30px;
}

.custom-designs-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  width: 604px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.design-button {
  text-align: baseline;
  justify-self: baseline;
}

.custom-designs-learn-more-button {
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 166px;
}

.custom-designs-find-rep-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background: transparent;
  display: inline-block;
  padding:10px;
  width: 166px;
}


/** Mobile */
.segment-header-content-mobile {
  height: 450px;
  position: relative;
}

.segment-header-content-mobile h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:80%;
  margin-left:10px;
}


.featured-row-mobile {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 25px;
  background: #FFF;
}

.featured-row-mobile .product-block {
  display: grid;
  grid-template-columns: 1fr;
}


.featured-row-mobile h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
  padding-top: 20px;
  padding-left: 20px;
}

.featured-row-mobile p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
  width: 50%;
  padding-left: 20px;
}


.trade-shows-wrapper-mobile .trade-shows-header {
  margin:0 auto;
  padding-top: 100px;
  text-align: center;
}

.trade-shows-wrapper-mobile .trade-shows-header-text {
  font-family: 'Adelle Sans';
  color: #FFF;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.trade-shows-mobile {
  width:100%;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  padding-top: 50px;
  padding-bottom: 50px;
}


@media screen and (max-width: 768px) {
  .hosp-segment-design-types {
    height: 100%;
    padding-bottom: 20px;
  }

  .sl-segment-design-types {
    height: 800px;
    }

    .segment-header-content h2 {
      top:80%;
      margin-left:50px;
    }

  .trade-shows {
    width:100%;
    margin: 0 auto;
    text-align: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .trade-show-box-border {
    display: none;
  }

  .trade-show-box1, .trade-show-box2 {
    padding-left: 10px;
    margin-right: 10px;
    width: 100%;
  }

  .design-rows {
    width:75%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    height: 100%;
  }
  
}

#program-data:nth-child(even) {
  flex-direction: row-reverse;
}
@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

nav.installations {
  background: #fff;
}

nav.installations li {
  display: inline-block;
}

nav.installations li a {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #777;
  padding-left: 15px;
}

nav.installations li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
}

nav.installations li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  -webkit-text-decoration-color: #A28D5B!important;
          text-decoration-color: #A28D5B!important;
}

.installation .card-title {
  font-family: 'Adelle Sans Sb';
  font-size: 18px;
  text-decoration: none;
  padding-left: 5px;
  color:#25282A;
}

.card-info {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color:#777777;
  margin-top:-10px;
  text-decoration: none;
}
.card-body > a:hover {
  text-decoration: none;
}
.install-modal-header {
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color:#25282A;
}

.install-modal-header-inner {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}

.slick-dots {
  bottom: -10px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.installs-header-content {
  height: 450px;
  position: relative;
}

.installs-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}


.installs-above-fold {
  height: 100px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 25px;
  padding-bottom: 25px;
}

.installs-featured-styles-top {
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;
}

.installs-featured-styles-top .installs-featured-header{
  margin:0 auto;
  padding-top:50px;
}

p.installs-featured-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.card-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  background: #fff;
}
.card {
  /* width: 17rem; */
}

.card-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0;
  padding-left: 0;
}


.custom-collection-body {
  min-height: 1000px;
  padding-bottom: 100px;
}


.custom-collection-body {
  width: 100%;
}

.image-element-class {
  position: relative;
  list-style: none;
  padding: 20px;
}

.install-product a {
  color: #A28D5B;
}
.install-product a:hover {
  text-decoration: none;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  margin-top: 10px;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.installation-info {
  font-size: 14px;
  font-family: 'Adelle Sans Lt';
}

.__react_modal_image__caption {
  display: none!important;
}

.installation-actionbar-left {
  text-align: right;
  width: 100%;
  display: inline-block;
}

.installation-actionbar-left a {
  color: #A28D5B;
  font-family: 'Adelle Sans';
  font-size: 12px;
}

.installation-actionbar-left a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1030px) {
  .__react_modal_image__modal_container {
    top: 50px!important;
    z-index: 5000!important;
  }
}
@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.cd0ac52c.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/Zahrah-Semibold.31258d0c.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}


.signaturenow-header-content {
  height: 450px;
  position: relative;
}

.signaturenow-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:80%;
  margin-left:100px;
}


.signaturenow-above-fold {
  height: 200px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 0px;
  padding-bottom: 25px;
  width: 1280px;
  max-width: 1280px;
}

.signaturenow-above-fold p {
  text-align: center;
  margin: 0 auto;
  width:75%;
}

.signaturenow-link-types .link-rows {
  margin: 0 auto;
  padding-top: 25px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.link-row {
  text-align: center;
}

.link-row .link-header {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #A28D5B;
  padding-bottom: 10px;
}

.signaturenow-link-types .link-rows .link-row .link-content {
  font-family: 'Adelle Sans Lt';
  font-size: 14;
  color: #fff;
  opacity: .7;
  padding-top: 10px;  
}

.link-button {
  padding-top: 20px;
}

.link-hr {
  border-top: 2px solid #A28D5B;
  width:50px;
  text-align: center;
  margin: 0 auto;  
}

.signaturenow-link-types .link-rows .link-row .link-button .link-row-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 10px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:225px;
}
.signaturenow-link-types .link-rows .link-row .link-button .link-row-button-large {
  color: #FFF;
  border:1px solid #FFF;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:200px;
}




.signaturenow-link-types {
  /* height: 325px; */
  position: relative;
  background-color: rgba(0, 0, 0, 0.9);
  background-image: url("/wp-content/uploads/2020/07/home-page-header-bg.jpg");
  background-blend-mode: overlay;
  background-size: cover;
  bottom:0;
  top:0;
}


.link-button {
  margin: 0 auto;
  text-align: baseline;
  justify-self: baseline;
}

.signaturenow-featured-styles-wrapper {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;

}
.signaturenow-hr {
  border-top: 2px solid #fff;
  width:50px;
  text-align: center;
  margin: 0 auto;  
}

.signaturenow-featured-styles-header {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.signaturenow-featured-styles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}

.signaturenow-featured-style img {
  height:500px;
  width: 500px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.signaturenow-featured-style p {
  text-align: left!important;
  padding-left: 20px;
  color: rgb(119,119,119);
}

.signaturenow-products-wrapper, .signaturenow-rug-wrapper {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;
}
.signaturenow-products-inner, .signaturenow-rug-inner {
  width: 90%;
  margin: 0 auto;
}
.signaturenow-products-header, .signaturenow-rug-header {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  -webkit-text-decoration-color: #A28D5B;
          text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.signaturenow-products-content p{
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.signaturenow-products-category {
  text-align: left;
  font-size: 1rem;
  padding-left: 25px;
  padding-top: 50px;
  padding-bottom: .5rem;
  font-weight: 500;
}

.signaturenow-products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}

.signaturenow-products a {
  text-decoration: none;
  color: #25282a;
}
.signaturenow-products a:hover {
  text-decoration: none;
  color: #25282a;
}

.signaturenow-product-block {
  text-align: left;
  position: relative;
  padding-left: 25px;
}

.signaturenow-notify-badge{
  position: absolute;
  right:10px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}


.signaturenow-rug-inner {
  margin: 0 auto;
  width: 100%;
}

.signaturenow-rug-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-top: 25px;
}

.signaturenow-rug-images>p {
  text-align: left!important;
  margin-left: 25px!important;
}

.signaturenow-rug-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 25px;
}

.signaturenow-rug-content h4 {
  font-size: 1.2rem;
  padding-bottom: 25px;
}

.signaturenow-rug-content ul {
  text-align: left;
  margin-left: 50px;
}

.signaturenow-button-large {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 200px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1024px) {
  .signaturenow-featured-styles {
    width: 100%;
  }
  .signaturenow-rug-images-col1 img{
    width: 300px;
    height: 400px;
  }

  .signaturenow-rug-images-col2 img{
    width: 300px;
    height: 400px;
  }

  .signaturenow-rug-images-col3-row1 img{
    width: 300px;
    height: 180px;
  }
  .signaturenow-rug-images-col3-row2 img{
    width: 300px;
    height: 180px;
  }
}

@media screen and (max-width: 768px) {
  .signaturenow-link-types {
    height: 700px;
    }

    .signaturenow-header-content h2 {
      top:80%;
      margin-left:50px;
    }
  .signaturenow-featured-styles {
    width: 100%;
  }

  .link-rows {
    width:75%;
    margin: 0 auto;
    padding-top: 100px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }
}


@media screen and (max-width: 480px) {
  .signaturenow-header-content h2 {
    margin-left: 10px;
  }

  .signaturenow-featured-style img {
    width: 100%;
  }

  .signaturenow-product-block {
    padding-left: 50px
  }

  .signaturenow-rug-content ul {
    margin-left: 0;
  }
}
@media screen and (max-width: 735px) {
  .container { max-width: 375px!important;}
  /*.home-hero-section { display: none; }*/
  .home-marketing-section {
    width:100%;
    margin: 0 auto;
    padding: 25px;
    text-align: center;
    background: #FFF;
    width: 100%;
  }

  .home-marketing-section h2 {
    text-align: center!important;
    font-family: 'Adelle Sans Sb';
    font-size: 20px!important;
    padding-bottom:20px;
  }

  .home-marketing-section p {
    font-family: 'Adelle Sans Lt';
    font-size: 18px;
    text-align: center;
    padding: 0!important;
  }
  
  .home-property-wrapper {
    display: grid;
    grid-template-columns: 1fr!important;
    height:448px;
  }

  .carousel-item > img {
    width: 100%;
    height: 488px;
    background-size: cover;
  }
  .carousel-caption-background {
    background: #000;
    z-index: 10;
  }
  
  .carousel-caption {
    display: block!important;
    margin-left: -175px;
    margin-top: -150px;
    z-index: 10;
    position: absolute;
    color: #fff;
    text-align: center;
    height:300px;
  }
  
  .carousel-button {
    margin-top:15px;
    color: #A28D5B;
    border:1px solid #A28D5B;
    border-radius: 50px;
    font-family: 'Adelle Sans Sb';
    background:transparent;
    padding:10px;
    width:150px;
    z-index: 10;
  }
  
  .carousel-caption .card {
    width:250px;
    min-height:250px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }
  
  .carousel-caption .card-title {
    font-family: 'Zahrah';
    font-size: 45px;
    line-height: 45px;
    text-align: center;
  }
  
  .carousel-caption .card-text {
    font-family: 'Adelle Sans Lt';
    font-size: 14px;
    text-align:center;
  }
  

  .home-product-wrapper {
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    width:80%;
    height:694px;
    grid-template: "large large large large" "large large large large""large2 large2 large2 large2" "large2 large2 large2 large2" "item1 item1 item2 item2" "item3 item3 item4 item4";
  }
  .home-product-subheader {
    display: none;
  }

  .home-blog-section {
    display: none;
  }

  .home-blog-section-mobile {
    background: #FFF;
    height: 700px!important;
    padding-top:450px;
    padding-bottom: 650px;
  }

  .home-blog-wrapper {
    display: grid;
    grid-template-rows: 1fr;
  }

  .home-blog-wrapper .home-blog-header{
    margin:0 auto;
    padding-top:75px;
  }
  
  p.home-blog-header-text {
    color: #25282A;
    font-size: 26px;
    text-decoration: underline;
    -webkit-text-decoration-color: #A28D5B;
            text-decoration-color: #A28D5B;
    margin: 0 auto;
  }
  
  .home-blog-wrapper .home-blog-entries {
    width:100%;
    height: 600px;
    margin: 0 auto;
  }

  .home-find-rep-text p {
    font-family: 'Adelle Sans Lt';
    font-size: 18px;
    color: #FFF;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -50px;
  }

  .home-property-text {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 10px;
  }

  .home-property-text .home-property-tag {
    font-family: 'Adelle Sans Lt';
    font-size: 14px;
    color: #fff;
    padding-right: 10px;
    width: 95%;
  }

  #category-wrapper {
    display: none;
  }

  .product-page-desktop {
    display: none;
  }

  .custom-collections-wrapper-desktop {
    display: none;
  }

  .custom-collection-wrapper-desktop {
    display: none;
  }

  .segment-wrapper-desktop {
    display: none;
  }


  .footer-desktop {
    display: none;
  }
  .desktop-nav {
    display: none;
  }
  .media-desktop-nav {
    display: none;
  }
  .home-hero-section {
    display: none;
  }

  #desktop-cart-button {
    display:none;
  }
}


@media screen and (min-width: 768px) and (max-width: 1020px) {
  .carousel-caption {
    display: block!important;
    margin-left: -100px;
    margin-top: -190px;
    z-index: 10;
    position: absolute;
    color: #fff;
    text-align: center;
    height:300px;
  }

   .home-product-wrapper {
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    width:100%;
    height:850px;
    grid-template: "large large large large" "large large large large""large2 large2 large2 large2" "large2 large2 large2 large2" "item1 item1 item2 item2" "item3 item3 item4 item4";
  }
  .home-product-text-mobile {
    position: absolute;
    top: 60%!important;
    right: 0;
    left: 0;
    padding:15px;
    line-height:1;
    height:100%;
  }
  .footer-mobile {
    display:none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .home-blog-section-mobile {
    display: none;
  }

  .footer-mobile {
    display: none;
  }
  #category-wrapper-mobile {
    display: none;
  }
  .product-page-mobile {
    display: none;
  }
  .custom-collections-wrapper-mobile {
    display: none;
  }
  .custom-collection-wrapper-mobile {
    display: none;
  }
  .segment-wrapper-mobile {
    display: none;
  }
  .ds-featuredstyles-section-mobile {
    display: none;
  }
  .mobile-nav {
    display:none;
  }
  .media-mobile-nav {
    display:none;
  }
  .home-hero-section-mobile {
    display:none;
  }
  .about-video-mobile {
    display: none;
  }

  .media-page-mobile-videos {
    display: none
  }
  .sustainability-video-mobile {
    display: none;
  }
  #mobile-cart-button {
    display: none;
  }

}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }

  .carousel-caption {
    display: block!important;
    margin-left: -25px;
    margin-top: -150px;
    z-index: 10;
    position: absolute;
    color: #fff;
    text-align: center;
    height:300px;
  }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
    .home-blog-section-mobile {
      display: none;
    }
    .footer-mobile {
      display: none;
    }
    #category-wrapper-mobile {
      display: none;
    }
    .product-page-mobile {
      display: none;
    }
    .custom-collections-wrapper-mobile {
      display: none;
    }
    .custom-collection-wrapper-mobile {
      display: none;
    }

    .segment-wrapper-mobile {
      display: none;
    }
    .ds-featuredstyles-section-mobile {
      display: none;
    }
    .mobile-nav {
      display: none;
    }
    .media-mobile-nav {
      display:none;
    }
    .home-hero-section-mobile {
      display:none;
    }
    .about-video-mobile {
      display: none;
    }
    .media-page-mobile-videos {
      display: none
    }
    .sustainability-video-mobile {
      display: none;
    }
    .sustainability-video-mobile {
      display: none;
    }
    .biocel-laminate-graphic-mobile, .biocel-cushion-graphic-mobile {
      display: none;
    }
    #mobile-cart-button {
      display: none;
    }

}
html {
    margin-top: 0;
}
body {
    max-width: 1280px;
    margin: 0 auto;
    background: #E8E8E8;
}

body,
div#page-inner {
    /* Will not work on IE if body is ommitted */
    /*display: flex;*/
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
}
button:focus {
  outline:none;
}
a:focus {
  outline: none;
}
a, button {
  cursor: pointer;
}
div#content {
    flex-grow: 1;
}

.posts-title,
.navbar {
    padding-left: 1rem;
}

.navbar {
    border-bottom: 1px solid #eaeaea;
}

.site-content-contain {
    background-color: transparent !important;
    margin-top: 60px;
}

.site-content-contain #content,
.navigation-top {
    background-color: #fff;
    width: 75%;
    margin: 0 auto;
}



@media screen and (min-width: 48em) {
    .site-content {
        
    }
    
}


.aseel-front-page.has-header-image .custom-header-media,
.aseel-front-page.has-header-video .custom-header-media,
.home.blog.has-header-image .custom-header-media,
.home.blog.has-header-video .custom-header-media {
    height: 25vh !important;
}

#primary #main .post {
    clear: left;
    float: left;
    width: 100%;
}

.post-excerpt {
    background: #f4f4f4;
    padding: 10px;
    margin-bottom: 20px;
}

img#loader {
    clear: both;
    margin: 0 auto;
}

#loader {
    text-align: center;
    display: none;
}

#loader.active {
    display: block;
}

.loader-gif {
    text-align: center;
    margin-top: 10em;
}

.post-entry {
    padding-top: 1em;
}

.col-md-4.card-outer {
    float: left;
    padding-top: 1em;
    padding-bottom: 1em;
}

.col-md-4.card-outer .card {
    height: 520px;
}

.img-outer {
    max-height: 250px;
    overflow: hidden;
}

#posts-here {
    position: fixed;
    bottom: 10px;
}

.card-outer {
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.5s ease-out;
}

.card-outer.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.card-body div {
    float: left;
}

img.product-image,
img.featured-image {
    width: 100%;
}

/*
CTC Separate Stylesheet
Updated: 2018-11-30 18:37:07
Theme Name: Divi Child
Theme URI: http://www.elegantthemes.com/gallery/divi/
Template: Divi
Author: Elegant Themes
Author URI: http://www.elegantthemes.com
Description: Smart. Flexible. Beautiful. Divi is the most powerful theme in our collection.
Tags: responsive-layout,one-column,two-columns,three-columns,four-columns,left-sidebar,right-sidebar,custom-background,custom-colors,featured-images,full-width-template,post-formats,rtl-language-support,theme-options,threaded-comments,translation-ready
Version: 3.17.6.1543603027
*/

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.99c580ea.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Light.2d23faa7.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.8ccf3b12.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-Regular.fd567d52.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.2c31cf6d.woff2) format('woff2'),
      url(/wp-content/themes/signature-flooring/static/media/AdelleSans-SemiBold.4ed2bcfe.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

