.custom-collection-wrapper {
  background: #F8F8F8;
}

.custom-collection-container {
  margin: 0 auto;
  width: 75%;
}

.custom-collection-header {
  text-align: center;
  padding-top:50px;
  border-width: 80%;
}
.custom-collection-header h1 {
  font-family: 'Zahrah';
  font-size: 56px;
  color: #25282A;
}
.custom-collection-header h1::first-letter {
  text-transform: uppercase;
}

.custom-collection-body {
  min-height: 1000px;
  padding-bottom: 100px;
}

.custom-collection-wrapper span {
  font-family: 'Adelle Sans Lt';
}

.custom-collection-wrapper a {
  color: #444;
}
.custom-collection-wrapper a:hover {
  color: #444;
  text-decoration: none;
}


.collection-grid {
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(239px, 239px)); /* see notes below */
  grid-gap: 1em;
}

.custom-collection-body {
  width: 100%;
}

.image-element-class {
  position: relative;
  list-style: none;
  padding: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

/*.image-element-class:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, .3);
}
*/

.button {
  position: absolute;
  left:64px;
  top: 45%;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}

.button button {
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  color: #fff;
  border-radius: 50px;
  padding: 10px;
  width: 150px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  z-index: 1;
  cursor: pointer;
}

.image-element-class:hover .button {
  opacity: 1;
}


.custom-collection-actionbar {
  border-bottom: 1px solid #CCC;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
}
.custom-collection-actionbar-left {
  text-align: left;
  width:49%;
  display:inline-block;
}

.custom-collection-actionbar-left a {
  color: #A28D5B;
  font-family: 'Adelle Sans';
  font-size: 12px;
}
.custom-collection-actionbar-left a:hover {
  text-decoration: none;
  cursor: pointer;
}

.custom-collection-actionbar-right {
  text-align:right;
  width:50%;
  display:inline-block;
}
.custom-collection-actionbar-right a {
  text-decoration: none;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
}

.custom-collection-brochure-button {
  margin-top:15px;
  color: #A28D5B !important;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  padding:10px;
  width:150px;
  z-index: 10;
}

.custom-collection-container-mobile {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.custom-collection-header-mobile {
  text-align: center;
  padding-top:25px;
  border-width: 80%;
}

.image-element-mobile-class {
  right: 0!important;
  left: 0!important;
  text-align: center;
  list-style: none;
  padding: 20px;
}
