
@font-face {
  font-family: 'Zahrah';
  src: url('../../../Fonts/Zahrah-Medium.woff2') format('woff2'),
      url('../../../Fonts/Zahrah-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity .150s ease-in-out;
}

.carousel-item > img {
  width: 100%;
  max-height: 600px;
  background-size: cover;
}

.carousel-caption-background {
  background: #000;
  z-index: 10;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  left: 63%;
  bottom: 20%;
  z-index: 10;
  padding-top: 250px;
  color: #fff;
  text-align: center;
  top: 0;
  height:100px;
}

.carousel-button {
  margin-top:15px;
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  padding:10px;
  width:150px;
  z-index: 10;
}

.carousel-caption .card {
  width:250px;
  min-height:250px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.carousel-caption .card-title {
  font-family: 'Zahrah';
  font-size: 40px;
  line-height: 45px;
  text-align: center;
}

.carousel-caption .card-text {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  text-align:center;
}
