/*http://www.signatureflooring.local/wp-content/uploads/2019/02/rs-bona-fide.png*/
@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .sales-rep-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SIG_ElementsDissolved_7411_Nickel_Herringbone_320_486.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:85%!important;
    width: 100%!important;
    margin-left:10px!important;
  }
  .header-content nav {
    margin-left: -40px!important;
    position: absolute!important;
    top: 95%!important;
  }

  .sales-rep-filter-form {
    background: #F8F8F8;
    width: 100%;
    height: 125px!important;
  }
  .sales-rep-filter-form ul {
    margin-left:-40px!important;
  }
  .sales-rep-filter-form li {
    display: inline-block;
    padding-top: 25px;
    padding-left:25px;
  }
  
  .sales-rep-filter-form li > label {
    font-family: 'Adelle Sans';
    font-size: 11px;
    color: #AAA;
  }
  
  .sales-rep-filter-form li > select {
    border: 0;
    border-bottom: .5px solid #ccc;
  }

  .sales-rep-cell {
    text-align: left!important;
  }

  .sales-reps-data {
    grid-template-columns: 1fr!important;
    padding-left: 20%!important;
  }

  .sales-rep-image {
    float: none!important;
  }
  .sales-rep-details {
    padding-top: 10px;
    float: none!important;
    text-align: left;
    padding-left:10px;
  }

  .sales-rep-hear-from-you {
    width: 100%;
    height: 100%!important;
    background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2019/03/sales-rep-hear-mobile-bg.png')!important;
    margin: 0 auto;
  }
  
  .sales-rep-hear-from-you h2 {
    font-family: 'Adelle Sans Lt';
    font-size: 26px!important;
    padding-left: 50px;
    padding-right: 50px;
    color: #FFF;
    text-align: center;
    padding-top: 50px!important;
    padding-bottom: 50px;
  }
  
  .sales-rep-contact-ways-wrapper {
    color: #fff;
    display: grid;
    grid-template-columns: 1fr!important;
    grid-gap: 0!important;
    justify-items:normal!important;
    padding-left: 25px!important;
  }

  .contact-ways1, .contact-ways2 {
    padding-bottom: 30px!important
  }
}

@media screen and (max-width: 780px) {
  .sales-reps-data {
    grid-template-columns: 1fr!important;
    padding-left: 20%!important;
  }
}

.sales-rep-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/02/sales-rep-header-active.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header-content {
  height: 450px;
  position: relative;
}
.header-content h2 {
  font-family: 'Adelle Sans';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.header-content nav {
  margin-left: 50px;
  position: absolute;
  top: 90%;
}

.header-content nav li {
  display: inline-block;
}

.header-content nav li a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left: 15px;
}

.header-content nav li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B;
}

.header-content nav li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B!important;
}

.sales-rep-filter-form {
  background: #F8F8F8;
  width: 100%;
  height: 125px;
}
.sales-rep-filter-form ul {
  margin-left:50px;
}
.sales-rep-filter-form li {
  display: inline-block;
  padding-top: 25px;
  padding-left:25px;
}

.sales-rep-filter-form li > label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.sales-rep-filter-form li > select {
  border: 0;
  border-bottom: .5px solid #ccc;
}

.sales-reps-wrapper {
  background: #fff;
  height: 100%;
  margin: 0 auto;
}

.sales-reps-data {
  width:100%;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, minmax(50%, 50%));
  justify-items: left;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  height: 100%;
}

.sales-rep-image {
  float: left;
}
.sales-rep-details {
  padding-top: 10px;
  float: left;
  text-align: left;
  padding-left:10px;
}

.sales-rep-details h3 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  font-color: #25282A;
}

.sales-rep-details h5 {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}

.sales-rep-details p {
  padding-top: 3px;
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #25282A;
}

.sales-rep-details p > i {
  color: #A28D5B;
}

.sales-rep-hear-from-you {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2019/02/sales-rep-hear-from-you-bg.png');
  margin: 0 auto;
}

.sales-rep-hear-from-you h2 {
  font-family: 'Adelle Sans Lt';
  font-size: 30px;
  color: #FFF;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.sales-rep-contact-ways-wrapper {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  justify-items: center;
}

.rep-segments {
  font-family: 'Adelle Sans';
  font-size: 12px;
  color: #FFF;
  opacity: .8;
}

.rep-segments span.segment-hospitality {
  background: #643460;
  padding: 2px;
}

.rep-segments span {
  padding-left:5px;
}

.rep-segments span.segment-senior-living {
  background: #00426A;
  padding:2px;
}

.contact-ways-header {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #AAA;
}

.contact-ways {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #D9D9D6;
}

.contact-email {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
}
