.custom-collections-wrapper-desktop {
  background: #F8F8F8;
}

.custom-collections-container {
  margin: 0 auto;
  width: 75%;
}

.custom-collections-header {
  text-align: center;
  padding-top:50px;
  border-bottom: 1px solid #CCC;
  border-width: 80%;
}
.custom-collections-header h1 {
  font-family: 'Zahrah';
  font-size: 56px;
  color: #25282A;
}

.custom-collections-body {
  min-height: 1000px;
  padding-bottom: 100px;
  padding-top: 25px;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /*grid-template-rows: auto auto auto auto;*/
}

.custom-collections-wrapper span {
  font-family: 'Adelle Sans Lt';
}

.custom-collections-wrapper a {
  color: #444;
}
.custom-collections-wrapper a:hover {
  color: #444;
  text-decoration: none;
}


.custom-collections-header-mobile {
  font-family: 'Zahrah';
  text-align: center;
  padding-top:75px;
  border-bottom: 1px solid #CCC;
  border-width: 80%;
}

.collection-wrapper {
  position: relative;
}

.collections-notify-badge{
  position: absolute;
  right: 198px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}

.collections-notify-badge-mobile{
  position: absolute;
  left: 32px;
  top: 0px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 75px;
}