.search-page-header {
  width: 100%;
  height: 200px;
  background: #25282A;
}

.search-page-header-form {
  max-width: 1004px;
  margin: 0 auto;
  text-align: center;
  padding-top: 75px;
}

.search-page-header-form > input[type="text"] {
  width: 80%;
  border: none;
  border-bottom: .5px solid #A28D5B;
  background: transparent;
  color: #FFF;
  font-family: 'Adelle Sans Lt';
  font-size: 26px;
}

.search-page-results {
  min-height: 53vh;
  background: #F8F8F8;
  display: block;
  margin: 0 auto;
  padding-left:5%;
  padding-right: 5%;
  text-align: center;
}

.search-page-results-header {
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
}

.search-page-results-header h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #777;
}

.search-page-products-results-grid {
  padding: 20px;
  display: grid;
  grid-gap: 20px;
  /*grid-template-columns: repeat(3, 239px);*/
  grid-template-columns: repeat(auto-fill, minmax(239px, 239px));
  color: #444;
}

.search-nav-links {
  color: #FFF;
  padding-top: 25px;
}

.search-nav-links li {
  display: inline-block;
  padding: 15px;
}

.search-nav-links li > a {
  color: #777;
  font-size: 12px;
  font-family: 'Adelle Sans'
}
.search-nav-links li > a:hover {
  text-decoration: none;
}

.search-nav-links li > a.active-link {
  color: #A28D5B;
  border-bottom: 1px solid #A28D5B;
  padding-bottom: 2px;
  
}