.custom-collection-body {
  min-height: 1000px;
  padding-bottom: 100px;
}


.custom-collection-body {
  width: 100%;
}

.image-element-class {
  position: relative;
  list-style: none;
  padding: 20px;
}

.install-product a {
  color: #A28D5B;
}
.install-product a:hover {
  text-decoration: none;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  margin-top: 10px;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.installation-info {
  font-size: 14px;
  font-family: 'Adelle Sans Lt';
}

.__react_modal_image__caption {
  display: none!important;
}

.installation-actionbar-left {
  text-align: right;
  width: 100%;
  display: inline-block;
}

.installation-actionbar-left a {
  color: #A28D5B;
  font-family: 'Adelle Sans';
  font-size: 12px;
}

.installation-actionbar-left a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1030px) {
  .__react_modal_image__modal_container {
    top: 50px!important;
    z-index: 5000!important;
  }
}