@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


.segment-header-content {
  height: 450px;
  position: relative;
}

.segment-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}


.segemnt-above-fold {
  height: 300px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
  padding-top: 25px;
  padding-bottom: 25px;
}

.segemnt-above-fold p {
  text-align: center;
  margin: 0 auto;
  width:75%;
}

.design-rows {
  width:90%;
  margin: 0 auto;
  padding-top: 50px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.design-row {
  text-align: center;
  height: 100%;
}

.design-row .design-header {
  font-family: 'Adelle Sans Sb';
  font-size: 17px;
  color: #fff;
  padding-bottom: 10px;
}

.design-rows .design-row .design-content {
  font-family: 'Adelle Sans Lt';
  font-size: 14;
  color: #fff;
  opacity: .7;
  padding-top: 10px;  
}

.design-content-hero {
  height: 273px;
  margin-top: -15px;
}

.design-button {
  bottom: 0;
}

.design-hr {
  border-top: 2px solid #A28D5B;
  width:50px;
  text-align: center;
  margin: 0 auto;  
}

.design-rows .design-row .design-button .design-row-button {
  color: #FFF;
  border:1px solid #FFF;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}
.design-rows .design-row .design-button .design-row-button-large {
  color: #FFF;
  border:1px solid #FFF;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:200px;
}

.segment-featured-styles-top {
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #FFF;
}

.segment-featured-styles-top .segment-featured-header{
  margin:0 auto;
  padding-top:100px;
}

p.segment-featured-header-text {
  color: #25282A;
  font-size: 26px;
  text-decoration: underline;
  text-decoration-color: #A28D5B;
  margin: 0 auto;
}

.featured-row {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  padding-bottom: 25px;
  background: #FFF;
}

.featured-row .product-block {
  display: grid;
  grid-template-columns: 1fr;
}


.featured-row h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
  padding-top: 20px;
  padding-left: 20px;
}

.featured-row p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
  width: 50%;
  padding-left: 20px;
}


.single-featured-row {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  padding-bottom: 25px;
  background: #FFF;
}

.single-featured-row .product-block {
  display: grid;
  grid-template-columns: 1fr;
}

.single-featured-row .product-block a:hover {
  text-decoration: none;
}

.single-featured-row h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
  padding-top: 20px;
  padding-left: 20px;
}

.single-featured-row p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
  width: 50%;
  padding-left: 20px;
}


.trade-shows-wrapper .trade-shows-header {
  margin:0 auto;
  padding-top: 100px;
  text-align: center;
}

.trade-shows-wrapper .trade-shows-header-text {
  font-family: 'Adelle Sans';
  color: #FFF;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.trade-shows {
  width:100%;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 100px 1fr;
  padding-top: 50px;
  padding-bottom: 50px;
}

.trade-shows h2 {
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #fff;
}

.show-date {
  font-family: 'Adelle Sans Lt';
  font-size: 20px;
  color: #fff;
  opacity: .6;
}

.trade-shows p {
  padding-top: 5px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #FFF;
  opacity: .6;
}

.trade-show-box {
  justify-self: end;
  width: 400px;
  text-align: left;
}

.trade-show-box > h2 > a {
  color:#FFF;
  text-decoration: none;
}

.trade-show-box > h2 >a:hover {
  text-decoration: none;
}

.trade-show-box-border {
  align-self: center;
  height: 100%;
}

.trade-show-box-border hr {
  border: none;
  border-left: 1px solid #FFF;
  opacity: .25;
  height: 200px;
  width: 1px;
}

.trade-show-box1 {
  padding-left: 100px;
  text-align: left;
}

.trade-show-box2 {
  justify-self: start;
  width: 400px;
  text-align: left;
  color: #777;
}

.hosp-segment-design-types {
  height: 100%;
  padding-bottom: 40px;
  position: relative;
  background: linear-gradient(rgba(100,52,96,.9),rgba(100,52,96,.9)), url("/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg");
  background-size: cover;
  bottom:0;
  top:0;
}
.sl-segment-design-types {
height: 620px;
position: relative;
background: linear-gradient(rgba(0,60,106,.9),rgba(0,60,106,.9)), url("/wp-content/uploads/2019/02/Artistry-Roomscene-1.jpg");
background-size: cover;
bottom: 0;
top:0;
}
.sl-hospitality-segment-design-types {
height: 620px;
position: relative;
background-color: #6c3460;
background-size: cover;
bottom: 0;
top:0;
}
.custom-designs-wrapper {
  background: linear-gradient(rgba(37,40,42,0.75),rgba(37,40,42,0.75)),url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  min-height: 400px;
  text-align: center;
}


.custom-designs-text {
  color: #fff;
  text-align: center;
  padding-top:100px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.custom-designs-text h2 {
  padding-bottom: 30px;
}

.custom-designs-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  width: 604px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.design-button {
  text-align: baseline;
  justify-self: baseline;
}

.custom-designs-learn-more-button {
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 166px;
}

.custom-designs-find-rep-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background: transparent;
  display: inline-block;
  padding:10px;
  width: 166px;
}


/** Mobile */
.segment-header-content-mobile {
  height: 450px;
  position: relative;
}

.segment-header-content-mobile h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:80%;
  margin-left:10px;
}


.featured-row-mobile {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 25px;
  background: #FFF;
}

.featured-row-mobile .product-block {
  display: grid;
  grid-template-columns: 1fr;
}


.featured-row-mobile h2 {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
  padding-top: 20px;
  padding-left: 20px;
}

.featured-row-mobile p {
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
  width: 50%;
  padding-left: 20px;
}


.trade-shows-wrapper-mobile .trade-shows-header {
  margin:0 auto;
  padding-top: 100px;
  text-align: center;
}

.trade-shows-wrapper-mobile .trade-shows-header-text {
  font-family: 'Adelle Sans';
  color: #FFF;
  font-size: 26px;
  border-bottom: 1px solid #A28D5B;
  margin: 0 auto;
  height: 40px;
}

.trade-shows-mobile {
  width:100%;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  padding-top: 50px;
  padding-bottom: 50px;
}


@media screen and (max-width: 768px) {
  .hosp-segment-design-types {
    height: 100%;
    padding-bottom: 20px;
  }

  .sl-segment-design-types {
    height: 800px;
    }

    .segment-header-content h2 {
      top:80%;
      margin-left:50px;
    }

  .trade-shows {
    width:100%;
    margin: 0 auto;
    text-align: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .trade-show-box-border {
    display: none;
  }

  .trade-show-box1, .trade-show-box2 {
    padding-left: 10px;
    margin-right: 10px;
    width: 100%;
  }

  .design-rows {
    width:75%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    height: 100%;
  }
  
}
