footer {
  height: 100%;
}
.footermobile-content {
  text-align: center;
  width: 100%;
}

.footermobile-content .footermobile-logo {
  padding-top: 50px;
}

.footermobile-content .footermobile-find-rep {
  padding-top: 50px;
  padding-bottom: 25px;
}

.footermobile-content .footermobile-find-rep > button.find-rep-button {
  color: #A28D5B;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.footermobile-content .footermobile-link-cell {
  padding-top:25px;
  display: inline-block;
  margin-left:5px;
  margin-right: 5px;
  width: 325px;
}

.footermobile-content .footermobile-link-cell .footermobile-item {
  border-bottom: .5px solid #555;
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-right a {
  font-size: 26px;
  color: #A28D5B
}

.footermobile-content .footermobile-link-cell .footermobile-item .footermobile-item-right a:hover {
  text-decoration: none;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-left {
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color: #FFF;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-left {
  text-align: left;
  width:49%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-right {
  text-align: right;
  width:50%;
  display:inline-block;
  padding:10px 0 10px 0;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-right a {
  font-size: 26px;
  color: #A28D5B
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-right a:hover {
  text-decoration: none;
}

.footermobile-content .footermobile-link-cell .footermobile-last-item .footermobile-item-left {
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color: #FFF;
}

.footermobile-content .footermobile-social-icons a {
  padding: 10px;
  color: #999;
}

.footermobile-content .footermobile-legal {
  padding-top: 10px;
  font-family: 'Adelle Sans LT';
  font-size: 11px;
  color: #555;
}

.footermobile-content .footermobile-legal a {
  font-family: 'Adelle Sans LT';
  font-size: 11px;
  color: #555;
  text-decoration: none;
}

.footermobile-content .footermobile-legal a:hover {
  text-decoration: none;
}

#footerResource-options,
#footerCompany-options,
#footerMedia-options {
  display: none;
}
