@font-face {
  font-family: "Adelle Sans Lt";
  src: url("../../Fonts/AdelleSans-Light.woff2") format("woff2"),
    url("../../Fonts/AdelleSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans";
  src: url("../../Fonts/AdelleSans-Regular.woff2") format("woff2"),
    url("../../Fonts/AdelleSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Adelle Sans Sb";
  src: url("../../Fonts/AdelleSans-SemiBold.woff2") format("woff2"),
    url("../../Fonts/AdelleSans-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  
}

@media screen and (max-width: 735px) {
  .senior-living-header {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG259-I70112-PD072_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .senior-living-regional-tapestry-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/ZD477-Plains_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-aquarella-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG259-Z70204_PD076_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-common-threads-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG258-B7032-7201_RS_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-terra-cruda-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG-TerraCruda-TileF7414-Form7424_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-reflective-echoes-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2024/05/SIG-ReflectiveEchoes_Hero.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-polished-collection-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/09/SIG_Polished_ZD455_1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-urban-escapes-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/10/Urban-Escapes-1280x500-HeroFinal_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-local-connections-hero {
    width: 100% !important;
    height: 500px !important;
    background:
      url("/wp-content/uploads/2023/10/Local-Connections-1280x500_resized.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }

  .senior-living-header3 {
    width: 100% !important;
    height: 486px !important;
    background:
      url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
  }
  
  .senior-living-marketing-block .smb-text {
    padding-top: 45px;
    padding-left: 10px;
    padding-right: 30px;
  }
  
  .senior-living-above-fold {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .how-does-it-work-grid-wrapper {
    grid-template-columns: 1fr !important;
  }

  .hdiw-col p {
    text-align: center;
  }

  .ds-featuredstyles-section {
    display: none;
  }
  
}

@media screen and (min-width: 736px) and (max-width: 1280px) {
  .ds-featuredstyles-section-mobile {
    display: none;
  }
}

.senior-living-header {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG259-I70112-PD072_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-header-content {
  height: 500px;
  position: relative;
}

.senior-living-header-content h2 {
  font-family: "Zahrah";
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: 70%;
  margin-left: 30px;
}
.senior-living-header-content h5 {
  font-family: "Zahrah";
  font-size: 16px !important;
  color: #fff;
  position: absolute;
  top: 83% !important;
  width: 100% !important;
  margin-left: 30px !important;
}

.senior-living-regional-tapestry-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/ZD477-Plains_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-regional-tapestry-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-aquarella-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG259-Z70204_PD076_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-aquarella-collection-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-common-threads-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG258-B7032-7201_RS_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-common-threads-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-terra-cruda-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG-TerraCruda-TileF7414-Form7424_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-terra-cruda-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-reflective-echoes-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2024/05/SIG-ReflectiveEchoes_Hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-reflective-echoes-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-polished-collection-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/09/SIG_Polished_ZD455_1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-polished-collection-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-urban-escapes-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/10/Urban-Escapes-1280x500-HeroFinal_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-urban-escapes-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-local-connections-hero {
  width: 100%;
  height: 500px;
  background:
    url("/wp-content/uploads/2023/10/Local-Connections-1280x500_resized.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-local-connections-hero-content {
  height: 500px;
  position: relative;
}

.senior-living-header3 {
  width: 100%;
  height: 450px;
  background:
    url("/wp-content/uploads/2021/11/SIG230-Urban-Escapes_714_1077x682_cropped.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.senior-living-header3-content {
  height: 450px;
  position: relative;
}

.ds-slider-card h5.card-title {
  padding-left: 0px !important;
}

.senior-living-above-fold {
  height: 300px;
  width: 1280px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #fff;
}

.senior-living-above-fold h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
}

.senior-living-above-fold p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 75%;
}

.senior-living-video- {
  background: #f8f8f8;
  padding-top: 30px;
  /*padding-bottom: 100px;*/

  height: 100%;
  margin-bottom: -6px;
  text-align: center;
}

.senior-living-video- h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.senior-living-how-does-it-work {
  background: #f8f8f8;
  padding-top: 75px;
  padding-bottom: 100px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.senior-living-how-does-it-work h3 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
  padding-bottom: 25px;
}

.how-does-it-work-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 80%;
  margin: 0 auto;
}

.hdiw-col img {
  height: 260px;
  width: 260px;
}

.hdiw-col p {
  font-family: "Adelle Sans Lt";
  font-size: 14px;
  color: #777;
  text-align: center;
}

.hdiw-col h4 {
  padding-top: 20px;
  font-family: "Adelle Sans Sb";
  font-size: 20px;
  color: #25282a;
}

.design-button .design-row-button-large {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 200px;
}
.ds-featuredstyles-section {
  background: #ddd;
  height: 500px;
}


.senior-living-marketing-block {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.senior-living-marketing-block .smb-graphic {
  padding-left: 20px;
  flex: 25%;
}

.senior-living-marketing-block .smb-text {
  padding-top: 45px;
  flex: 60%;
  width: 80%;
  padding-right: 10px;
}

.senior-living-marketing-block .smb-text p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;  
}

.senior-living-marketing-block .smb-text p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}

.senior-living-marketing-block .smb-youtube {
  padding-left: 20px;
  flex: 30%;
  width: 30%;
  margin-right: -60px;
}

.senior-living-marketing-block .smb-graphic2 {
  padding-left: 20px;
  flex: 25%;
  width: 25%;
}

.senior-living-marketing-block .smb-text2 {
  padding-top: 0px;
  flex: 35%;
  width: 35%;
  padding-right: 10px;
  padding-left: 10px;
}

.senior-living-marketing-block .smb-text2 p {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  text-align: left;
  
}

.senior-living-marketing-block .smb-text2 p.bold-text {
  font-family: "Adelle Sans";
  color: #25282a;
  font-size: 26px;
}