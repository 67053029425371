@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@media screen and (max-width: 735px) {
  .resources-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SIG_NatInstincts_AspenGlow_CK2_320_486.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  .resources-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:90%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .resources-main-content {
    padding-top: 25px!important;
  }

  .resources-wrapper {
    padding-top: 50px!important;
    grid-template-columns: 1fr!important;
    justify-items: left!important;
  }
  .resources-col-1 {
    padding-left: 0!important;
  }

  .spec-col-1 {
    grid-template-columns: 1fr!important;
  }

}
.resources-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/02/resources-header@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.resources-header-content {
  height: 450px;
  position: relative;
}
.resources-header-content h2 {
  font-family: 'Adelle Sans';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.resources-header-content nav {
  margin-left: 50px;
  position: absolute;
  top: 90%;
}

.resources-header-content nav li {
  display: inline-block;
}

.resources-header-content nav li a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left: 15px;
}

.resources-header-content nav li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B;
}

.resources-header-content nav li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B!important;
}


/** Resources Content */
.resources-main-content {
  background: #F8F8F8;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0 auto;
}

.resources-main-content h2 {
  font-family: 'Adelle Sans';
  font-size: 26px;
  color: #25282A;
  text-align: center;
}

.resources-main-content p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 70%;
}

.resources-main-content .resources-wrapper {
  padding-top: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  justify-items: center;
}

.resources-wrapper {
  width: 80%;
  margin: 0 auto;
}

.resources-col-header {
  font-family: 'Adelle Sans';
  font-size: 12px;
  color: #AAA;
}
.resources-col-links {
  min-width: 150px;
}
.resources-col-links a {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
  text-decoration: none;
  padding-bottom: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/** Need Help */
.resources-need-help {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.40),rgba(37,40,42,0.40)), url('/wp-content/uploads/2019/02/resources-need-help@2x.jpg');
  margin: 0 auto;
  color: #fff;
}

.resources-need-help-text {
  color: #fff;
  text-align: center;
  padding-top:100px;
  padding-bottom: 50px;
  font-family: 'Adelle Sans';
  font-size: 26px;
}

.resources-need-help-text h2 {
  padding-bottom: 30px;
}

.resources-need-help-text p {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #CCC;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.resources-find-rep-button {
  color: #25282A;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 225px;
}

.resources-find-rep-button:hover {
  text-decoration: none;
  color: #25282A;
}

.resources-col-1 .spec-col-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

.resources-col-1 {
  padding-left: 200px;
}

.resources-col-2 .spec-col-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

span.product-title {
  font-family: 'Adelle Sans Lt';
  font-size: 16px;
  color: #777;
  text-align: center;
  margin: 0 auto;
  width: 70%;
}
