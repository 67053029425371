@media screen and (max-width: 735px) {
  .container { max-width: 375px!important;}
  /*.home-hero-section { display: none; }*/
  .home-marketing-section {
    width:100%;
    margin: 0 auto;
    padding: 25px;
    text-align: center;
    background: #FFF;
    width: 100%;
  }

  .home-marketing-section h2 {
    text-align: center!important;
    font-family: 'Adelle Sans Sb';
    font-size: 20px!important;
    padding-bottom:20px;
  }

  .home-marketing-section p {
    font-family: 'Adelle Sans Lt';
    font-size: 18px;
    text-align: center;
    padding: 0!important;
  }
  
  .home-property-wrapper {
    display: grid;
    grid-template-columns: 1fr!important;
    height:448px;
  }

  .carousel-item > img {
    width: 100%;
    height: 488px;
    background-size: cover;
  }
  .carousel-caption-background {
    background: #000;
    z-index: 10;
  }
  
  .carousel-caption {
    display: block!important;
    margin-left: -175px;
    margin-top: -150px;
    z-index: 10;
    position: absolute;
    color: #fff;
    text-align: center;
    height:300px;
  }
  
  .carousel-button {
    margin-top:15px;
    color: #A28D5B;
    border:1px solid #A28D5B;
    border-radius: 50px;
    font-family: 'Adelle Sans Sb';
    background:transparent;
    padding:10px;
    width:150px;
    z-index: 10;
  }
  
  .carousel-caption .card {
    width:250px;
    min-height:250px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }
  
  .carousel-caption .card-title {
    font-family: 'Zahrah';
    font-size: 45px;
    line-height: 45px;
    text-align: center;
  }
  
  .carousel-caption .card-text {
    font-family: 'Adelle Sans Lt';
    font-size: 14px;
    text-align:center;
  }
  

  .home-product-wrapper {
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    width:80%;
    height:694px;
    grid-template: "large large large large" "large large large large""large2 large2 large2 large2" "large2 large2 large2 large2" "item1 item1 item2 item2" "item3 item3 item4 item4";
  }
  .home-product-subheader {
    display: none;
  }

  .home-blog-section {
    display: none;
  }

  .home-blog-section-mobile {
    background: #FFF;
    height: 700px!important;
    padding-top:450px;
    padding-bottom: 650px;
  }

  .home-blog-wrapper {
    display: grid;
    grid-template-rows: 1fr;
  }

  .home-blog-wrapper .home-blog-header{
    margin:0 auto;
    padding-top:75px;
  }
  
  p.home-blog-header-text {
    color: #25282A;
    font-size: 26px;
    text-decoration: underline;
    text-decoration-color: #A28D5B;
    margin: 0 auto;
  }
  
  .home-blog-wrapper .home-blog-entries {
    width:100%;
    height: 600px;
    margin: 0 auto;
  }

  .home-find-rep-text p {
    font-family: 'Adelle Sans Lt';
    font-size: 18px;
    color: #FFF;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -50px;
  }

  .home-property-text {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 10px;
  }

  .home-property-text .home-property-tag {
    font-family: 'Adelle Sans Lt';
    font-size: 14px;
    color: #fff;
    padding-right: 10px;
    width: 95%;
  }

  #category-wrapper {
    display: none;
  }

  .product-page-desktop {
    display: none;
  }

  .custom-collections-wrapper-desktop {
    display: none;
  }

  .custom-collection-wrapper-desktop {
    display: none;
  }

  .segment-wrapper-desktop {
    display: none;
  }


  .footer-desktop {
    display: none;
  }
  .desktop-nav {
    display: none;
  }
  .media-desktop-nav {
    display: none;
  }
  .home-hero-section {
    display: none;
  }

  #desktop-cart-button {
    display:none;
  }
}


@media screen and (min-width: 768px) and (max-width: 1020px) {
  .carousel-caption {
    display: block!important;
    margin-left: -100px;
    margin-top: -190px;
    z-index: 10;
    position: absolute;
    color: #fff;
    text-align: center;
    height:300px;
  }

   .home-product-wrapper {
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    width:100%;
    height:850px;
    grid-template: "large large large large" "large large large large""large2 large2 large2 large2" "large2 large2 large2 large2" "item1 item1 item2 item2" "item3 item3 item4 item4";
  }
  .home-product-text-mobile {
    position: absolute;
    top: 60%!important;
    right: 0;
    left: 0;
    padding:15px;
    line-height:1;
    height:100%;
  }
  .footer-mobile {
    display:none;
  }
}
