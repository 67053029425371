@font-face {
  font-family: "Zahrah";
  src: url("../../Fonts/Zahrah-Semibold.woff2") format("woff2"),
    url("../../Fonts/Zahrah-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans Lt";
  src: url("../../Fonts/AdelleSans-Light.woff2") format("woff2"),
    url("../../Fonts/AdelleSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Adelle Sans";
  src: url("../../Fonts/AdelleSans-Regular.woff2") format("woff2"),
    url("../../Fonts/AdelleSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.product-notify-badge {
  position: absolute;
  right: 0px;
  top: 15px;
  color:white;
  padding:5px;
  background: #A28D5B;
  height: 125px;
}

.sg-product-notify-badge {
  position: absolute;
  right: 0px;
  top: 15px;
  color:white;
  padding:5px;
  background: transparent;
  height: 125px;
}

.product-page {
  margin: 0;
  padding-bottom: 100px;
  background: #f8f8f8;
}

.product-page-wrapper {
  margin: 0 auto;
}

.product-top {
  padding-top: 50px;
  padding-bottom: 100px;
  /*width: 60%;*/
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.product-page-top-inner {
  display: grid;
  grid-template-columns: 30% 1fr;
  width: 80%;
}

.product-breadcrumb {
  text-align: left;
}

.product-breadcrumb > a {
  color: #a28d5b;
  font-family: "Adelle Sans";
  font-size: 12px;
}
.product-breadcrumb > a:hover {
  text-decoration: none;
}

.product-swatches {
  padding-top: 15px;
  display: grid;
  /*grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));*/
  grid-template-columns: repeat(auto-fill, minmax(50%, 50%));
  width: 90%;
  min-height: 500px;
}

.product-swatches .left {
  display: inline;
}
.product-title h1 {
  font-family: "Zahrah";
  font-size: 40px;
  text-align: left;
}

.product-swatch-changer {
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #777;
  text-align: left;
}

.product-swatch-changer a {
  color: #444;
}

.product-swatch-changer a:hover {
  color: #a28d5b;
  text-decoration: underline;
}

.product-swatch-changer a.active-link {
  text-decoration: underline;
  color: #a28d5b;
}
.product-swatch-changer a.nonactive-link {
  color: #444;
}

.product-swatches-small {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 10px;
}
.swatch-box {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #25282a;
}

.swatch-box img.active-swatch {
  border: 5px solid black;
}

.swatch-box span {
  font-family: "Adelle Sans Lt";
  color: #777;
}

.product-swatches .right {
  padding-left: 20px;
  margin-left: 20px;
  max-height: 494px;
  min-width: 494px;
  position: relative;
}

.product-swatches-small a {
  font-family: "Adelle Sans Sb";
  font-size: 12px;
  color: #25282a;
}
.product-swatches-small a:hover {
  text-decoration: none;
}
.sample-button {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 150px;
}

a.sample-button:hover {
  color: #a28d5b;
}

.rug-button {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 150px;
}

.customize-button {
  position: relative;
  top: 85%;
  left: 60%;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  color: #fff;
  border-radius: 50px;
  padding: 10px;
  width: 150px;
  font-family: "Adelle Sans Sb";
  font-size: 14px;
}

.product-description-text {
  color: #fff;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  padding-top: 200px;
}

.product-description-heading {
  font-family: "Adelle Sans";
  font-size: 26px;
}

.product-description-tag {
  font-family: "Adelle Sans Lt";
  font-size: 16px;
  color: #d9d9d6;
  padding-top: 10px;
  padding-bottom: 10px;
}

a.brochure-button,
a.product-specs-download-button {
  text-align: center;
  color: #a28d5b;
  border: 1px solid #a28d5b;
  font-size: 14px;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 250px;
}
a.brochure-button,
a.product-specs-download-button:hover {
  text-decoration: none;
}

.product-specs {
  padding-top: 50px;
  width: 60%;
  margin: 0 auto;
  border-bottom: 0.5px solid #ccc;
  padding-bottom: 50px;
}

.product-specs-header,
.product-resources-header h2 {
  font-family: "Adelle Sans";
  font-size: 26px;
  color: #25282a;
}

.product-specs-details {
  width: 100%;
}

.product-specs-details .table td {
  border: none;
}
.product-resources-details .table td {
  border: none;
}
.product-specs-details .spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
}
.product-resources-details .spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
  display: block;
}

.product-resources-details .spec-title span {
  display: block;
  float: right;
  width: 50%;
  margin-left: 10px;
}

.product-specs-details {
  font-family: "Adelle Sans";
  font-size: 16px;
  color: #777;
}

.product-resources {
  padding-top: 50px;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.product-resources-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}

.product-resources-details .spec-data a {
  font-family: "Adelle Sans";
  font-size: 16px;
  color: #a28d5b;
  text-decoration: none;
}

.rug-specs-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.rug-specs-wrapper .rug-spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
  display: block;
}

.rug-specs-wrapper .rug-spec-title span {
  display: block;
  float: right;
  width: 50%;
  text-align: left;
  font-family: "Adelle Sans";
  font-size: 15px;
  color: #777;
}

.hs-specs-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}

.hs-specs-wrapper .hs-spec-title {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
  display: block;
}

.hs-specs-wrapper .hs-spec-title span {
  display: block;
  float: right;
  width: 50%;
  text-align: left;
  font-family: "Adelle Sans";
  font-size: 13px;
  color: #777;
}

/** MOBILE */

.product-page-wrapper-mobile {
  margin: 0 auto;
}

.product-top-mobile {
  padding-top: 50px;
  width: 100%;
  padding-left: 10px;
}

.product-breadcrumb-mobile > a {
  color: #a28d5b;
  font-family: "Adelle Sans";
  font-size: 12px;
}

.product-breadcrumb-mobile > a:hover {
  text-decoration: none;
}

.product-page {
  margin: 0;
  padding-bottom: 100px;
  background: #f8f8f8;
}

.product-page-top-inner {
  display: grid;
  grid-template-columns: 30% 1fr;
  width: 80%;
}

.product-breadcrumb > a {
  color: #a28d5b;
  font-family: "Adelle Sans";
  font-size: 12px;
}

.product-title-mobile h1 {
  text-align: center;
  font-family: "Zahrah";
  font-size: 40px;
}

.product-swatch-changer-mobile {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #777;
}

.product-swatch-changer-mobile a {
  color: #444;
}

.product-swatch-changer-mobile a:hover {
  color: #a28d5b;
  text-decoration: underline;
}

.product-swatch-changer-mobile a.active-link {
  text-decoration: underline;
  color: #a28d5b;
}
.product-swatch-changer-mobile a.nonactive-link {
  color: #444;
}

.colourway-roomscenes-mobile {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.product-swatches-small-mobile {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 110px));
  grid-gap: 20px;
}

.popup-content {
  width: 80% !important;
  height: 75% !important;
}

.order-sample-mobile-button button {
  color: #a28d5b;
  border: 1px solid #a28d5b;
  border-radius: 50px;
  font-family: "Adelle Sans Sb";
  background: transparent;
  display: inline-block;
  padding: 10px;
  width: 150px;
}

.product-swatches-small-mobile a {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #25282a;
}
.product-swatches-small-mobile a:hover {
  text-decoration: none;
}

.product-description-text-mobile {
  color: #fff;
  text-align: center;
  width: 85%;
  margin: 0 auto;
  padding-top: 100px;
}

.product-specs-mobile,
.product-resources-mobile {
  padding-top: 50px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.spec-group-mobile {
  padding-bottom: 3px;
  text-align: left;
  padding-left: -20px;
}

.spec-title-mobile {
  font-family: "Adelle Sans";
  font-size: 12px;
  color: #aaa;
}
.spec-data-mobile {
  font-family: "Adelle Sans";
  font-size: 16px;
  color: #777;
}
