.sustainability-wrapper {
  background: #D9D9D6;
  padding-top: 50px;
  padding-bottom: 50px;
}

.sustainability-text {
	font-family: 'Zahrah';
	font-size: 50px;
	color: #fff;
	padding-bottom: 100px;
	padding-left: 70px;
}

.sustainability-wrapper a:hover {
  text-decoration: none;
}

.sustainability-logo img {
  padding-left: 25px;
  padding-bottom: 5px;
}

.sustainability-content {
  background: #A28D5B;
  height: 400px;
  color: #fff;
  position: relative;
}

.sustainability-content-inner {
  display: flex;
  flex-direction: row;
}

.sustainability-content-inner-left {
  margin-left: 20px;
  margin-right: 60px;
  margin-top: 15px;
  font-size: 14px;
  width: 640px;
}

.sustainability-content-inner-right img {
  width: 800px;
}

.sustainability-content-box {
  position: absolute;
  left: 310px;
  top: 265px;
}

@media screen and (max-width: 450px) {
  .sustainability-wrapper {
    margin-top: 450px;
    height: 100%;
  }  

  .sustainability-content-inner {
    flex-direction: column;
  }

  .sustainability-content-inner-left {
    width: 100%;
    padding-right: 25px;
  }

  .sustainability-content-inner-right img {
    width: 100%;
  }

  .sustainability-content {
    height: 100%;
  }

  .sustainability-content-box {
    display:none;
  }
  
  .home-blog-section-mobile {
    padding-top: 0!important;
  }

}

@media screen and (min-width: 736px) and (max-width: 1024px) {
  .sustainability-content-inner-right {
    width: 600px;
  }

  .sustainability-content-inner-right img {
    width: 600px;
  }
  
  .sustainability-content-box {
    position: absolute;
    left: 260px;
    top: 265px;
  }
}
