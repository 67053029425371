@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .aboutus-studio-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/Tree_320_486.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
    margin-top: 50px!important;
  }
  .aboutus-studio-header-content h2 {
    font-family: 'Zahrah'!important;
    font-size: 30px!important;
    color: #fff;
    position: absolute;
    top:90%!important;
    width: 100%!important;
    margin-left:10px!important;
  }

  .aboutus-history-content > h3{
    padding-top: 25px!important;
    padding-bottom: 25px!important;
    font-size: 26px!important;
  }

  
  .about-history-video-wrapper {
    width: 100%!important;
  }

  .about-us-video-block {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
  .about-video-desktop {
    display: none;
  }

  .aboutus-history-content {
    height: 1300px!important;
    padding-top: 450px!important;
  }
  .checkmarkWrapper {
    grid-template-columns: 1fr!important;
  }
  .about-check-left{
    padding-left: 56px!important;
  }
  .about-check-left > p {
    padding-left: 0px!important;
  }
  .about-check-right {
    padding-left: 0px!important;
  }

  .aboutus-hear-from-you {
    height: 800px!important;
  }
  .aboutus-contact-ways-wrapper {
    grid-template-columns: 1fr!important;
    grid-gap:0px!important;
    justify-items: left!important;
    padding-left: 25px!important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .checkmarkWrapper {
    grid-template-columns: 1fr!important;
    margin-left: 20px!important;
  }
  .about-check-left{
    padding: 0px!important;
  }
  .about-check-left > p {
    padding-left: 50px!important;
  }
  .about-check-right {
    padding-left: 0px!important;
  }
}

@media screen and (max-width: 1024px) {
  .about-check-left {
    padding-left: 50px!important;
  }
}

.aboutus-history-content {
  height: 500px;
  text-align: center;
  margin: 0 auto;
  /*display: table-cell;*/
  vertical-align: middle;
  background: #FFF;
}

.aboutus-history-content p {
  text-align: center;
  margin: 0 auto;
  width:75%;
}

.aboutus-studio-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/03/Tree.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.aboutus-studio-header-content {
  height: 450px;
  position: relative;
}

.aboutus-studio-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.aboutus-above-fold {
  height: 300px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
}

.aboutus-history {
  height: 300px;
  text-align: center;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  background: #FFF;
}

.aboutus-above-fold p {
  width: 36em;
  margin: 0 auto;
  max-width: 100%;
  line-height: 1.5;
}

.about-us-video-block {
  background: #F8F8F8;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.about-history-video-wrapper {
  margin:0 auto;
  text-align: center;
  width:650px;
}

.about-history-video-wrapper.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.aboutus-history-content {
  padding-top: 50px;
}

.aboutus-history p {
  width: 36em;
  margin: 0 auto;
  max-width: 100%;
  line-height: 1.5;
}

.checkmarkWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(50%,50%));
  margin:0 auto;
  /*max-width: 100%;*/
  padding-top: 20px;
  padding-bottom: 100px;
  font-family: 'Adelle Sans Lt';
  font-size: 14px;
  color: #777;
}
.about-check-left {
  padding-left: 150px;
}
.about-check-left > p {
  padding: 5px;
  text-align: left;
  padding-left:150px;
  width: 100%;
}

.about-check-right {
 
}

.about-check-right > p {
  padding: 5px;
  text-align: left;
}

.aboutus-hear-from-you {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2018/12/Motion-Wave-Website.jpg');
  margin: 0 auto;
}

.aboutus-hear-from-you h2 {
  font-family: 'Adelle Sans Lt';
  font-size: 30px;
  color: #FFF;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.aboutus-contact-ways-wrapper {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  justify-items: center;
}
