@font-face {
  font-family: 'Zahrah';
  src: url('../../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Lt';
  src: url('../../../Fonts/AdelleSans-Light.woff2') format('woff2'),
      url('../../../Fonts/AdelleSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans';
  src: url('../../../Fonts/AdelleSans-Regular.woff2') format('woff2'),
      url('../../../Fonts/AdelleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adelle Sans Sb';
  src: url('../../../Fonts/AdelleSans-SemiBold.woff2') format('woff2'),
      url('../../../Fonts/AdelleSans-SemiBold.woff') format('woff');
  font-weight: normal;
}

button:focus {outline:0;}

.close {
  color: #A28D5B;
  font-family: 'Adelle Sans Lt';
  font-size: 2rem;
  font-weight: normal;
  padding-right: 50px;
  padding-top:10px;
}

.popup-content {
  width: 60%!important;
  padding:0!important;
  display:inline-block;
}
.cart-wrapper {
  width: 100%;
  max-height: 90%;
  overflow-x: auto;
  overflow-y: auto;
  z-index: 1;
}

.cart-header {
  width: 100%;
  background: #F8F8F8;
  display: inline-block;
}

.header-text {
  display: inline-block;
  width:100%;
  padding-top: 40px;
}

.header-text h1 {
  font-size: 56px;
  font-family: 'Zahrah';
  color: #25282A;
  text-align: center;
}

.header-text p.sample-count {
  text-align: center;
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
  margin-top:-5px;
}

.cart-top-nav {
  padding-left:20px;
}

.cart-top-nav a {
  font-family: 'Adelle Sans';
  font-size: 12px;
  color: #777;
}
.cart-top-nav a:hover, a.active-cart-nav {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration: underline;

}

.cart-items {
  min-height: 200px;
  width: 100%;
  padding-left:30px;
}

.item-name {
  text-align: left;
}
.item-name .item-headers {
  font-family: 'Adelle Sans Sb';
  font-size: 20px;
  color: #25282A;
}
.item-name .color-name {
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
  margin-top:-5px;
}
table {
  width: 80%;
  margin: 0 auto;
}
td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.cart-item-row {
  border-bottom: .5px solid #CCC;
}

.cart-buttons, .checkout-buttons {
  text-align: center;
  padding: 50px;
}

.cart-buttons .continue-shopping {
  color: #A28D5B;
  border:1px solid #A28D5B;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.cart-buttons .checkout-button-front {
  color: #fff;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 100px;
}

.form-label {
  font-family: 'Adelle Sans LT';
  font-size: 1rem;
  color: #777;
}

.qty-select select {
  border: 0;
  border-bottom:1px solid #A28D5B;
  background: #fff;
}

.checkout-form-div {
  width:60%;
  margin: 0 auto;
  min-height: 300px;
  max-height: 600px;
  padding-top:20px;
  z-index: auto;
  text-align: left;
}

.checkout-form-div p {
  font-family: 'Adelle Sans';
  font-size: 11px;
  font-style: italic;
  color: #AAA;
}
.row, .fullRow {
  padding: 10px;
}
.col {
  float: left;
  width: 50%
}

.col label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.col input {
  border:0;
  border-bottom: .5px solid #CCC;
  width:100%;
}

.fullRow label {
  font-family: 'Adelle Sans';
  font-size: 11px;
  color: #AAA;
}

.fullRow input {
  border:0;
  border-bottom: .5px solid #CCC;
  width:100%;
}

.checkout-buttons .continue-shopping{
  color: #A28D5B;
  border:1px solid #A28D5B;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  background:transparent;
  display: inline-block;
  padding:10px;
  width:150px;
}

.checkout-buttons .checkout-button-front{
  color: #fff;
  border:1px solid #A28D5B;
  border-radius: 50px;
  font-family: 'Adelle Sans Sb';
  font-size: 14px;
  background:#A28D5B;
  display: inline-block;
  padding:10px;
  width: 150px;
}


.frmStateSelect select {
  border: 0;
  border-bottom: .5px solid #CCC;
  background: #fff;
}

.error{
  margin-left: 5px;
  font-size: 12px;
  font-family: 'Adelle Sans';
  color: red;
}

.thank-you {
  margin: 0 auto;
  padding-top:50px;
  padding-left: 30px;
  padding-right: 30px;
}

.thank-you p {
  text-align: center;
  font-family: 'Adelle Sans';
  font-size: 1rem;
  color: #777;
  padding-bottom: 50px;
}