@font-face {
  font-family: 'Zahrah';
  src: url('../../Fonts/Zahrah-Semibold.woff2') format('woff2'),
      url('../../Fonts/Zahrah-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}


@media screen and (max-width: 735px) {
  .media-page-header {
    width: 100%!important;
    height: 486px!important;
    background: linear-gradient(to bottom, transparent 0%, black 175%), url('/wp-content/uploads/2019/03/SIG_Reflect_Refract_Water_320_486-2.jpg')!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: top!important;
  }
  
  .media-page-header-content {
    height: 450px;
    position: relative;
  }
  .media-page-header-content h2 {
    font-family: 'Adelle Sans';
    font-size: 50px;
    color: #fff;
    position: absolute;
    top:80%!important;
    margin-left:10px!important;
  }

  .media-page-main-content {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }

  .pr-grid {
    grid-template-columns: 1fr!important;
  }

  .pr-item {
    padding-left: 5px!important;
    margin-right: 5px!important;
  }

  .media-page-pr {
    width: 100%!important;
  }
  .media-page-desktop-videos {
    display:none;
  }

  .media-page-ads {
    padding-left: 20%!important;
  }
  
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .pr-grid {
    grid-template-columns: 1fr!important;
  }
  .media-page-main-content {
    padding-left: 10%!important;
    padding-right: 10%!important;
  }
  .media-page-pr {
    width: 100%!important;
  }
  .media-page-mobile-video {
    display: none;
  }

  .media-page-ads {
    padding-left: 17%!important;
  }
}


@media screen and (max-width: 1024px) {
  .media-page-main-content {
    padding-left: 10%!important;
    padding-right: 10%!important;
  }
}


.media-page-header {
  width: 100%;
  height: 450px;
  background: linear-gradient(to bottom, transparent 0%, black 120%), url('/wp-content/uploads/2019/03/MediaHeader.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.media-page-header-content {
  height: 450px;
  position: relative;
}

.media-page-header-content h2 {
  font-family: 'Zahrah';
  font-size: 50px;
  color: #fff;
  position: absolute;
  top:70%;
  margin-left:100px;
}

.media-page-header-content nav {
  margin-left: 50px;
  position: absolute;
  top: 90%;
}

.media-page-header-content nav li {
  display: inline-block;
}

.media-page-header-content nav li a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
  padding-left: 15px;
}

.media-page-header-content nav li a:hover {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B;
}

.media-page-header-content nav li a.active-link {
  font-family: 'Adelle Sans Sb';
  color: #A28D5B;
  text-decoration-color: #A28D5B!important;
}

.media-page-above-fold {
  height: 200px;
  width: 100%;
  text-align: center;
  background: #FFF;
  padding-top: 75px;
  font-family: 'Adelle Sans';
  font-size: 20px;
  color: #25282A;
}

.media-page-main-content {
  min-height: 400px;
  background: #FFF;
  padding-left: 200px;
  padding-right: 200px;
}

.media-page-pr-year-links {
  text-align: center;
  margin: 0 auto;
}

.media-page-pr-year-links > a {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #777;
}

.media-page-pr-year-links > a:hover {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}

.media-page-pr-year-links > a.active-year {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #A28D5B;
  text-decoration: underline;
}

.media-page-pr {
  margin: 0 auto;
  width: 900px;
}

.pr-grid {
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 25px;
}

.pr-item {
  padding-left: 50px;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B
}
.pr-item .pr-date {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #AAA;
}

.pr-item a {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
}
.pr-item a:hover {
  text-decoration: none;
}

.media-page .pr-grid .pr-item .pr-link {
  font-family: 'Adelle Sans';
  font-size: 14px;
  color: #AAA;
}


.media-page-videos {
  text-align: center;
  padding-left: 25px;
  margin: 0 auto;
  font-family: 'Adelle Sans';
  font-size: 25px;
  color: #25282A;
}
.media-page-videos span {
  text-align: left;
}


.ad-grid {
  padding-top: 50px;
  padding-bottom: 100px;
  margin: 0 auto;
  padding-left: 0!important;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, 239px);
  grid-row-gap: 25px;
}

.ad-title {
  text-align: left;
  font-family: 'Adelle Sans Sb';
  font-size: 16px;
  color: #25282A;
}

.ad-download-link a {
  text-align: left;
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #A28D5B;
}

.ad-download-link a:hover {
  text-decoration: none;
}

.media-page-need-help {
  width: 100%;
  height: 448px;
  background: linear-gradient(rgba(37,40,42,0.80),rgba(37,40,42,0.80)), url('/wp-content/uploads/2019/03/media-page-need-help-bg.jpg');
  margin: 0 auto;
}

.media-page-need-help h2 {
  font-family: 'Adelle Sans Lt';
  font-size: 30px;
  color: #FFF;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
}

.media-page-need-help-wrapper {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  justify-items: center;
}
